import i18next from 'i18next';
import { Card } from '../type/savingType';

export const CardList: Card[] = [
  {
    title: i18next.t('savingType.negativeSaver.title'),
    image: '/assets/images/savingType/NegativeSaver.svg',
    text: i18next.t('savingType.negativeSaver.description'),
    id: 'negativeSaver',
    checkBoxList: [
      {
        name: 'hasOverdraft',
      },
      {
        name: 'hasConsumerLoan',
      },
      {
        name: 'hasDuePaymentNotice',
      },
      {
        name: 'hasDebt',
      },
    ],
  },
  {
    title: i18next.t('savingType.amateurSaver.title'),
    image: '/assets/images/savingType/AmateurSaver.svg',
    text: i18next.t('savingType.amateurSaver.description'),
    id: 'amateurSaver',
    checkBoxList: [
      {
        name: 'hasPlusMinusZero',
      },
      {
        name: 'hasRevenueInterest',
      },
      {
        name: 'hasConsumption',
      },
      {
        name: 'hasSavingBook',
      },
    ],
  },
  {
    title: i18next.t('savingType.proSaver.title'),
    image: '/assets/images/savingType/ProSaver.svg',
    text: i18next.t('savingType.proSaver.description'),
    id: 'proSaver',
    checkBoxList: [
      {
        name: 'hasDiscipline',
      },
      {
        name: 'hasStrategy',
      },
      {
        name: 'hasTwoAccountModel',
      },
      {
        name: 'hasAsset',
      },
    ],
  },
];
