import { useQuery } from '@tanstack/react-query';
import { personApi } from 'service/api/personApi';
import { Address } from 'type/address';

export interface SearchValidationParams {
  country?: string;
  searchKey?: string;
}

export const useLoadAddressValidationList = (
  data: SearchValidationParams,
  onDataLoaded: (list: Address[]) => void,
) =>
  useQuery({
    queryKey: [`addressValidationList`, data?.searchKey],
    queryFn: async () => {
      if (!data || !data.country || !data.searchKey) return [];
      const { country, searchKey } = data;
      const personService = personApi();

      const response = await personService.getAddressValidation(country, searchKey);
      const responseData = response?.data || [];
      onDataLoaded(responseData);

      return responseData;
    },
    enabled: !!data.searchKey,
    initialData: [],
  });

export const getUeeLoadAddressValidation =
  (country: string | undefined) =>
  (search: string | undefined, onDataLoaded: (list: Address[]) => void) =>
    useLoadAddressValidationList({ country, searchKey: search ?? '' }, onDataLoaded);
