import {
  DxButton,
  DxDropdown,
  DxDropdownOption,
  DxText,
  DxTextInput,
} from '@dvag/design-system-react';
import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import {
  CreateOrUpdateResource,
  DeleteResource,
} from '@dvag/dfs-ui-blocks/hooks/useAutosaveHelper';
import i18next from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { defaultFaxData, keyFaxSectionName, telephoneOrFaxKeyList } from 'form/util';
import { validatePhoneOrFaxNumber } from 'form/validation';

import { useCreateOrUpdateCommunicationChannel } from 'hooks/useCreateOrUpdateCommunicationChannel';
import { useDeleteCommunicationChannel } from 'hooks/useDeleteCommunicationChannel';
import 'style.css';
import { style } from 'styleConfig';
import { IFaxForm, TFormValueList } from 'type/contact';
import { FormType, FormTypes, SecondaryFormType, TriggerValidationValue } from 'utils/fieldList';
import { ContinutationRequest } from 'utils/useContinuation';
import { checkIcon, faxTypeOptionList } from 'utils/util';
import { checkIsMobile } from 'utils/windowSize';
import { useContactDialCodeOptionList } from '../action/useContactDialCodeOptionList';
import { isDeleteDisabled } from './common';

type FaxSectionProps = {
  personId: string | undefined;
  data: IFaxForm[];
  onFormDirtyStatusChanged: (isDirty: boolean) => void;
  onFormValidStatusChanged: (isValid: boolean) => void;
  onFormSubmittingStatusChanged: (isSubmitting: boolean) => void;
  onFormSaved: () => void;
  getCanContinueHandler: () => (canContinueCond: boolean) => void;
  onContinuationRequest: (continuationRequestCallback: ContinutationRequest) => void;
  display: string;
  setTriggerValidationForms: (
    value: TriggerValidationValue,
    formType: FormTypes,
    secondaryFormType?: SecondaryFormType,
  ) => void;
};

const translation = {
  selectDefaultPlaceholder: i18next.t('general.selectDefaultPlaceholder'),
  inputDefaultPlaceholder: i18next.t('general.inputDefaultPlaceholder'),
  addFaxNumber: i18next.t('contact.addFaxNumber'),
};

export const FaxSection = ({
  personId,
  data,
  onFormDirtyStatusChanged,
  onFormValidStatusChanged,
  onFormSubmittingStatusChanged,
  onFormSaved,
  getCanContinueHandler,
  onContinuationRequest,
  setTriggerValidationForms,
}: FaxSectionProps) => {
  const resourceSchema = useMemo(
    () =>
      yup.object({
        value: yup
          .string()
          .nullable()
          .required(i18next.t('general.mandatoryField'))
          .test(
            'validatePhoneOrFaxNumber',
            i18next.t('contact.errorMessage'),
            (x) => validatePhoneOrFaxNumber(x) === true,
          ),
      }),
    [],
  );

  const { dialCodeListMemoized } = useContactDialCodeOptionList();
  const deleteCommunicationChannel = useDeleteCommunicationChannel(personId);
  const createOrUpdateCommunicationChannel = useCreateOrUpdateCommunicationChannel(personId);

  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);

  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    flushChanges,
    remove,
    append,
    getAllResources,
    resources,
    hasTouchedResources,
    triggerValidation,
  } = useAutosave<IFaxForm>({
    data,
    defaultData: defaultFaxData,
    createResource:
      createOrUpdateCommunicationChannel.mutateAsync as CreateOrUpdateResource<IFaxForm>,
    updateResource:
      createOrUpdateCommunicationChannel.mutateAsync as CreateOrUpdateResource<IFaxForm>,
    onIsValidChange: (isValidParam) => {
      onFormValidStatusChanged(isValidParam);
    },
    onSubmitSuccessfulChange: (isSubmitSuccessfulParam) => {
      if (isSubmitSuccessfulParam) onFormSaved();
    },
    onIsDirtyChange: (isDirtyParam) => {
      setIsDirty(isDirtyParam);
      onFormDirtyStatusChanged(isDirtyParam);
      onCanContinueChange(!isDirtyParam);
    },
    onIsSubmittingChange: (isSubmittingParam) => {
      setIsSubmitting(isSubmittingParam);
      onFormSubmittingStatusChanged(isSubmittingParam);
    },
    resourceSchema,
    deleteResource: deleteCommunicationChannel.mutateAsync as unknown as DeleteResource<IFaxForm>,
  });

  useEffect(() => {
    onContinuationRequest(() => {
      flushChanges();
      triggerValidation();
    });
  }, [flushChanges, onContinuationRequest, triggerValidation]);

  const isMobile = checkIsMobile();

  useEffect(() => {
    setTriggerValidationForms(triggerValidation as TriggerValidationValue, FormType.contact, 'fax');
  }, [setTriggerValidationForms, triggerValidation]);

  const onAppend = () => {
    append(defaultFaxData, { shouldFocus: false });
  };

  return (
    <div
      data-testid="fax-section"
      className="pd_fax-content-container"
      style={{ width: 'inherit', height: 'auto', clear: 'right' }}
    >
      <DxText color="headline" type="Paragraph-Standard" style={style.mainTitle}>
        {i18next.t('contact.fax')}
      </DxText>
      <form style={{ paddingTop: '16px', clear: 'right' }} className="pd_contact--section">
        {resources &&
          resources.map((item, index) => {
            register(`${keyFaxSectionName}.${index}.id`);
            return (
              <div
                key={item.id}
                className="pd_contact_container"
                style={{
                  gridTemplateColumns: `${style.contactPhoneColumnGridTemplate} min-content`,
                  clear: 'right',
                }}
              >
                <DxDropdown
                  size="m"
                  placeholder={translation.selectDefaultPlaceholder}
                  className="pd_contact_type"
                  style={{ whiteSpace: 'nowrap' }}
                  data-testid={`pd_faxType-${index}`}
                  id={`pd_faxType-${index}`}
                  required={false}
                  label={`${i18next.t('contact.faxType')}`}
                  clearable={false}
                  {...register('type', index)}
                >
                  {faxTypeOptionList.map(({ value, label }) => (
                    <DxDropdownOption
                      key={`${item.id}-${value}-${label}`}
                      value={value}
                      label={label}
                    />
                  ))}
                </DxDropdown>
                <DxDropdown
                  size="m"
                  placeholder={translation.selectDefaultPlaceholder}
                  className="pd_contact_prefix"
                  data-testid={`pd_prefixType-${index}`}
                  id={`pd_fax-prefixType-${index}`}
                  required={false}
                  label={`${i18next.t('contact.prefix')}`}
                  clearable={false}
                  {...register('prefix', index)}
                >
                  {dialCodeListMemoized}
                </DxDropdown>
                <DxTextInput
                  nominmaxlabel
                  size="m"
                  placeholder={translation.inputDefaultPlaceholder}
                  className="pd_contact_value"
                  required={hasTouchedResources()}
                  data-testid={`pd_fax-${index}`}
                  id={`pd_fax-${index}`}
                  label={`${i18next.t('contact.fax')}`}
                  maxlength={14}
                  {...register('value', index)}
                />
                <DxButton
                  type="text"
                  disabled={isDeleteDisabled(
                    index,
                    getAllResources() as TFormValueList,
                    telephoneOrFaxKeyList,
                    isSubmitting || isDirty,
                  )}
                  className="pd_contact_delete"
                  onClick={() => remove(index)}
                  data-testid={`pd_deleteFaxSection-${index}`}
                  id={`pd_deleteFaxSection-${index}`}
                  icon={checkIcon('loeschen')}
                  theme="destructive"
                />
              </div>
            );
          })}
      </form>
      <DxButton
        type="text"
        className="pd_contact_addNewSection"
        style={{ float: 'right', margin: isMobile ? '0 0 32px 0' : '24px 0 32px 0' }}
        data-testid="pd_addFaxSection"
        id="pd_addFaxSection"
        disabled={getAllResources().some((p) => p.id === '' || p.id === undefined)}
        onClick={() => onAppend()}
        label={translation.addFaxNumber}
        icon={checkIcon('plus-kreis')}
      />
    </div>
  );
};
