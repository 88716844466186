import React from 'react';
import { DxCard, DxCardContent, DxContainer, DxText } from '@dvag/design-system-react';
import i18next from 'i18next';
import { CommunicationChannelDto } from 'graphql/generates';
import { isGdprConsentGranted } from 'utils/ConsentUtil';
import { Separator } from './Separator';
import { ConsentDetails } from './ConsentDetails/ConsentDetails';
import { SendEmailButton } from './SendEmailButton';
import './ProcessDescriptionCard.css';
import { FinleapConsent } from '../../../../type/consent';

type ProcessDescriptionCardProps = {
  emailCommunicationChannel: CommunicationChannelDto | undefined;
  gdprConsent: FinleapConsent | undefined;
  finleapConsent: FinleapConsent | undefined;
  isPersonFetched: boolean;
  isEmailSending: boolean;
  onSendEmailClick: () => void;
};

const stepTextMap: Record<string, string> = {
  step1: i18next.t('bankSelectionPage.processDescriptionCard.content.step1'),
  step2: i18next.t('bankSelectionPage.processDescriptionCard.content.step2'),
  step3: i18next.t('bankSelectionPage.processDescriptionCard.content.step3'),
  step4: i18next.t('bankSelectionPage.processDescriptionCard.content.step4'),
  step5: i18next.t('bankSelectionPage.processDescriptionCard.content.step5'),
};

export const ProcessDescriptionCard = ({
  emailCommunicationChannel,
  gdprConsent,
  finleapConsent,
  isPersonFetched,
  isEmailSending,
  onSendEmailClick,
}: ProcessDescriptionCardProps) => (
  <DxCard id="bc-process-description-card" data-testid="bc-process-description-card">
    <DxCardContent>
      <DxText data-testid="bc-process-description-card-title">
        {i18next.t('bankSelectionPage.processDescriptionCard.title')}
      </DxText>
      <br />
      {Object.keys(stepTextMap).map((step, index) => (
        <DxContainer key={step} color="transparent" className="bc-process-description-list-item">
          <DxText
            className="bc-process-description-list-item-order"
            data-testid={`bc-pdc-item-order-${index + 1}`}
          >{`${index + 1}.`}</DxText>
          <DxContainer color="transparent">
            <DxText data-testid={`bc-pdc-item-text-${step}`}>{stepTextMap[step]}</DxText>
          </DxContainer>
        </DxContainer>
      ))}
      <Separator />
      {isPersonFetched && (
        <DxContainer
          color="transparent"
          className="bc-process-description-action-container"
          data-testid="bc-pdc-action-container"
        >
          <ConsentDetails email={emailCommunicationChannel?.value} gdprConsent={gdprConsent} />
          {!finleapConsent && isGdprConsentGranted(gdprConsent) && (
            <SendEmailButton disabled={isEmailSending} onClick={onSendEmailClick} />
          )}
        </DxContainer>
      )}
    </DxCardContent>
  </DxCard>
);
