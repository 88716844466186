import React from 'react';
import { DxContainer, DxCard } from '@dvag/design-system-react';

import './style.css';

export const HeaderPlaceholder = () => (
  <DxCard color="transparent" data-testid="data-form-header-placeholder">
    <DxContainer className="header-container" color="white">
      <DxContainer className="header-placeholder" color="gray-7" />
    </DxContainer>
  </DxCard>
);
