import React, { useState } from 'react';
import i18next from 'i18next';

import { DxButton, DxCheckbox, DxModal, DxText } from '@dvag/design-system-react';

import { getPersonFullName } from 'utils/util';

import { PersonInHouseholdDto } from 'graphql/generates';
import './style.css';

type CopyModalProps = {
  visible: boolean;
  personList: PersonInHouseholdDto[];
  personId: string | undefined;
  isSubmitDisabled?: boolean;
  onCancel?: () => void;
  onSubmit?: (personIdList: string[]) => void;
  onOpened?: () => void;
  onClosed?: () => void;
};

const translations = {
  modalTitle: i18next.t('wishesAndGoals.copyModal.title'),
  contentTitle: i18next.t('wishesAndGoals.copyModal.contentTitle'),
  bottomTitle: i18next.t('wishesAndGoals.copyModal.bottomTitle'),
  cancelButtonText: i18next.t('wishesAndGoals.copyModal.cancelButton'),
  copyButtonText: i18next.t('wishesAndGoals.copyModal.saveButton'),
};

export const CopyModal = ({
  visible,
  personList,
  personId,
  isSubmitDisabled = false,
  onCancel,
  onSubmit,
  onOpened,
  onClosed,
}: CopyModalProps) => {
  const [selectedIdSet, setSelectedIdSet] = useState(new Set<string>());

  const clearForm = () => {
    setSelectedIdSet(new Set());
  };

  const onModalOpened = () => {
    if (onOpened) {
      onOpened();
    }
  };

  const onModalClosed = () => {
    if (onClosed) {
      onClosed();
    }
    clearForm();
  };

  const doCancel = () => {
    if (onCancel) {
      onCancel();
    }
    clearForm();
  };

  const doSubmit = async () => {
    if (onSubmit) {
      onSubmit(Array.from(selectedIdSet));
    }
    clearForm();
  };

  const updatePersonList = (id: string, isSelected: boolean) => {
    if (isSelected) {
      selectedIdSet.add(id);
    } else {
      selectedIdSet.delete(id);
    }
    setSelectedIdSet(new Set(selectedIdSet));
  };

  return (
    <DxModal
      id="wg-copy-modal"
      data-testid="wg-copy-modal"
      visible={visible}
      height="content"
      label={translations.modalTitle}
      onModalOpened={onModalOpened}
      onModalClosed={onModalClosed}
    >
      <div slot="content">
        <DxText>{translations.contentTitle}</DxText>
        <div className="person-list">
          {personList.map(
            (person) =>
              person.id !== personId && (
                <DxCheckbox
                  id={`${personId}-checkbox`}
                  data-testid={`${personId}-checkbox`}
                  key={person.id}
                  label={getPersonFullName(person)}
                  checked={selectedIdSet.has(person.id)}
                  onCheckedChange={(e) => {
                    updatePersonList(person.id, e.detail);
                  }}
                />
              ),
          )}
        </div>
        <DxText>{translations.bottomTitle}</DxText>
      </div>
      <DxButton
        id="wg-copy-cancel-button"
        data-testid="wg-copy-cancel-button"
        slot="secondary-actions"
        type="text"
        label={translations.cancelButtonText}
        onClick={doCancel}
      />
      <DxButton
        id="wg-copy-submit-button"
        data-testid="wg-copy-submit-button"
        slot="primary-actions"
        type="primary-m"
        label={translations.copyButtonText}
        disabled={!selectedIdSet.size || isSubmitDisabled}
        onClick={doSubmit}
      />
    </DxModal>
  );
};
