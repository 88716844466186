import { BankAccountMutationTransactionInputDto, useBankAccountMutation } from 'graphql/generates';
import { getGraphQLClient } from 'graphql/graphQLClient';
import { useNotification } from 'utils/notification/NotificationContext';

export const useUpdateManyBankAccounts = (personId: string) => {
  const { showError } = useNotification();
  const { mutateAsync, isPending, isError, isPaused, isSuccess, isIdle } = useBankAccountMutation(
    getGraphQLClient(),
    {
      onError: () => {
        showError();
      },
      onSuccess: (data) => {
        const failedTransaction = data.bankAccount.transactionList.find(
          (transaction) => transaction.errorList?.length,
        );
        if (failedTransaction) {
          showError({ description: failedTransaction.errorList?.[0].message });
        }
      },
    },
  );

  async function updateManyBankAccounts(transactionList: BankAccountMutationTransactionInputDto[]) {
    const updatedTransactionList = transactionList.map((transactionItem) => ({
      operation: transactionItem.operation,
      data: {
        id: transactionItem.data.id,
        iban: transactionItem.data.iban,
        isUsedForCalculation: transactionItem.data.isUsedForCalculation,
      },
    }));

    const data = await mutateAsync({
      bankAccountInput: {
        personId,
        transactionList: updatedTransactionList,
      },
    });

    return data.bankAccount.transactionList;
  }

  return {
    updateManyBankAccounts,
    isSuccess,
    isPending,
    isPaused,
    isError,
    isIdle,
  };
};
