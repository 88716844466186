import i18next from 'i18next';
import {
  UPDATE_PERSONAL_DATA_FORM_IS_DIRTY,
  UPDATE_PERSONAL_DATA_FORM_IS_SUBMITTING,
  UPDATE_PERSONAL_DATA_FORM_IS_VALID,
  UPDATE_PERSONAL_DATA_FORM_SUCCESS,
} from 'config/action';
import { CountryCode } from 'graphql/generates';
import {
  MobilePhone,
  PersonalEmail,
  PersonalFax,
  Phone as PrivatPhone,
  WorkEmail,
  WorkFax,
  WorkMobilePhone,
  WorkPhone,
} from 'service/model/communicationType';
import {
  emailType,
  faxType,
  IEmailForm,
  IFaxForm,
  ITelephoneForm,
  phoneType,
  TFormSection,
} from 'type/contact';
import { AddressFormValues } from 'type/address';
import { ListItem } from 'type/person';
import { PersonalDataActionPayload } from 'reducer';

export const defaultAddressData: AddressFormValues = {
  addressData: [
    {
      context: 'PERSON',
      city: '',
      country: CountryCode.D,
      isReadOnly: false,
      street: '',
      zipCode: '',
      state: null,
      isPostalAddress: false,
      isVerified: false,
    },
  ],
};

export const defaultBankDetailsData = {
  bankInformationData: [
    {
      iban: '',
      financialInstitution: '',
      id: '',
      bic: '',
      isReadOnly: false,
      isUsedForCalculation: true,
    },
  ],
};
export const defaultPrefix = '+49';

export const defaultTelephoneData: ITelephoneForm = {
  type: MobilePhone,
  value: '',
  prefix: defaultPrefix,
  id: '',
  context: '',
};

export const defaultFaxData: IFaxForm = {
  type: PersonalFax,
  value: '',
  prefix: defaultPrefix,
  id: '',
  context: '',
};

export const defaultEmailData: IEmailForm = {
  type: PersonalEmail,
  value: '',
  context: '',
  id: '',
};

export const taxableIncomePreviousYearFieldName = 'taxableIncomePreviousYear';
export const incomeSocialInsurancePreviousYearFieldName = 'incomeSocialInsurancePreviousYear';
export const taxNumberFieldName = 'taxNumber';
export const taxReturnTypeFieldName = 'taxReturnType';
export const childAllowanceFieldName = 'childAllowance';
export const churchTaxFieldName = 'churchTax';
export const taxAdvisorFieldName = 'taxAdvisor';

export const incomeMaxValue = 999999999;

export const incomeMinValue = 0.01;
export const MIN_SEARCH_TERM_LENGTH = 4;

export const checkForTelephoneByType = (type: phoneType) => {
  const handler = {
    [PrivatPhone]: () => true,
    [WorkPhone]: () => true,
    [MobilePhone]: () => true,
    [WorkMobilePhone]: () => true,
  };
  return handler[type];
};

export const checkForFaxByType = (type: faxType) => {
  const handler = {
    [PersonalFax]: () => true,
    [WorkFax]: () => true,
  };
  return handler[type];
};

export const checkForEmailType = (type: emailType) => {
  const handler = {
    [PersonalEmail]: () => true,
    [WorkEmail]: () => true,
  };
  return handler[type];
};

export const PREFIX = 'prefix';
export const VALUE = 'value';
export const TYPE = 'type';

export const errorMessage = i18next.t('contact.errorMessage');
export const mandatoryFieldMessage = i18next.t('general.mandatoryField');
export const keyFaxSectionName = 'communicationChannelFaxData';
export const keyEmailSectionName = 'communicationChannelEmailData';
export const keyTelephoneSectionName = 'communicationChannelTelephoneData';

export const telephoneOrFaxKeyList = [VALUE, TYPE, PREFIX];
export const emailKeyList = [VALUE, TYPE];

export const checkForData = (formSection: TFormSection, formSectionKeyList: string[]): boolean =>
  formSectionKeyList
    .map((key) => !!formSection?.[`${key as keyof TFormSection}`]?.length)
    .every((bool) => bool);

export const getUniqueListBy = (arr: ListItem[], key: keyof ListItem): ListItem[] =>
  [...new Map(arr.map((item) => [item[key], item])).values()] as ListItem[];

export const mainPersonChecker = (order: number | undefined): boolean => {
  if (order) {
    return order === 1;
  }
  return false;
};

export const bankInformationNameKeyName = 'bankInformationData';

export const setSuccessStatus = (
  updateContext: {
    (actionType: string, formState: PersonalDataActionPayload): void;
    (arg0: string, arg1: { key: string; value: { isSuccess: boolean } }): void;
  },
  payloadKey: string,
  isFormValid: boolean,
) =>
  updateContext(UPDATE_PERSONAL_DATA_FORM_SUCCESS, {
    key: payloadKey,
    value: {
      isSuccess: isFormValid,
    },
  });

export const setIsValidStatus = (
  updateContext: {
    (actionType: string, formState: PersonalDataActionPayload): void;
    (arg0: string, arg1: { key: string; value: { isValid: boolean } }): void;
  },
  payloadKey: string,
  isValid: boolean,
) =>
  updateContext(UPDATE_PERSONAL_DATA_FORM_IS_VALID, {
    key: payloadKey,
    value: {
      isValid,
    },
  });

export const setIsDirtyStatus = (
  updateContext: {
    (actionType: string, formState: PersonalDataActionPayload): void;
    (arg0: string, arg1: { key: string; value: { isDirty: boolean } }): void;
  },
  payloadKey: string,
  isDirty: boolean,
) =>
  updateContext(UPDATE_PERSONAL_DATA_FORM_IS_DIRTY, {
    key: payloadKey,
    value: {
      isDirty,
    },
  });

export const setIsSubmittingStatus = (
  updateContext: {
    (actionType: string, formState: PersonalDataActionPayload): void;
    (arg0: string, arg1: { key: string; value: { isSubmitting: boolean } }): void;
  },
  payloadKey: string,
  isSubmitting: boolean,
) =>
  updateContext(UPDATE_PERSONAL_DATA_FORM_IS_SUBMITTING, {
    key: payloadKey,
    value: {
      isSubmitting,
    },
  });
