import { DxCard, DxIcon } from '@dvag/design-system-react';
import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { FormHeader } from 'component/FormHeader';
import { style } from 'styleConfig';
import { checkIsMobile } from 'utils/windowSize';
import { FormType, FormTypes, SecondaryFormType } from 'utils/fieldList';
import { ContinutationRequest } from 'utils/useContinuation';
import { checkIcon } from 'utils/util';
import { CommunicationChannelDto } from 'graphql/generates';
import { useHandleDataBeforeRender } from './action/useHandleDataBeforeRender';
import { EmailSection } from './component/EmailSection';
import { FaxSection } from './component/FaxSection';
import { TelephoneSection } from './component/TelephoneSection';
import './style.css';

interface ContactFormProps {
  display: string;
  personId: string | undefined;
  onFormDirtyStatusChanged: (formType: FormTypes, isDirty: boolean) => void;
  onFormValidStatusChanged: (formType: FormTypes, isValid: boolean) => void;
  onFormSubmittingStatusChanged: (formType: FormTypes, isSubmitting: boolean) => void;
  onFormSaved: (formType: FormTypes) => void;
  getCanContinueHandler: () => (canContinueCond: boolean) => void;
  onContinuationRequest: (continuationRequestCallback: ContinutationRequest) => void;
  data: CommunicationChannelDto[];
  setTriggerValidationForms: (
    value: () => Promise<boolean[]>,
    formType: FormTypes,
    secondaryFormType?: SecondaryFormType,
  ) => void;
}

export const ContactForm = ({
  display,
  personId,
  onFormValidStatusChanged,
  onFormDirtyStatusChanged,
  onFormSubmittingStatusChanged,
  onFormSaved,
  getCanContinueHandler,
  onContinuationRequest,
  data,
  setTriggerValidationForms,
}: ContactFormProps) => {
  const isMobile = checkIsMobile();

  const {
    communicationChannelTelephoneData,
    communicationChannelEmailData,
    communicationChannelFaxData,
  } = useHandleDataBeforeRender(data);

  const [isDirty, setIsDirty] = useState([false, false, false]);
  const [isValid, setIsValid] = useState([true, true, true]);
  const [isSubmitting, setIsSubmitting] = useState([false, false, false]);
  const [isSaved, setIsSaved] = useState([false, false, false]);

  useEffect(() => {
    onFormDirtyStatusChanged(FormType.contact, isDirty[0] || isDirty[1] || isDirty[2]);
  }, [isDirty, onFormDirtyStatusChanged]);

  useEffect(() => {
    onFormValidStatusChanged(FormType.contact, isValid[0] && isValid[1] && isValid[2]);
  }, [isValid, onFormValidStatusChanged]);

  useEffect(() => {
    onFormSubmittingStatusChanged(
      FormType.contact,
      isSubmitting[0] || isSubmitting[1] || isSubmitting[2],
    );
  }, [isSubmitting, onFormSubmittingStatusChanged]);

  useEffect(() => {
    const isSavedPhoneSection = isSaved[0] && isValid[1] && isValid[2];
    const isSavedEmailSection = isSaved[1] && isValid[0] && isValid[2];
    const isSavedFaxSection = isSaved[2] && isValid[0] && isValid[1];

    if (isSavedPhoneSection || isSavedEmailSection || isSavedFaxSection) {
      onFormSaved(FormType.contact);
    }
  }, [isSaved, isValid, onFormSaved]);

  const setTelephoneIsDirty = useCallback(
    (dirty: boolean) =>
      setIsDirty((isDirtyContact) => [dirty, isDirtyContact[1], isDirtyContact[2]]),
    [setIsDirty],
  );

  const setTelephoneIsSaved = useCallback(
    () => setIsSaved((isSavedContact) => [true, isSavedContact[1], isSavedContact[2]]),
    [setIsSaved],
  );

  const setTelephoneIsValid = useCallback(
    (valid: boolean) => setIsValid((validContact) => [valid, validContact[1], validContact[2]]),
    [setIsValid],
  );

  const setTelephoneIsSubmitting = useCallback(
    (submitting: boolean) =>
      setIsSubmitting((submittingContact) => [
        submitting,
        submittingContact[1],
        submittingContact[2],
      ]),
    [setIsSubmitting],
  );

  const setEmailIsDirty = useCallback(
    (dirty: boolean) =>
      setIsDirty((isDirtyContact) => [isDirtyContact[0], dirty, isDirtyContact[2]]),
    [setIsDirty],
  );

  const setEmailIsSaved = useCallback(
    () => setIsSaved((isSavedContact) => [isSavedContact[0], true, isSavedContact[2]]),
    [setIsSaved],
  );

  const setEmailIsValid = useCallback(
    (valid: boolean) => setIsValid((validContact) => [validContact[0], valid, validContact[2]]),
    [setIsValid],
  );

  const setEmailIsSubmitting = useCallback(
    (submitting: boolean) =>
      setIsSubmitting((submittingContact) => [
        submittingContact[0],
        submitting,
        submittingContact[2],
      ]),
    [setIsSubmitting],
  );

  const setFaxIsDirty = useCallback(
    (dirty: boolean) =>
      setIsDirty((isDirtyContact) => [isDirtyContact[0], isDirtyContact[1], dirty]),
    [setIsDirty],
  );

  const setFaxIsSaved = useCallback(
    () => setIsSaved((isSavedContact) => [isSavedContact[0], isSavedContact[1], true]),
    [setIsSaved],
  );

  const setFaxIsValid = useCallback(
    (valid: boolean) => setIsValid((validContact) => [validContact[0], validContact[1], valid]),
    [setIsValid],
  );

  const setFaxIsSubmitting = useCallback(
    (submitting: boolean) =>
      setIsSubmitting((submittingContact) => [
        submittingContact[0],
        submittingContact[1],
        submitting,
      ]),
    [setIsSubmitting],
  );

  const iconSize24 = 24;

  return (
    <DxCard className="pd_contact-container" style={{ display, width: '100%' }}>
      <FormHeader
        subtitleText={i18next.t('contact.instructionTelephone')}
        subtitleIcon={<DxIcon icon={checkIcon('information')} size={iconSize24} color="gray-83" />}
      />
      <div
        style={{ padding: isMobile ? style.mobilePadding : style.desktopPadding, clear: 'both' }}
      >
        <TelephoneSection
          personId={personId}
          data={communicationChannelTelephoneData}
          onFormDirtyStatusChanged={setTelephoneIsDirty}
          onFormValidStatusChanged={setTelephoneIsValid}
          onFormSubmittingStatusChanged={setTelephoneIsSubmitting}
          onFormSaved={setTelephoneIsSaved}
          getCanContinueHandler={getCanContinueHandler}
          onContinuationRequest={onContinuationRequest}
          display={display}
          setTriggerValidationForms={setTriggerValidationForms}
        />
        <EmailSection
          data={communicationChannelEmailData}
          personId={personId}
          onFormDirtyStatusChanged={setEmailIsDirty}
          onFormValidStatusChanged={setEmailIsValid}
          onFormSubmittingStatusChanged={setEmailIsSubmitting}
          onFormSaved={setEmailIsSaved}
          getCanContinueHandler={getCanContinueHandler}
          onContinuationRequest={onContinuationRequest}
          display={display}
          setTriggerValidationForms={setTriggerValidationForms}
        />
        <FaxSection
          data={communicationChannelFaxData}
          personId={personId}
          onFormDirtyStatusChanged={setFaxIsDirty}
          onFormValidStatusChanged={setFaxIsValid}
          onFormSubmittingStatusChanged={setFaxIsSubmitting}
          onFormSaved={setFaxIsSaved}
          getCanContinueHandler={getCanContinueHandler}
          onContinuationRequest={onContinuationRequest}
          display={display}
          setTriggerValidationForms={setTriggerValidationForms}
        />{' '}
      </div>
    </DxCard>
  );
};
