import {
  DxButton,
  DxDropdown,
  DxDropdownOption,
  DxText,
  DxTextInput,
} from '@dvag/design-system-react';

import * as yup from 'yup';

import i18next from 'i18next';
import React, {
  ReactFragment,
  RefCallback,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from 'react';
import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import {
  CreateOrUpdateResource,
  DeleteResource,
} from '@dvag/dfs-ui-blocks/hooks/useAutosaveHelper';
import { defaultTelephoneData, keyTelephoneSectionName, telephoneOrFaxKeyList } from 'form/util';
import { validatePhoneOrFaxNumber } from 'form/validation';
import { useCreateOrUpdateCommunicationChannel } from 'hooks/useCreateOrUpdateCommunicationChannel';
import { useDeleteCommunicationChannel } from 'hooks/useDeleteCommunicationChannel';

import { style } from 'styleConfig';
import { ITelephoneForm, TFormValueList } from 'type/contact';
import { FormType, FormTypes, SecondaryFormType, TriggerValidationValue } from 'utils/fieldList';
import { checkIcon, telephoneTypeOptionList } from 'utils/util';
import { checkIsMobile } from 'utils/windowSize';

import { ContinutationRequest } from 'utils/useContinuation';
import { useContactDialCodeOptionList } from '../action/useContactDialCodeOptionList';

import '../style.css';
import { isDeleteDisabled, SECOND_SECTION_INDEX } from './common';

const translation = {
  selectPlaceholder: i18next.t('general.selectDefaultPlaceholder'),
  inputPlaceholder: i18next.t('general.inputDefaultPlaceholder'),
  addTelephoneNumber: i18next.t('contact.addTelephoneNumber'),
};

type TelephoneSectionProps = {
  personId: string | undefined;
  data: ITelephoneForm[];
  onFormDirtyStatusChanged: (isDirty: boolean) => void;
  onFormValidStatusChanged: (isValid: boolean) => void;
  onFormSubmittingStatusChanged: (isSubmitting: boolean) => void;
  onFormSaved: () => void;
  getCanContinueHandler: () => (canContinueCond: boolean) => void;
  onContinuationRequest: (continuationRequestCallback: ContinutationRequest) => void;
  display: string;
  setTriggerValidationForms: (
    value: TriggerValidationValue,
    formType: FormTypes,
    secondaryFormType?: SecondaryFormType,
  ) => void;
};

type DialCodeListProps = {
  index: number;
  register: (fieldName: string, resourceIndex?: number) => { ref: RefCallback<unknown> };
  dialCodeListMemoized: ReactFragment;
};

const DialCodeList = (props: DialCodeListProps) => {
  const { index, register, dialCodeListMemoized } = props;

  return (
    <DxDropdown
      size="m"
      placeholder={i18next.t('general.selectDefaultPlaceholder')}
      className="pd_contact_prefix"
      data-testid={`pd-prefixType-${index}`}
      id={`pd-telephoneType-${index}`}
      label={i18next.t('contact.prefix')}
      required={false}
      clearable={false}
      {...register('prefix', index)}
    >
      {dialCodeListMemoized}
    </DxDropdown>
  );
};

export const TelephoneSection = ({
  personId,
  data,
  onFormValidStatusChanged,
  onFormDirtyStatusChanged,
  onFormSubmittingStatusChanged,
  onFormSaved,
  getCanContinueHandler,
  onContinuationRequest,
  setTriggerValidationForms,
}: TelephoneSectionProps): JSX.Element => {
  const resourceSchema = useMemo(
    () =>
      yup.object({
        value: yup
          .string()
          .required(i18next.t('general.mandatoryField'))
          .test(
            'validatePhoneOrFaxNumber',
            i18next.t('contact.errorMessage'),
            (x) => validatePhoneOrFaxNumber(x) === true,
          )
          .nullable(),
      }),
    [],
  );

  const [, startTransition] = useTransition();
  const deleteCommunicationChannel = useDeleteCommunicationChannel(personId);
  const createOrUpdateCommunicationChannel = useCreateOrUpdateCommunicationChannel(personId);

  const { dialCodeListMemoized } = useContactDialCodeOptionList();

  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    flushChanges,
    getAllResources,
    append,
    resources,
    register,
    hasTouchedResources,
    remove,
    triggerValidation,
  } = useAutosave<ITelephoneForm>({
    data,
    defaultData: defaultTelephoneData,
    createResource:
      createOrUpdateCommunicationChannel.mutateAsync as CreateOrUpdateResource<ITelephoneForm>,
    updateResource:
      createOrUpdateCommunicationChannel.mutateAsync as CreateOrUpdateResource<ITelephoneForm>,
    onIsValidChange: (isValidParam) => {
      onFormValidStatusChanged(isValidParam);
    },
    onSubmitSuccessfulChange: (isSubmitSuccessfulParam) => {
      if (isSubmitSuccessfulParam) onFormSaved();
    },
    onIsDirtyChange: (isDirtyParam) => {
      setIsDirty(isDirtyParam);
      onFormDirtyStatusChanged(isDirtyParam);
      onCanContinueChange(!isDirtyParam);
    },
    onIsSubmittingChange: (isSubmittingParam) => {
      setIsSubmitting(isSubmittingParam);
      onFormSubmittingStatusChanged(isSubmittingParam);
    },
    resourceSchema,
    deleteResource:
      deleteCommunicationChannel.mutateAsync as unknown as DeleteResource<ITelephoneForm>,
  });

  useEffect(() => {
    onContinuationRequest(() => {
      flushChanges();
      triggerValidation();
    });
  }, [flushChanges, onContinuationRequest, triggerValidation]);

  useEffect(() => {
    setTriggerValidationForms(
      triggerValidation as TriggerValidationValue,
      FormType.contact,
      'telephone',
    );
  }, [setTriggerValidationForms, triggerValidation]);

  const isMobile = checkIsMobile();

  const onAppend = () => {
    startTransition(() => {
      append(defaultTelephoneData, { shouldFocus: false });
    });
  };

  return (
    <div
      data-testid="telephone-section"
      className="pd_telephone-content-container"
      style={{ width: 'inherit', height: 'auto', clear: 'right' }}
    >
      <br />
      <DxText color="headline" type="Paragraph-Standard" style={style.mainTitle}>
        {i18next.t('contact.telephoneTitle')}
      </DxText>
      <div>
        <form className="pd_contact-phone-section" style={{ paddingTop: '16px', clear: 'right' }}>
          {resources &&
            resources.map((item, index) => {
              register(`${keyTelephoneSectionName}.${index}.id`);
              return (
                <div
                  key={item.id}
                  className="pd_contact_container"
                  style={{
                    gridTemplateColumns: `${style.contactPhoneColumnGridTemplate} min-content`,
                    clear: 'right',
                  }}
                >
                  <DxDropdown
                    size="m"
                    placeholder={translation.selectPlaceholder}
                    className="pd_contact_type"
                    data-testid={`pd_phoneType-${index}`}
                    id={`pd_phoneType-${index}`}
                    required={false}
                    label={`${i18next.t('contact.telephoneType')}`}
                    clearable={false}
                    {...register(`type`, index)}
                  >
                    {telephoneTypeOptionList?.map(({ value, label }) => (
                      <DxDropdownOption
                        key={`${item.id}-${value}-${label}`}
                        value={value}
                        label={label}
                      />
                    ))}
                  </DxDropdown>
                  <DialCodeList
                    dialCodeListMemoized={dialCodeListMemoized}
                    register={register}
                    index={index}
                  />
                  <DxTextInput
                    nominmaxlabel
                    size="m"
                    placeholder={translation.inputPlaceholder}
                    className="pd_contact_value"
                    required={hasTouchedResources()}
                    maxlength={14}
                    data-testid={`pd_phone-${index}`}
                    id={`pd_phone-${index}`}
                    label={`${i18next.t('contact.telephone')}`}
                    {...register('value', index)}
                  />
                  <DxButton
                    type="text"
                    disabled={isDeleteDisabled(
                      index,
                      getAllResources() as TFormValueList,
                      telephoneOrFaxKeyList,
                      isSubmitting || isDirty,
                      SECOND_SECTION_INDEX,
                    )}
                    className="pd_contact_delete"
                    onClick={() => remove(index)}
                    data-testid={`pd_deletePhoneSection-${index}`}
                    id={`pd_deletePhoneSection-${index}`}
                    icon={checkIcon('loeschen')}
                    theme="destructive"
                  />
                </div>
              );
            })}
        </form>
      </div>
      <DxButton
        type="text"
        className="pd_contact_addNewSection"
        data-testid="pd_addTelephoneSection"
        style={{ float: 'right', margin: isMobile ? '0 0 32px 0' : '24px 0 32px 0' }}
        id="pd_addTelephoneSection"
        disabled={getAllResources().some((p) => p.id === '' || p.id === undefined)}
        onClick={() => onAppend()}
        label={translation.addTelephoneNumber}
        icon={checkIcon('plus-kreis')}
      />
    </div>
  );
};
