import { useMutation, useQueryClient } from '@tanstack/react-query';

import { personApi } from 'service/api/personApi';
import { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';
import { useNotification } from 'utils/notification/NotificationContext';

export const useDeleteAddress = (personId: string | undefined) => {
  const { showError } = useNotification();
  const queryClient = useQueryClient();
  const personService = personApi();

  return useMutation<void, ErrorRequestEntity, string | undefined>({
    mutationFn: async (addressId) => {
      const response = await personService.deleteAddress(`${addressId}`, personId);

      return response.data;
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [`address-${personId}`] });
    },
    onError: (error) => {
      showError({ status: error?.response?.status });
    },
  });
};
