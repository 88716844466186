import { CreateOrUpdateResource } from '@dvag/dfs-ui-blocks/hooks/useAutosaveHelper';
import { RefCallBack } from 'react-hook-form';

type RegisterField = <T1>(
  fieldName: string,
  resourceIndex?: number,
) => {
  onValueChange: (e: { detail: unknown }) => void;
  errormessage: string;
  value: T1;
  resourceFieldName: string;
  ref: RefCallBack;
};

export interface SavingProfileFormValues {
  id?: string;
  saverProfileCurrent: number;
  saverProfileDesired: number;
  hasAsset: boolean;
  hasConsumerLoan: boolean;
  hasConsumption: boolean;
  hasDebt: boolean;
  hasDiscipline: boolean;
  hasDuePaymentNotice: boolean;
  hasOverdraft: boolean;
  hasPlusMinusZero: boolean;
  hasRevenueInterest: boolean;
  hasSavingBook: boolean;
  hasStrategy: boolean;
  hasTwoAccountModel: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface CheckBox {
  name: keyof SavingProfileFormValues;
}

export interface Card {
  checkBoxList: CheckBox[];
  id: string;
  image: string;
  text: string;
  title: string;
}

export interface SavingTypeCardProps {
  card: Card;
  currentStep?: string;
  savingTypeId: string;
  register: RegisterField;
  setValue?: <TF>(fieldName: string, resourceFieldValue: TF, resourceIndex?: number) => void;
  getValues: (fieldName: string, resourceIndex?: number) => string | undefined;
}

export interface CheckBoxGroupProps {
  checkBoxList: CheckBox[];
  currentStep?: string;
  register: RegisterField;
  setValue?: <TF>(fieldName: string, resourceFieldValue: TF, resourceIndex?: number) => void;
  getValues: (fieldName: string, resourceIndex?: number) => string | undefined;
}

export interface SavingTypeFormProps {
  savingProfile?: SavingProfileFormValues;
}

export interface UseSaverProfileResponse {
  mutateAsync: CreateOrUpdateResource<SavingProfileFormValues>;
  isSuccess: boolean;
  isError: boolean;
}
export interface SaverFields {
  saverProfileCurrent: 'saverProfileCurrent';
  saverProfileDesired: 'saverProfileDesired';
  hasAsset: 'hasAsset';
  hasConsumerLoan: 'hasConsumerLoan';
  hasConsumption: 'hasConsumption';
  hasDebt: 'hasDebt';
  hasDiscipline: 'hasDiscipline';
  hasDuePaymentNotice: 'hasDuePaymentNotice';
  hasOverdraft: 'hasOverdraft';
  hasPlusMinusZero: 'hasPlusMinusZero';
  hasRevenueInterest: 'hasRevenueInterest';
  hasSavingBook: 'hasSavingBook';
  hasStrategy: 'hasStrategy';
  hasTwoAccountModel: 'hasTwoAccountModel';
}
export const SaverFieldType: SaverFields = {
  saverProfileCurrent: 'saverProfileCurrent',
  saverProfileDesired: 'saverProfileDesired',
  hasAsset: 'hasAsset',
  hasConsumerLoan: 'hasConsumerLoan',
  hasConsumption: 'hasConsumption',
  hasDebt: 'hasDebt',
  hasDiscipline: 'hasDiscipline',
  hasDuePaymentNotice: 'hasDuePaymentNotice',
  hasOverdraft: 'hasOverdraft',
  hasPlusMinusZero: 'hasPlusMinusZero',
  hasRevenueInterest: 'hasRevenueInterest',
  hasSavingBook: 'hasSavingBook',
  hasStrategy: 'hasStrategy',
  hasTwoAccountModel: 'hasTwoAccountModel',
};
