import { getGraphQLClient } from 'graphql/graphQLClient';
import { useGetPersonWishGoalListQuery } from 'graphql/generates';

export const useGetPersonWishGoalList = (personId: string) => {
  const query = useGetPersonWishGoalListQuery(
    getGraphQLClient(),
    {
      personId,
    },
    {
      enabled: !!personId,
      select: (data) => data.person.wishGoal,
    },
  );

  return { ...query, data: query.data || [] };
};
