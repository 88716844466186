import { TaxData } from 'type/taxData';

export const defaultTaxData: TaxData = {
  taxableIncomePreviousYear: undefined,
  incomeSocialInsurancePreviousYear: undefined,
  childAllowance: undefined,
  churchTax: undefined,
  taxAdvisor: undefined,
  taxNumber: undefined,
  taxReturnType: undefined,
};
