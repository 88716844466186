import React from 'react';

import { DxText } from '@dvag/design-system-react';

import i18next from 'i18next';

import './style.css';

export const Tick = ({ percent }: { percent?: number }) => {
  const leftPosition = () => {
    if (!percent) return `0%`;
    if (percent < 4) return `${percent}%`;
    if (percent > 90) return `calc(${percent}% - 70px)`;
    return `calc(${percent}% - 35px)`;
  };
  return (
    <div
      className="tick-wrapper flex column align-center"
      data-testid="tick-wrapper"
      style={{ left: leftPosition(), top: '20px' }}
    >
      <div className="line" />
      <DxText className="tick-label">{i18next.t('savingType.saverProfileCurrent')}</DxText>
    </div>
  );
};
