import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

import en from './en.json';
import de from './de.json';

export const locales = {
  german: 'de',
  english: 'en',
};

export const resources = {
  en,
  de,
};

export const defaultNS = 'person';

export const defaultLocale = locales.german;

export const getLocale = (): string => i18n.language;

export const isGermanLocale = () => getLocale() === locales.german;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS,
    detection: {
      order: ['navigator'],
    },
    fallbackLng: defaultLocale,
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

export default i18n;
