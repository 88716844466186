import APIClient from '@dvag/dfs-api-client';
import * as context from '@dvag/dfs-orchestrator-client';
import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config';
import {
  ProfessionFormData,
  ProfessionPayloadData,
  ProfessionPayloadDataUpdate,
  ProfessionSearch,
} from 'type/job';
import { Address, AddressPayload, ValidateAddress } from 'type/address';
import { TaxData, TaxDataUpdatePayload, TransformParameters } from 'type/taxData';
import { CommunicationChannelSectionDataType } from 'type/person';
import { SavingProfileFormValues } from 'type/savingType';
import { RemoveType } from 'type/general';
import { ICreatePerson, ManagePerson } from 'type/createPerson';
import { BasicData, BasicDataPayload } from 'type/basicData';

import { ConsentDto, PersonWishGoalDto } from 'graphql/generates';
import { ApiResponse } from '@dvag/dfs-api-client/interface';
import { versions } from './versions';

type CommunicationChannelPayload = CommunicationChannelSectionDataType | TransformParameters;

const config = { baseURL: `${getEnvironmentApiUrls().person}/${versions.v3}` };
export const api = new APIClient({
  config,
  options: {
    getToken: context.getToken,
    getContext: context.getContext,
    hasDefaultRequestInterceptors: true,
    hasDefaultResponseinterceptors: true,
  },
});

export const personApi = () => {
  const createPersonAndHousehold = async (payload: ICreatePerson) =>
    api.post<ManagePerson, ICreatePerson>('/person', payload);

  const updatePerson = async (personId: string, payload: BasicDataPayload) =>
    api.put<BasicData, BasicDataPayload>(`/person/${personId}`, payload);

  const updateLastAccessedHouseholdList = (householdId: string) =>
    api.put<never, never[]>(`/household/${householdId}/last-accessed`, []);

  const createPersonInHousehold = async (householdId: string, payload: ICreatePerson) =>
    api.post<ManagePerson, ICreatePerson>(`/household/${householdId}/person`, payload);

  const createAddress = async (payload: AddressPayload, personId: string | undefined) =>
    api.post<Address, AddressPayload>(`/person/${personId}/address`, payload);

  const updateAddress = async (id: string, data: AddressPayload, personId: string | undefined) =>
    api.put<Address, AddressPayload>(`/person/${personId}/address/${id}`, data);

  const deleteAddress = async (addressId: string, personId: string | undefined) =>
    api.delete<void>(`/person/${personId}/address/${addressId}`);

  const createCommunicationChannel = async (
    payload: CommunicationChannelPayload,
    personId: string | undefined,
  ) =>
    api.post<CommunicationChannelSectionDataType, CommunicationChannelPayload>(
      `/person/${personId}/communication-channel`,
      payload,
    );

  const updateCommunicationChannel = async (
    id: string,
    payload: CommunicationChannelPayload,
    personId: string | undefined,
  ) =>
    api.put<CommunicationChannelSectionDataType, CommunicationChannelPayload>(
      `/person/${personId}/communication-channel/${id}`,
      payload,
    );

  const deleteCommunicationChannel = async (communicationChannelId: string, personId: string) =>
    api.delete<void>(`/person/${personId}/communication-channel/${communicationChannelId}`);

  const createTaxData = async (
    payload: TaxDataUpdatePayload | RemoveType,
    personId: string | undefined,
  ) => api.post<TaxData, TaxDataUpdatePayload | RemoveType>(`/person/${personId}/tax/`, payload);

  const updateTaxData = async (personId: string, payload: TaxDataUpdatePayload | RemoveType) =>
    api.put<TaxData, TaxDataUpdatePayload | RemoveType>(`/person/${personId}/tax/`, payload);

  const getAddressValidation = async (country: string, searchKey: string) =>
    api.get<Address[]>(`/address/search?query=${searchKey}&country=${country}&limit=10`);

  const validateAddress = async (query: string) =>
    api.get<ValidateAddress>(`/address/validate${query}`);

  const createProfession = async (personId: string, payload: ProfessionPayloadDataUpdate) =>
    api.post<ProfessionFormData, ProfessionPayloadDataUpdate>(
      `/person/${personId}/profession/`,
      payload,
    );

  const updateProfession = async (personId: string, payload: ProfessionPayloadDataUpdate) =>
    api.put<ProfessionFormData, ProfessionPayloadData>(`/person/${personId}/profession/`, payload);

  const searchProfession = async (profession: string | undefined) =>
    api.get<ProfessionSearch[]>(`/profession/search/`, {
      params: { query: profession },
    });

  const copyWishAndGoal = async (
    personId: string,
    payload: {
      personIdList: string[];
    },
  ) =>
    api.post<Omit<PersonWishGoalDto, '__typename'>, { personIdList: string[] }>(
      `/person/${personId}/wish-goal/copy`,
      payload,
    );

  const createSaverProfile = async (
    payload: SavingProfileFormValues,
    householdId: string | undefined,
  ): Promise<ApiResponse<SavingProfileFormValues>> =>
    api.post<SavingProfileFormValues>(`/household/${householdId}/saver-profile`, payload);

  const updateSaverProfile = async (
    householdId: string | undefined,
    payload: SavingProfileFormValues,
  ) =>
    api.put<SavingProfileFormValues, SavingProfileFormValues>(
      `household/${householdId}/saver-profile`,
      payload,
    );

  const sendBankConnectionEmail = async (payload: { householdId: string; personId: string }) =>
    api.post<Omit<ConsentDto, '__typename'>, { householdId: string; personId: string }>(
      '/flc/send-email',
      payload,
    );

  return {
    createPersonAndHousehold,
    updatePerson,
    updateLastAccessedHouseholdList,
    createPersonInHousehold,
    createAddress,
    updateAddress,
    deleteAddress,
    createCommunicationChannel,
    updateCommunicationChannel,
    deleteCommunicationChannel,
    createTaxData,
    updateTaxData,
    getAddressValidation,
    validateAddress,
    createProfession,
    updateProfession,
    searchProfession,
    copyWishAndGoal,
    createSaverProfile,
    updateSaverProfile,
    sendBankConnectionEmail,
  };
};
