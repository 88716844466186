import React, { useMemo } from 'react';
import { DxOverflowMenu, DxOverflowMenuItem } from '@dvag/design-system-react';
import { usePersonList } from 'hooks/usePersonList';
import { getPersonFullName } from 'utils/util';
import './style.css';

interface PersonDropdownProps {
  className?: string;
  selectedId: string;
  onChange?: (id: string) => void;
}

export const PersonDropdown = ({ className, selectedId, onChange }: PersonDropdownProps) => {
  const personList = usePersonList();
  const selectedPerson = useMemo(
    () => personList.data.find((person) => person.id === selectedId),
    [personList.data, selectedId],
  );

  const truncateString = (str: string) => {
    if (str.length > 16) {
      return `${str.slice(0, 16)}...`;
    }
    return str;
  };

  return (
    <DxOverflowMenu
      align="right"
      id="wg-person-dropdown"
      data-testid="select-person"
      className={`dropdown ${className}`}
      label={selectedPerson ? getPersonFullName(selectedPerson) : ''}
      onSelection={(e) => {
        onChange?.(e.detail.value as string);
      }}
    >
      {personList.data.map((person, index) => (
        <DxOverflowMenuItem
          id={`wg-person-dropdown-option-${index}`}
          value={person.id}
          label={truncateString(getPersonFullName(person))}
          key={person.id}
        />
      ))}
    </DxOverflowMenu>
  );
};
