import { useQueryClient } from '@tanstack/react-query';
import { getGraphQLClient } from 'graphql/graphQLClient';
import { useGetPersonForBankSelectionQuery } from 'graphql/generates';
import { PersonConsentType } from 'type/consent';

export const useGetPersonForBankSelection = (personId: string) => {
  const queryClient = useQueryClient();

  const invalidateQuery = () =>
    queryClient.invalidateQueries({ queryKey: ['getPersonForBankSelection', { personId }] });

  const query = useGetPersonForBankSelectionQuery(
    getGraphQLClient(),
    {
      personId,
    },
    {
      enabled: !!personId,
      select: (data) => ({
        finleapConsent: data.person.consent?.find(({ type }) => type === PersonConsentType.Finleap),
        gdprConsent: data.person.consent?.find(({ type }) => type === PersonConsentType.Gdpr),
        communicationChannelList: data.person.communicationChannel || [],
      }),
    },
  );

  return { ...query, invalidateQuery };
};
