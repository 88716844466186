export const style = {
  mainContainer: {
    backgroundColor: 'none',
    flexDirection: 'column',
    background: 'none',
    clear: 'both',
    width: '100vw',
    marginBottom: '30px',
  },
  mainHeader: {
    display: 'flex',
    background: 'none',
    padding: '24px',
    maxWidth: '1200px',
    alignSelf: 'flex-start',
  },
  navigatedContent: {
    padding: '0px',
    margin: '0px',
    background: '#ededed',
  },
  menu: {
    margin: '0px',
    padding: '0px',
    alignItems: 'center',
    alignContent: 'center',
    background: '#F7F7F7',
  },
  formContainer: {
    backgroundColor: 'white',
  },
  footer: {
    background: 'none',
    display: 'flex',
    flexDirection: 'row',
  },
  displayType: {
    initial: 'initial',
    none: 'none',
  },
  mobilePadding: '0 16px 32px 16px',
  desktopPadding: '0 24px 32px 24px',
  mainTitle: {
    alignSelf: 'flex-start',
    float: 'left',
    paddingBottom: '24px',
  },
  addressTitleContainer: {
    padding: '24px 0 0 0',
    width: '100%',
  },
  contactPhoneColumnGridTemplate: 'minmax(28%, auto) minmax(115px, 20%) minmax(28%, auto)',
  contactEmailColumnGridTemplate: 'minmax(30%, auto) minmax(30%, auto)',
  bankDetailsColumnGridTemplate: 'minmax(auto, auto)',
};
