import React from 'react';
import { DxButton, DxContainer } from '@dvag/design-system-react';
import i18next from 'i18next';
import { checkIcon } from 'utils/util';
import './SendEmailButton.css';

type SendEmailButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
};

export const SendEmailButton = (props: SendEmailButtonProps) => (
  <DxContainer color="transparent" className="bc-process-description-send-email">
    <DxButton
      {...props}
      id="bc-send-email-button"
      data-testid="bc-send-email-button"
      type="primary-s"
      icon={checkIcon('mail')}
      iconposition="right"
      label={i18next.t('bankSelectionPage.processDescriptionCard.contact.generateEmail')}
    />
  </DxContainer>
);
