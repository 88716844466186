import React from 'react';

import { DxContainer, DxGrid } from '@dvag/design-system-react';

import { LoadingCard } from './LoadingCard';

import '../style.css';
import { TopDisplayType } from '../../type';

const cards = Array.from(Array(10).keys());

type LoadingProps = { props: TopDisplayType };
export const Loading = ({ props: topDisplayType }: LoadingProps) => {
  const containerStyle = topDisplayType === 'hide' ? 'grid-wrapper' : 'grid-wrapper has-top';
  return (
    <DxContainer color="transparent" className={containerStyle}>
      <DxGrid
        className="grid-list"
        mq5="2-2-2-2-2-(2)/2-2-2-2-2-(2)"
        mq4="2-2-2-2-2-(2)/2-2-2-2-2-(2)"
        mq3="4-4-4/*"
        mq2="12/*"
        mq1="12/*"
      >
        {cards.map((val) => (
          <LoadingCard key={val} />
        ))}
      </DxGrid>
    </DxContainer>
  );
};
