import i18next from 'i18next';
import { useEffect } from 'react';

import { DxModal, DxText } from '@dvag/design-system-react';

import './style.css';

interface PendingRequestModalProps {
  visible: boolean;
  subscribeOnModalClose: (() => void) | undefined;
}

export const PendingRequestModal = ({
  visible,
  subscribeOnModalClose,
}: PendingRequestModalProps) => {
  useEffect(() => {
    if (subscribeOnModalClose) {
      window.addEventListener('modalClosed', subscribeOnModalClose);
      return () => window.removeEventListener('modalClosed', subscribeOnModalClose);
    }
    return () => undefined;
  }, [subscribeOnModalClose]);

  return (
    <DxModal
      height="content"
      id="moving-away-alert"
      data-testid="moving-away-alert-pending"
      visible={visible}
    >
      <div slot="content" className="saving-data-modal">
        <DxText id="savingData" type="ps">
          {i18next.t('warning.savingData')}
        </DxText>
      </div>
    </DxModal>
  );
};
