import React from 'react';

import { DxProcessHeader, DxText } from '@dvag/design-system-react';
import i18next from 'i18next';

import './style.css';

interface SavingTypeHeaderProps {
  subtitle: string | null;
}

export const SavingTypeHeader = ({ subtitle }: SavingTypeHeaderProps) => (
  <DxProcessHeader
    id="savings-type-title"
    data-testid="savings-type-title"
    className={subtitle ? 'savings-type-title' : 'savings-type-title-no-subtitle'}
    headline={i18next.t('savingType.title')}
  >
    <DxText
      slot="note"
      className="savings-type-subtitle"
      type="h5"
      data-testid="savings-type-subtitle"
    >
      {subtitle}
    </DxText>
  </DxProcessHeader>
);
