import { DxCard, DxContainer, DxIcon, DxText } from '@dvag/design-system-react';

import i18next from 'i18next';

import { checkIcon, familyRelationshipList } from 'utils/util';

import './style.css';

type PropType = string | null | undefined;

interface PersonHeaderProps {
  firstName: PropType;
  lastName: PropType;
  familyRelationship?: string;
}

export const PersonHeader = ({
  firstName,
  lastName,
  familyRelationship = familyRelationshipList.ERWACHSEN,
}: PersonHeaderProps) => {
  const iconSize = 24;
  return (
    <DxContainer color="transparent">
      <DxCard>
        {!!firstName && (
          <div className="pd_person-header-container align-items-center">
            <div className="pd_person-text-container flex column align-items-start">
              <DxText
                color="headline"
                className="pd_firstName person-name"
                data-testid="pd_header_firstName"
                type="Paragraph-Standard"
              >
                {firstName} {lastName}
              </DxText>
              <div className="pd_person-type-container flex align-items-center align-content-start">
                <DxText className="pd_person-type" type="Infotext-Standard" color="gray-66">
                  {familyRelationship === familyRelationshipList.KIND
                    ? i18next.t('general.child')
                    : i18next.t('general.adult')}
                </DxText>
              </div>
            </div>
            <div className="shape-container flex column align-content-end align-items-end">
              <div className="triangle" />
              <div className="pd_person-icon-container flex">
                <DxIcon
                  className="mensch-icon"
                  icon={checkIcon('mensch')}
                  color="white"
                  size={iconSize}
                />
              </div>
            </div>
          </div>
        )}
      </DxCard>
    </DxContainer>
  );
};

interface MultipleSectionsHeaderProps {
  title: string;
  activeSectionsLabels: string[];
}

export const MultipleSectionsHeader = ({
  title,
  activeSectionsLabels,
}: MultipleSectionsHeaderProps) => {
  const iconSize = 24;
  return (
    <DxContainer color="transparent">
      <DxCard>
        {!!title && (
          <div className="pd_person-header-multiple-sections align-items-center">
            <div className="shape-container flex column align-content-end align-items-end pd_icon">
              <DxIcon icon="information" size={iconSize} color="error" />
            </div>
            <div className="pd_person-text-container flex column align-items-start">
              <DxText
                color="headline"
                className="pd_firstName person-name"
                type="Paragraph-Standard"
              >
                {title}
              </DxText>
              <div className="pd_person-type-container flex align-items-center align-content-start">
                <DxText className="pd_person-type" type="Infotext-Standard" color="gray-66">
                  {activeSectionsLabels.reduce((acc, cur) => `${acc}${acc ? ', ' : ''}${cur}`, '')}
                </DxText>
              </div>
            </div>
          </div>
        )}
      </DxCard>
    </DxContainer>
  );
};
