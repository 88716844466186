import { QueryClient } from '@tanstack/react-query';
import { STATUS_FORBIDDEN, STATUS_UNAUTHORIZED } from 'utils/util';

type TError = {
  response: {
    status: number;
  };
  config: {
    url: string;
  };
};

const checkException = (error: TError | Error) =>
  [STATUS_FORBIDDEN, STATUS_UNAUTHORIZED].includes((error as TError).response?.status);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      throwOnError: true,
    },
    mutations: {
      throwOnError: (error) => checkException(error),
    },
  },
});
