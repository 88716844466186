import { navigateNext, navigatePrevious } from '@dvag/dfs-orchestrator-client/messengers';
import {
  DxButton,
  DxCard,
  DxContainer,
  DxGrid,
  DxMediaQueryValue,
  DxProcessHeader,
  DxSiteNavigation,
  DxSiteNavigationMenu,
  DxSiteNavigationMenuItem,
  DxSpacer,
} from '@dvag/design-system-react';
import i18next from 'i18next';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { HeaderPlaceholder } from 'component/DataFormPlaceholder/DataFormHeader';
import { FormPlaceholder } from 'component/DataFormPlaceholder/FormPlaceholder';
import { MultipleSectionsHeader, PersonHeader } from 'component/PersonHeader';
import { UnsavedOrPendingModal } from 'component/UnsavedOrPendingModal';
import { route } from 'config/route';
import { AddressForm } from 'form/address/AddressForm';
import { BankAccountForm } from 'form/bankAccount/BankAccountForm';
import { BasicDataForm } from 'form/BasicDataForm';
import { ContactForm } from 'form/contact/ContactForm';
import { defaultProfessionData } from 'form/profession/defaultOccupationData';
import { ProfessionForm } from 'form/profession/ProfessionForm';
import { defaultTaxData } from 'form/taxData/defaultTaxData';
import { TaxDataForm } from 'form/taxData/TaxDataForm';
import {
  setIsDirtyStatus,
  setIsSubmittingStatus,
  setIsValidStatus,
  setSuccessStatus,
} from 'form/util';
import { useGetBasicData } from 'hooks/useGetBasicData';
import { useGetPersonalData } from 'hooks/useGetPersonalData';
import { handleNavigateToPath } from 'service/handleNavigateToPath';
import { style } from 'styleConfig';
import type {
  FormTypes,
  SecondaryFormType,
  TriggerValidationForms,
  VisitedForms,
} from 'utils/fieldList';
import { FormType, getForm, getVisitedFormsDefaultValues, navigateToForm } from 'utils/fieldList';
import { sendPersonalDataHasBeenUpdatedMessage } from 'utils/sendMessage';
import { Status } from 'utils/status';
import { useContinuation } from 'utils/useContinuation';
import { useValidateUrlParams } from 'utils/useValidateUrlParams';
import { assert, checkIcon, handleNavigationButtonLabel } from 'utils/util';
import { checkIsMobile } from 'utils/windowSize';
import type { PersonalDataState } from 'reducer';
import { BasicData } from 'type/basicData';
import { tabList } from './tablist';
import { usePathWithMultipleSections } from './usePathWithMultipleSections';
import { usePersonalDataReducer } from './usePersonalDataReducer';

import 'style.css';

interface NavigatedRoute {
  value: string;
  complete: () => void;
}

type Name = { firstName: string; lastName: string };

const translation = {
  householdManagement: i18next.t('general.householdManagement'),
  back: i18next.t('general.back'),
};

const formTypeEntries = Object.values(FormType);
const getActiveStep = (step: FormTypes): number => formTypeEntries.indexOf(step);

const shouldSetName = (stateName: Name, basicDataName: BasicData) =>
  (stateName.firstName !== basicDataName.firstName && !stateName.firstName) ||
  (stateName.lastName !== basicDataName.lastName && !stateName.lastName);

export const PersonalDataForm: FC = () => {
  const { t } = useTranslation();
  useValidateUrlParams();

  const { householdId, personId, activeForm: activeFromWithSections } = useParams();

  const { multipleSections, hasMultipleSections, multipleSectionsLabels, activeForm } =
    usePathWithMultipleSections(activeFromWithSections);

  assert(typeof personId === 'string');

  const { formState, updateFormState } = usePersonalDataReducer();
  const personalData = useGetPersonalData(personId, householdId);
  const basicData = useGetBasicData(personId, householdId);
  const [name, setName] = useState<Name>({
    firstName: '',
    lastName: '',
  });
  if (shouldSetName(name, basicData.data)) {
    setName({ firstName: basicData.data.firstName, lastName: basicData.data.lastName });
  }
  const { continuation, getContinuationHandler, getCanContinueHandler, onContinuationRequest } =
    useContinuation();

  const personOrder = basicData?.data.order;

  const [visitedForms, setVisitedForms] = useState<VisitedForms>(
    getVisitedFormsDefaultValues(activeForm),
  );

  const triggerValidationForms = useRef<TriggerValidationForms>({});

  const setTriggerValidationForms = (
    value: () => Promise<boolean[]>,
    formType: FormTypes,
    secondaryFormType?: SecondaryFormType,
  ) => {
    if (formType === FormType.contact) {
      triggerValidationForms.current = {
        ...triggerValidationForms.current,
        contact: {
          ...triggerValidationForms.current.contact,
          [secondaryFormType as SecondaryFormType]: value,
        },
      };
    } else {
      triggerValidationForms.current = {
        ...triggerValidationForms.current,
        [formType]: value,
      };
    }
  };

  const form = getForm(activeForm);

  const triggerFormValidation = useCallback(() => {
    if (form === FormType.contact) {
      triggerValidationForms.current.contact?.fax?.();
      triggerValidationForms.current.contact?.email?.();
      triggerValidationForms.current.contact?.telephone?.();
    } else {
      (triggerValidationForms.current[form] as () => Promise<boolean[]>)?.();
    }
  }, [form]);

  const setVisitedForm = useCallback((formValue: string) => {
    setVisitedForms((vf: VisitedForms) => ({
      ...vf,
      [formValue]: true,
    }));
  }, []);

  const onNavigate = ({ detail: { value } }: CustomEvent<NavigatedRoute>) => {
    triggerFormValidation();
    navigateToForm(value as FormTypes, householdId, personId);
    setVisitedForm(value);
    window.scrollTo(0, 0);
  };

  const handleFormDisplay = (activeFormType: keyof typeof FormType) =>
    form === activeFormType ? 'initial' : 'none';
  const isMobile = checkIsMobile();

  const navigateToHouseholdManagement = () => {
    getContinuationHandler(() =>
      handleNavigateToPath(route.manageHousehold, householdId, 'person'),
    )();
  };

  const navigateToNextStep = useCallback(
    (active: FormTypes) => {
      if (
        active === FormType.bankDetails ||
        (hasMultipleSections &&
          multipleSections?.every((section) => visitedForms[getForm(section)]))
      ) {
        const fallbackUrl = `/person/haushalt/${householdId}/verwalten`;
        getContinuationHandler(() =>
          hasMultipleSections ? navigateNext(fallbackUrl) : navigatePrevious(fallbackUrl),
        )();
      } else {
        const newNavigationPath = formTypeEntries[getActiveStep(active) + 1];
        triggerFormValidation();
        navigateToForm(newNavigationPath, householdId, personId);
        setVisitedForm(formTypeEntries[getActiveStep(active) + 1]);
        window.scrollTo(0, 0);
      }
    },
    [
      getContinuationHandler,
      hasMultipleSections,
      householdId,
      multipleSections,
      personId,
      setVisitedForm,
      triggerFormValidation,
      visitedForms,
    ],
  );

  const navigateToPreviousStep = (active: FormTypes) => {
    if (
      active === FormType.basicData ||
      (hasMultipleSections && multipleSections?.every((section) => visitedForms[getForm(section)]))
    ) {
      getContinuationHandler(() => navigatePrevious(`/person/haushalt/${householdId}/verwalten`))();
    } else {
      triggerFormValidation();
      navigateToForm(formTypeEntries[getActiveStep(active) - 1], householdId, personId);
      setVisitedForm(formTypeEntries[getActiveStep(active) - 1]);
      window.scrollTo(0, 0);
    }
  };

  const isPersonFormSubmitting = useCallback(
    () =>
      tabList.reduce(
        (result, tab) => result || formState[tab.key as keyof PersonalDataState].isSubmitting,
        false,
      ),
    [formState],
  );

  const onFormSaved = useCallback(
    (formType: FormTypes) => {
      setSuccessStatus(updateFormState, formType, true);
      sendPersonalDataHasBeenUpdatedMessage(householdId, personId);
    },
    [householdId, personId, updateFormState],
  );

  const onFormValidStatusChanged = useCallback(
    (formType: FormTypes, isValid: boolean) => {
      setIsValidStatus(updateFormState, formType, isValid);
    },
    [updateFormState],
  );

  const onFormDirtyStatusChanged = useCallback(
    (formType: FormTypes, isDirty: boolean) => {
      setIsDirtyStatus(updateFormState, formType, isDirty);
    },
    [updateFormState],
  );

  const onFormSubmittingStatusChanged = useCallback(
    (formType: FormTypes, isSubmitting: boolean) => {
      setIsSubmittingStatus(updateFormState, formType, isSubmitting);
    },
    [updateFormState],
  );

  const getMenuState = ({ key }: { key: string }) => {
    const { isDirty, isValid, isSuccess } = formState[key as keyof PersonalDataState];

    switch (true) {
      case !isValid:
        return Status.error;
      case isDirty && isValid:
        return Status.required;
      case isSuccess:
        return Status.success;
      default:
        return Status.none;
    }
  };

  const commonFormProps = {
    personId,
    householdId,
    onFormSaved,
    onFormDirtyStatusChanged,
    onFormValidStatusChanged,
    onFormSubmittingStatusChanged,
    getCanContinueHandler,
    onContinuationRequest,
    setTriggerValidationForms,
  };

  useEffect(() => {
    const menuElement = document.querySelector('#wb-menu') as HTMLDxSiteNavigationMenuElement;

    if (menuElement) menuElement.selectedvalue = form;
  }, [form]);

  return (
    <DxContainer color="transparent" data-testid="personal-data-form">
      <DxProcessHeader
        headline={i18next.t('general.personalInformation')}
        data-testid="pd_mainTitle"
      />
      <DxGrid mq1="12/*">
        <DxContainer type="page" color="transparent">
          <DxSpacer size="16v" />
          {basicData.isLoading || basicData.isFetching ? (
            <HeaderPlaceholder />
          ) : (
            <>
              {hasMultipleSections && (
                <>
                  <MultipleSectionsHeader
                    title={t('general.adjustSectionData')}
                    activeSectionsLabels={multipleSectionsLabels.current!}
                  />
                  <DxSpacer size="24v" />
                </>
              )}
              <PersonHeader
                firstName={name.firstName}
                lastName={name.lastName}
                familyRelationship={basicData?.data?.familyRelationship}
              />
            </>
          )}

          <DxSpacer>
            <DxMediaQueryValue mq5="24v" mq4="24v" mq3="0v" mq2="0v" mq1="0v" property="size" />
          </DxSpacer>
          <DxGrid class="basic-width-and-padding" mq4="1-11/12" mq3="12/*" mq2="12/*" mq1="12/*">
            <DxSiteNavigation style={style.navigatedContent} className="pd_navigated-content">
              <DxSiteNavigationMenu
                slot="menu"
                id="wb-menu"
                data-testid="pd_navigation"
                style={style.menu}
                className="pd_navigation_menu"
                onNavigate={onNavigate}
              >
                {tabList.map((tab) => (
                  <DxSiteNavigationMenuItem
                    data-testid={`site-navigation-menu-${tab.key}`}
                    key={tab.label}
                    label={tab.label}
                    value={tab.key}
                    state={getMenuState(tab)}
                  />
                ))}
              </DxSiteNavigationMenu>
            </DxSiteNavigation>
            <div>
              <DxCard className="pd_form-main-container no-border no-background">
                <DxCard className="pd_form-main-container no-border" style={style.formContainer}>
                  {visitedForms.basicData && (
                    <>
                      {(basicData.isLoading || basicData.isFetching) &&
                        form === FormType.basicData && (
                          <DxCard>
                            <FormPlaceholder />
                          </DxCard>
                        )}
                      {!(basicData.isLoading || basicData.isFetching) && (
                        <BasicDataForm
                          data={basicData?.data}
                          display={handleFormDisplay(FormType.basicData)}
                          order={personOrder}
                          onNameChange={setName}
                          {...commonFormProps}
                        />
                      )}
                    </>
                  )}
                  {!personalData.isLoading && !personalData.isFetching && (
                    <>
                      {visitedForms.address && (
                        <AddressForm
                          display={handleFormDisplay(FormType.address)}
                          data={personalData.data?.addressData}
                          {...commonFormProps}
                        />
                      )}
                      {visitedForms.contact && (
                        <ContactForm
                          display={handleFormDisplay(FormType.contact)}
                          data={personalData.data?.communicationChannelData || []}
                          {...commonFormProps}
                        />
                      )}
                      {visitedForms.profession && (
                        <ProfessionForm
                          display={handleFormDisplay(FormType.profession)}
                          data={personalData.data?.professionData || defaultProfessionData}
                          order={personOrder}
                          {...commonFormProps}
                        />
                      )}
                      {visitedForms.tax && (
                        <TaxDataForm
                          display={handleFormDisplay(FormType.tax)}
                          taxData={personalData.data?.taxData || defaultTaxData}
                          {...commonFormProps}
                        />
                      )}
                      {visitedForms.bankDetails && (
                        <BankAccountForm
                          display={handleFormDisplay(FormType.bankDetails)}
                          data={personalData.data?.bankAccountData}
                          {...commonFormProps}
                        />
                      )}
                    </>
                  )}
                  {form !== FormType.basicData &&
                    (personalData.isLoading || personalData.isFetching) && (
                      <DxCard>
                        <FormPlaceholder />
                      </DxCard>
                    )}
                </DxCard>
              </DxCard>
            </div>

            <DxContainer color="transparent">
              <DxCard
                className="no-border"
                style={{
                  ...style.footer,
                  margin: isMobile ? '0 16px' : '0',
                  flexDirection: isMobile ? 'column-reverse' : 'row',
                }}
              >
                <DxButton
                  id="pd_go-hh-management"
                  data-testid="pd_go-hh-management"
                  label={translation.householdManagement}
                  type="text"
                  icon={checkIcon('pfeil-links')}
                  iconposition="left"
                  onClick={navigateToHouseholdManagement}
                  style={{ marginRight: 'auto' }}
                />
                <DxGrid
                  mq5="6-6"
                  mq1="6-6"
                  className={isMobile ? 'mobile-footer-button-container' : ''}
                  style={{
                    minWidth: form === FormType.bankDetails ? '300px' : '',
                  }}
                >
                  <div className={isMobile ? 'mobile-back-button' : 'back-footer-button'}>
                    <DxButton
                      id="pd_go-back"
                      data-testid="pd_go-back"
                      label={translation.back}
                      type="text"
                      icon={checkIcon('pfeil-links')}
                      iconposition="left"
                      onClick={() => navigateToPreviousStep(form)}
                    />
                  </div>

                  <DxButton
                    className="pd_continue"
                    id="pd_continue"
                    data-testid="pd_continue"
                    type="primary-s"
                    icon={form !== FormType.bankDetails ? checkIcon('pfeil-rechts') : undefined}
                    iconposition="right"
                    label={handleNavigationButtonLabel(form)}
                    onClick={() => navigateToNextStep(form)}
                    stretch
                  />
                </DxGrid>
              </DxCard>
            </DxContainer>
          </DxGrid>
        </DxContainer>
        <UnsavedOrPendingModal
          showPending={continuation !== undefined && isPersonFormSubmitting()}
          showUnsaved={continuation !== undefined && !isPersonFormSubmitting()}
          onCancel={continuation?.onCancel}
          onConfirm={continuation?.onConfirm}
        />
      </DxGrid>
    </DxContainer>
  );
};
