import { DxContainer } from '@dvag/design-system-react';
import React from 'react';
import './FlcIframe.css';

type FinleapConnectIFrameProps = {
  link: string;
};

export const FlcIframe = ({ link }: FinleapConnectIFrameProps) => {
  if (!link) return null;

  return (
    <DxContainer
      color="transparent"
      className="flc-iframe-container"
      data-testid="ba-flc-iframe-container"
    >
      <iframe
        className="flc-iframe"
        title="finleap-connect"
        src={link}
        data-testid="ba-flc-iframe"
      />
    </DxContainer>
  );
};
