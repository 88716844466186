import { useGetPersonListManageHouseholdQuery } from 'graphql/generates';
import { getGraphQLClient } from 'graphql/graphQLClient';

export const getPersonListManageHouseholdQueryKey = (householdId?: string) => [
  'getPersonListManageHousehold',
  { householdId },
];

export const usePersonListManageHousehold = (householdId: string) => {
  const query = useGetPersonListManageHouseholdQuery(
    getGraphQLClient(),
    {
      householdId,
    },
    {
      enabled: !!householdId,
      select: (response) => response.household.basicData?.personInHouseholdList,
    },
  );

  return {
    ...query,
    data: query.data || [],
  };
};
