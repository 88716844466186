import { DxCheckbox, DxDropdown, DxGrid, DxTextInput } from '@dvag/design-system-react';
import i18next from 'i18next';
import React from 'react';
import { checkIfChild } from 'utils/util';
import { useMemoizedDropdownOptionList } from '../../../hooks/useMemoizedDropdownOptionList';

type PersonCardType = {
  firstName: string;
  lastName: string;
  salutation: string | undefined | null;
  familyRelationship: string | undefined | null;
};

export const PersonCard = ({
  firstName,
  lastName,
  salutation,
  familyRelationship,
}: PersonCardType) => {
  const isChild = !!familyRelationship && checkIfChild(familyRelationship);

  const translationContent = {
    salutation: i18next.t('basicData.salutation'),
    firstName: i18next.t('basicData.firstName'),
    lastName: i18next.t('basicData.lastName'),
    child: i18next.t('basicData.child'),
  };
  const { DxSalutationListMemoized } = useMemoizedDropdownOptionList();

  return (
    <DxGrid base="page" mq4="6-6/6-6/6-6/6/*" mq1="12/*">
      <DxDropdown
        size="m"
        required
        label={translationContent.salutation}
        readonly
        value={salutation as string}
      >
        {DxSalutationListMemoized}
      </DxDropdown>
      <DxCheckbox
        className="createPerson-familyRelationship family-relationship-box"
        data-testid="pd_child"
        id="pd_child"
        label={translationContent.child}
        checked={isChild}
        disabled
      />
      <DxTextInput
        data-testid="pd_firstName"
        id="pd_firstName"
        size="m"
        required
        label={translationContent.firstName}
        readonly
        value={firstName}
      />
      <DxTextInput
        data-testid="pd_lastName"
        id="pd_lastName"
        size="m"
        required
        label={translationContent.lastName}
        readonly
        value={lastName}
      />
    </DxGrid>
  );
};
