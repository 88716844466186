import { DxContainer } from '@dvag/design-system-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { useNotification } from 'utils/notification/NotificationContext';
import { checkPermission } from 'utils/util';
import { useValidateUrlParams } from 'utils/useValidateUrlParams';
import { Header } from '../Header';
import { navigateToBankAnalysis } from '../flcNavigation';
import { useGetPersonListForBankAnalysis } from './useGetPersonListForBankAnalysis';
import { useGetPersonForBankAnalysis } from './useGetPersonForBankAnalysis';
import { FlcIframe } from './FlcIframe';
import { flcPermission } from '../flcConstants';

export const BankAnalysis = () => {
  checkPermission(flcPermission);
  useValidateUrlParams();
  const params = useParams();
  const householdId = params.householdId || '';
  const [personId, setPersonId] = useState(params.personId || '');
  const notification = useNotification();
  const getPersonList = useGetPersonListForBankAnalysis(householdId);
  const getPerson = useGetPersonForBankAnalysis(personId);
  const finleapConsent = getPerson.data?.finleapConsent;

  useEffect(() => {
    if (householdId && personId) {
      navigateToBankAnalysis(householdId, personId);
    }
  }, [householdId, personId]);

  const isPersonListFetched = () =>
    getPersonList.isFetched && getPersonList.data.find(({ id }) => id === personId);

  const isPersonFetched = () => getPerson.isFetched && getPerson.data;

  const renderContent = () => {
    if (!personId || !isPersonListFetched() || !isPersonFetched()) {
      return null;
    }

    if (!finleapConsent?.link) {
      notification.showError({
        title: i18next.t('general.toasters.loading.generic.title'),
      });
      return null;
    }

    return <FlcIframe link={finleapConsent.link} />;
  };

  return (
    <DxContainer color="transparent" type="page">
      <Header
        selectedPersonId={personId}
        personList={getPersonList.data}
        onPersonIdChange={setPersonId}
      />
      {renderContent()}
    </DxContainer>
  );
};
