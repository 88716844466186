import React from 'react';
import { DxDropdown, DxDropdownOption } from '@dvag/design-system-react';
import { getPersonFullName } from 'utils/util';
import { PersonInHouseholdDto } from 'graphql/generates';

type PersonDropdownProps = {
  selectedId: string;
  list: Pick<PersonInHouseholdDto, 'id' | 'firstName' | 'lastName'>[];
  onChange: (id: string) => void;
};

export const PersonDropdown = ({ selectedId, list, onChange }: PersonDropdownProps) => (
  <DxDropdown
    size="m"
    id="bc-person-dropdown"
    data-testid="bc-person-dropdown"
    value={selectedId}
    onValueChange={(e) => {
      onChange(e.target.value as string);
    }}
  >
    {list.map((person, index) => (
      <DxDropdownOption
        id={`bc-person-dropdown-option-${index}`}
        data-testid={`bc-person-dropdown-option-${index}`}
        value={person.id}
        label={getPersonFullName(person)}
        key={person.id}
      />
    ))}
  </DxDropdown>
);
