import { useGetSaverProfileDataQuery } from 'graphql/generates';
import { getGraphQLClient } from 'graphql/graphQLClient';
import { SavingProfileFormValues } from 'type/savingType';
import { defaultSavingTypeValues } from 'screen/SavingType/defaultSavingTypeValues';

export const useGetSaverProfile = (householdId: string) =>
  useGetSaverProfileDataQuery(
    getGraphQLClient(),
    { householdId },
    {
      select: (response) =>
        (response?.household?.saverProfile as SavingProfileFormValues) || defaultSavingTypeValues,
    },
  );
