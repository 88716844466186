import { useEffect, useMemo } from 'react';

import i18next from 'i18next';

import {
  DxCard,
  DxCardContent,
  DxDropdown,
  DxGrid,
  DxNumberInput,
  DxTextInput,
} from '@dvag/design-system-react';

import * as yup from 'yup';

import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import { useCreateTaxData } from 'hooks/useCreateTaxData';
import { useMemoizedDropdownOptionList } from 'hooks/useMemoizedDropdownOptionList';
import { useUpdateTaxData } from 'hooks/useUpdateTaxData';
import { TaxData } from 'type/taxData';
import { FormType, FormTypes } from 'utils/fieldList';
import { ContinutationRequest } from 'utils/useContinuation';

import {
  childAllowanceFieldName,
  churchTaxFieldName,
  incomeSocialInsurancePreviousYearFieldName,
  taxableIncomePreviousYearFieldName,
  taxAdvisorFieldName,
  taxNumberFieldName,
  taxReturnTypeFieldName,
} from '../util';
import { validateMaxIncomeNumber, validateMinIncomeNumber, validateTaxNumber } from '../validation';
import { defaultTaxData } from './defaultTaxData';

import './style.css';

interface TaxDataFormProps {
  display: string;
  personId: string | undefined;
  onFormDirtyStatusChanged: (formType: FormTypes, isDirty: boolean) => void;
  onFormValidStatusChanged: (formType: FormTypes, isValid: boolean) => void;
  onFormSubmittingStatusChanged: (formType: FormTypes, isSubmitting: boolean) => void;
  onFormSaved: (formType: FormTypes) => void;
  getCanContinueHandler: () => (canContinueCond: boolean) => void;
  onContinuationRequest: (continuationRequestCallback: ContinutationRequest) => void;
  taxData: TaxData;
}

const TAX_NUMBER_LIMIT_LENGTH = 13;

const translation = {
  inputDefaultPlaceholder: i18next.t('general.inputDefaultPlaceholder'),
  selectDefaultPlaceholder: i18next.t('general.selectDefaultPlaceholder'),
  taxableIncomeFromPreviousYear: i18next.t('taxDataForm.taxableIncomeFromPreviousYear'),
  incomeFromSocialInsurancePreviousYear: i18next.t(
    'taxDataForm.incomeFromSocialInsurancePreviousYear',
  ),
  taxNo: i18next.t('taxDataForm.taxNo'),
  taxAssessment: i18next.t('taxDataForm.taxAssessment'),
  childAllowance: i18next.t('taxDataForm.childAllowance'),
  churchTax: i18next.t('taxDataForm.churchTax'),
  taxAdvisor: i18next.t('taxDataForm.taxAdvisor'),
};

export const TaxDataForm = ({
  display,
  personId,
  onFormSaved,
  onFormDirtyStatusChanged,
  onFormValidStatusChanged,
  onFormSubmittingStatusChanged,
  getCanContinueHandler,
  onContinuationRequest,
  taxData,
}: TaxDataFormProps) => {
  const { taxReturnMemoizedType, childAllowanceMemoizedList, churchTaxOptionMemoizedList } =
    useMemoizedDropdownOptionList();

  const createTaxData = useCreateTaxData({ personId });
  const updateTaxData = useUpdateTaxData({ personId });

  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);

  const resourceSchema = useMemo(
    () =>
      yup.object().shape({
        taxableIncomePreviousYear: yup
          .number()
          .test('validateMinTaxableIncome', i18next.t('general.fieldMustBePositive'), (x) =>
            validateMinIncomeNumber(x),
          )
          .test('validateMaxTaxableIncome', i18next.t('general.maxIncomeError'), (x) =>
            validateMaxIncomeNumber(x),
          )
          .nullable(),
        incomeSocialInsurancePreviousYear: yup
          .number()
          .test('validateMinTaxableIncome', i18next.t('general.fieldMustBePositive'), (x) =>
            validateMinIncomeNumber(x),
          )
          .test('validateMaxTaxableIncome', i18next.t('general.maxIncomeError'), (x) =>
            validateMaxIncomeNumber(x),
          )
          .nullable(),
        taxNumber: yup
          .string()
          .test('validateTaxNumber', i18next.t('general.taxNumber'), (x) => validateTaxNumber(x))
          .nullable(),
      }),
    [],
  );

  const { register, flushChanges } = useAutosave<TaxData>({
    data: taxData,
    defaultData: defaultTaxData,
    createResource: createTaxData.mutateAsync,
    updateResource: updateTaxData.mutateAsync,
    onIsValidChange: (isValidParam) => onFormValidStatusChanged(FormType.tax, isValidParam),
    onSubmitSuccessfulChange: (isSubmitSuccessfulParam) => {
      if (isSubmitSuccessfulParam) onFormSaved(FormType.tax);
    },
    onIsDirtyChange: (isDirtyParam) => {
      onFormDirtyStatusChanged(FormType.tax, isDirtyParam);
      onCanContinueChange(!isDirtyParam);
    },
    onIsSubmittingChange: (isSubmittingParam) => {
      onFormSubmittingStatusChanged(FormType.tax, isSubmittingParam);
    },
    resourceSchema,
  });

  useEffect(() => {
    onContinuationRequest(flushChanges);
  }, [flushChanges, onContinuationRequest]);

  return (
    <div className="pd_tax-container" style={{ display, width: 'inherit' }}>
      <DxCard className="pd_tax-card">
        <DxCardContent className="pd_tax-content">
          <form style={{ width: '100%' }}>
            <DxGrid base="page" mq3="12/12/6-6/6-6/6-(6)" mq1="12/*">
              <DxNumberInput
                size="m"
                data-testid={`pd_${taxableIncomePreviousYearFieldName}`}
                id={`pd_${taxableIncomePreviousYearFieldName}`}
                label={translation.taxableIncomeFromPreviousYear}
                profile="currency-euro"
                precision={2}
                placeholder={translation.inputDefaultPlaceholder}
                {...register(taxableIncomePreviousYearFieldName)}
              />
              <DxNumberInput
                size="m"
                data-testid={`pd_${incomeSocialInsurancePreviousYearFieldName}`}
                id={`pd_${incomeSocialInsurancePreviousYearFieldName}`}
                profile="currency-euro"
                precision={2}
                label={translation.incomeFromSocialInsurancePreviousYear}
                placeholder={translation.inputDefaultPlaceholder}
                {...register(incomeSocialInsurancePreviousYearFieldName)}
              />
              <DxTextInput
                nominmaxlabel
                size="m"
                maxlength={TAX_NUMBER_LIMIT_LENGTH}
                label={translation.taxNo}
                id={`pd_${taxNumberFieldName}`}
                data-testid={`pd_${taxNumberFieldName}`}
                placeholder={translation.inputDefaultPlaceholder}
                {...register(taxNumberFieldName)}
              />
              <DxDropdown
                size="m"
                label={translation.taxAssessment}
                id={`pd_${taxReturnTypeFieldName}`}
                data-testid={`pd_${taxReturnTypeFieldName}`}
                placeholder={translation.selectDefaultPlaceholder}
                {...register(taxReturnTypeFieldName)}
              >
                {taxReturnMemoizedType}
              </DxDropdown>
              <DxDropdown
                size="m"
                label={translation.childAllowance}
                id={`pd_${childAllowanceFieldName}`}
                data-testid={`pd_${childAllowanceFieldName}`}
                placeholder={translation.selectDefaultPlaceholder}
                {...register(childAllowanceFieldName)}
              >
                {childAllowanceMemoizedList}
              </DxDropdown>
              <DxDropdown
                size="m"
                label={translation.churchTax}
                id={`pd_${churchTaxFieldName}`}
                data-testid={`pd_${churchTaxFieldName}`}
                placeholder={translation.selectDefaultPlaceholder}
                {...register(churchTaxFieldName)}
              >
                {churchTaxOptionMemoizedList}
              </DxDropdown>
              <DxTextInput
                nominmaxlabel
                size="m"
                label={translation.taxAdvisor}
                id={`pd_${taxAdvisorFieldName}`}
                data-testid={`pd_${taxAdvisorFieldName}`}
                placeholder={translation.inputDefaultPlaceholder}
                maxlength={50}
                {...register(taxAdvisorFieldName)}
              />
            </DxGrid>
          </form>
        </DxCardContent>
      </DxCard>
    </div>
  );
};
