import { DeepMap, FieldError, FieldValues } from 'react-hook-form';

import { Status } from 'utils/status';
import {
  TEmailFormFieldError,
  TFaxFormFieldError,
  TTelephoneFormFieldError,
} from '../type/contact';
import { TAddressFieldError } from '../type/address';

type TFormFieldErrorAddressEmail = TAddressFieldError | TEmailFormFieldError;
type TFormFieldTelephoneAddressForm = TTelephoneFormFieldError | TFaxFormFieldError;

type FormErrors = TFormFieldErrorAddressEmail | TFormFieldTelephoneAddressForm;

export const isFieldRequired = (
  fieldName: string,
  formErrors: FormErrors | DeepMap<FieldValues, FieldError>,
): boolean => {
  const errorType = formErrors?.[fieldName as keyof typeof formErrors]?.type;
  return errorType && errorType === Status.required;
};
