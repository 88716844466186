import React, { useCallback, useEffect } from 'react';
import i18next from 'i18next';
import { DxCombobox } from '@dvag/design-system-react';
import { removeAccents } from 'utils/util';
import { MIN_SEARCH_TERM_LENGTH } from '../util';
import { mapData } from '../searchInput/ProfessionSearchInput';

const translationContext = {
  emptySearchText: i18next.t('combobox.noSearchResultsFoundForInput'),
  preSearchConditionText: i18next.t('combobox.enterMinCharactersForSearchTerm', {
    minSearchTermLength: MIN_SEARCH_TERM_LENGTH,
  }),
  inputDefaultPlaceholder: i18next.t('general.inputDefaultPlaceholder'),
};

interface ComboboxOption {
  value: string;
  label: string;
}

interface ComboboxSearchProps {
  errormessage?: string;
  onSelectSearchOption: (searchOption: string | undefined) => void;
  isRequired?: boolean;
  label: string;
  id: string;
  isDisabled?: boolean;
  value?: string;
  data: Array<ComboboxOption>;
  filterFn?: (filter: string) => (item: ComboboxOption) => boolean;
  presearchLetterCount?: number;
}

const getLabel = (item: ComboboxOption) => item.label;

export const ComboboxSearch = ({
  errormessage,
  isDisabled,
  onSelectSearchOption,
  isRequired = false,
  label,
  id,
  value,
  data,
  filterFn = (filterBy) => (item) => removeAccents(item.label).includes(removeAccents(filterBy)),
  presearchLetterCount = 0,
}: ComboboxSearchProps) => {
  const onSearch = useCallback(
    (e: Event) => {
      const { detail } = e as CustomEvent;
      if (detail.reason === 'clear') {
        onSelectSearchOption(undefined);
      }
      const comboboxData = detail.value ? data.filter(filterFn(detail.value)) : data;
      const providedValues = comboboxData?.length
        ? [
            {
              label: undefined,
              options: comboboxData.map((p) => mapData(p, getLabel)),
            },
          ]
        : [];
      detail.provideValues(providedValues);
    },
    [data, onSelectSearchOption, filterFn],
  );

  useEffect(() => {
    const combobox = document.querySelector(`#${id}`) as HTMLDxComboboxElement;
    combobox?.addEventListener('search', onSearch);

    return () => combobox?.removeEventListener('search', onSearch);
  }, [id, onSearch, data, value]);

  return (
    <DxCombobox
      errormessage={errormessage}
      size="m"
      value={data.find((item) => item.value === value)?.label || ''}
      disabled={isDisabled}
      onSelectSearchOption={(e) => {
        onSelectSearchOption(e.detail.data.value);
      }}
      onInputChange={(e: CustomEvent) => {
        if (!e.detail || e.detail.reason === 'clear') {
          onSelectSearchOption(undefined);
        }
      }}
      usagemode="new"
      label={label}
      required={isRequired}
      id={id}
      data-testid={id}
      emptysearchtext={translationContext.emptySearchText}
      presearchconditiontext={translationContext.preSearchConditionText}
      placeholder={translationContext.inputDefaultPlaceholder}
      presearchlettercount={presearchLetterCount}
    />
  );
};
