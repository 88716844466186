import React, { useCallback, useEffect, useRef, useState } from 'react';

import { navigateToMicroApp } from '@dvag/dfs-orchestrator-client/navigation';
import { DxCheckbox, DxDropdown, DxGrid, DxTextInput } from '@dvag/design-system-react';

import i18next from 'i18next';

import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import { ResourceRequestResponse } from '@dvag/dfs-ui-blocks/hooks/useAutosaveHelper';
import * as yup from 'yup';
import { useCreatePerson } from 'hooks/useCreatePerson';

import { route } from 'config/route';
import { mandatoryFieldMessage } from 'form/util';
import { useMemoizedDropdownOptionList } from 'hooks/useMemoizedDropdownOptionList';
import { personApi } from 'service/api/personApi';
import { buildPath } from 'service/buildPath';
import { ManagePerson } from 'type/createPerson';
import { FieldType } from 'utils/fieldList';
import { OnCanContinue, useContinuation } from 'utils/useContinuation';
import { defaultCreatePerson } from 'utils/util';
import './style.css';

const resourceSchema = yup.object().shape({
  salutation: yup.string().required(mandatoryFieldMessage),
  firstName: yup.string().required(mandatoryFieldMessage),
  lastName: yup.string().required(mandatoryFieldMessage),
});

const translation = {
  selectPlaceholder: i18next.t('general.selectDefaultPlaceholder'),
  inputPlaceholder: i18next.t('general.inputDefaultPlaceholder'),
  salutation: i18next.t('basicData.salutation'),
  child: i18next.t('basicData.child'),
  firstName: i18next.t('basicData.firstName'),
  lastName: i18next.t('basicData.lastName'),
};

export const PersonForm = ({
  householdId,
  notChild = false,
  setAddPerson,
  onIsSubmittingChange,
  onContinuationRequest,
  onCanContinueChange,
  getContinuationHandler,
}: {
  householdId?: string;
  notChild?: boolean;
  setAddPerson: React.Dispatch<React.SetStateAction<boolean>>;
  onIsSubmittingChange: (boolean: boolean) => void;
  onContinuationRequest: ReturnType<typeof useContinuation>['onContinuationRequest'];
  onCanContinueChange: OnCanContinue;
  continuation: ReturnType<typeof useContinuation>['continuation'];
  getContinuationHandler: ReturnType<typeof useContinuation>['getContinuationHandler'];
}) => {
  const createPerson = useCreatePerson();

  const { DxSalutationListMemoized } = useMemoizedDropdownOptionList();

  const [newHouseholdId, setNewHouseholdId] = useState<string | undefined>(undefined);

  const setLastAccessed = useCallback((id: string) => {
    if (id) {
      personApi().updateLastAccessedHouseholdList(id);
    }
  }, []);

  const onRequestResponse = useCallback(
    async (response: ResourceRequestResponse<ManagePerson>[]) => {
      if (householdId) {
        await setLastAccessed(householdId);
      } else {
        await setLastAccessed(response[0]?.response?.householdList?.[0].id || '');
      }

      if (householdId) {
        getContinuationHandler(() => setAddPerson(false))();
      } else {
        setNewHouseholdId(response[0]?.response?.householdList?.[0].id);
      }
    },
    [getContinuationHandler, householdId, setAddPerson, setLastAccessed],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { flushChanges, register, getValues, setValue, triggerValidation } =
    useAutosave<ManagePerson>({
      data: useRef([]).current,
      defaultData: defaultCreatePerson,
      resourceSchema,
      createResource: createPerson.mutateAsync,
      onIsDirtyChange: (isDirtyParam) => {
        onCanContinueChange(!isDirtyParam);
      },
      onIsSubmittingChange: (isSubmittingParam) => {
        onIsSubmittingChange(isSubmittingParam);
        setIsSubmitting(isSubmittingParam);
      },
      onRequestResponse,
    });

  useEffect(() => {
    if (newHouseholdId) {
      getContinuationHandler(() =>
        navigateToMicroApp(
          'person',
          buildPath(route.manageHousehold, {
            householdId: newHouseholdId,
          }),
        ),
      )();
    }
  }, [getContinuationHandler, householdId, newHouseholdId, setAddPerson, setLastAccessed]);

  useEffect(() => {
    onContinuationRequest(() => {
      triggerValidation().then(flushChanges);
    });
  }, [flushChanges, onContinuationRequest, triggerValidation]);

  return (
    <form>
      <DxGrid base="page" mq4="6-6/6-6/6-6/6/*" mq1="12/*">
        <DxDropdown
          size="m"
          placeholder={translation.selectPlaceholder}
          required
          data-testid="pd_salutation__input"
          id="pd_salutation__input"
          label={translation.salutation}
          disabled={isSubmitting}
          {...register(FieldType.salutation)}
        >
          {DxSalutationListMemoized}
        </DxDropdown>

        <div style={{ background: 'transparent' }}>
          <DxCheckbox
            className="createPerson-familyRelationship createPerson-family-relationship-box"
            data-testid="pd_child"
            id="pd_child"
            style={{
              paddingLeft: '12px',
            }}
            label={translation.child}
            onCheckedChange={(e) => {
              setValue(FieldType.familyRelationship, e.detail);
            }}
            disabled={notChild || isSubmitting}
            checked={!!getValues(FieldType.familyRelationship)}
            {...register(FieldType.familyRelationship)}
          />
        </div>
        <DxTextInput
          nominmaxlabel
          size="m"
          placeholder={translation.inputPlaceholder}
          data-testid="pd_firstName"
          id="pd_firstName"
          required
          label={translation.firstName}
          maxlength={20}
          disabled={isSubmitting}
          {...register(FieldType.firstName)}
        />

        <DxTextInput
          nominmaxlabel
          size="m"
          placeholder={translation.inputPlaceholder}
          data-testid="pd_lastName"
          id="pd_lastName"
          required
          label={translation.lastName}
          maxlength={30}
          disabled={isSubmitting}
          {...register(FieldType.lastName)}
        />
      </DxGrid>
    </form>
  );
};
