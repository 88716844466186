import { DxDropdownOption } from '@dvag/design-system-react';
import React, { useMemo } from 'react';
import {
  academicTitle,
  childAllowanceOptionList,
  churchTaxOptionList,
  maritalState,
  occupationGroupList,
  salutationList,
  smoker,
  taxReturnType,
} from 'utils/util';
import { countryList, stateList } from 'utils/countries';

export const useMemoizedDropdownOptionList = () => {
  const countryListMemoized = useMemo(
    () =>
      countryList.map(({ value, label }) => (
        <DxDropdownOption value={value} label={label} key={`country_${value}_${label}`} />
      )),
    [],
  );

  const stateListMemoized = useMemo(
    () =>
      stateList.map(({ value, label }) => (
        <DxDropdownOption value={value} label={label} key={`state_${value}`} />
      )),
    [],
  );

  const maritalStateMemoized = useMemo(
    () =>
      maritalState.map(({ value, label }) => (
        <DxDropdownOption value={value} label={label} key={`marital_${value}`} />
      )),
    [],
  );

  const academicTitleListMemoized = useMemo(
    () =>
      academicTitle.map(({ value, label }) => (
        <DxDropdownOption value={value} label={label} key={`academic_${value}`} />
      )),
    [],
  );

  const smokerOptionsListMemoized = useMemo(
    () =>
      smoker.map(({ value, label }) => (
        <DxDropdownOption value={value} label={label} key={`smoker_${value}`} />
      )),
    [],
  );

  const salutationListMemoized = useMemo(
    () =>
      salutationList.map(({ value, label }) => (
        <DxDropdownOption value={value} label={label} key={`salutation_${value}`} />
      )),
    [],
  );

  const DxSalutationListMemoized = useMemo(
    () =>
      salutationList.map(({ value, label }) => (
        <DxDropdownOption value={value} label={label} key={value} />
      )),
    [],
  );

  const taxReturnMemoizedType = useMemo(
    () =>
      taxReturnType.map(({ value, label }) => (
        <DxDropdownOption value={value} label={label} key={`tax_${value}`} />
      )),
    [],
  );

  const churchTaxOptionMemoizedList = useMemo(
    () =>
      churchTaxOptionList.map(({ value, label }) => (
        <DxDropdownOption value={value} label={label} key={`church_${value}`} />
      )),
    [],
  );

  const childAllowanceMemoizedList = useMemo(
    () =>
      childAllowanceOptionList.map(({ value, label }) => (
        <DxDropdownOption value={value} label={label} key={`child_${value}`} />
      )),
    [],
  );

  const occupationGroupMemoizedList = useMemo(
    () =>
      occupationGroupList.map(({ value, label }) => (
        <DxDropdownOption value={value} label={label} key={`occuaption_${value}`} />
      )),
    [],
  );

  return {
    countryListMemoized,
    stateListMemoized,
    taxReturnMemoizedType,
    churchTaxOptionMemoizedList,
    childAllowanceMemoizedList,
    maritalStateMemoized,
    academicTitleListMemoized,
    smokerOptionsListMemoized,
    salutationListMemoized,
    DxSalutationListMemoized,
    occupationGroupMemoizedList,
  };
};
