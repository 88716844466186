import { FamilyRelationship } from 'graphql/generates';
import { PersonHousehold } from './household';

export const PersonFamilyRelationship = {
  Adult: FamilyRelationship.Erwachsen,
  Child: FamilyRelationship.Kind,
};

export interface Person {
  id: string;
  firstName: string;
  lastName: string;
  salutation: string;
  familyRelationship: string;
  order: number;
  birthDate: string;
  bogusBirthDate: boolean;
  household: PersonHousehold[];
  allFirstNames: string;
  birthName: string;
  smoker: boolean;
  noOfChildren: number;
  placeOfBirth: string;
  successfulSync: boolean;
  lastSuccessfulSync: string;
  academicTitle: string;
  maritalState: string;
  consentStatus: string;
  countryOfBirth: string;
  nationality: string;
}

export type ListItem = {
  value: string;
  label: string;
};

export type ListItemGraph = {
  value?: string | null;
  label?: string | null;
};

export interface PersonFullName {
  firstName?: string;
  lastName?: string;
}

export type CommunicationChannelSectionDataType = {
  context?: string;
  id?: string;
  householdId?: string;
  type?: string;
  value: string;
  prefix?: string;
  index?: number;
  personId?: string;
};
