import type { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';
import { useMutation } from '@tanstack/react-query';
import { personApi } from 'service/api/personApi';
import { SavingProfileFormValues } from 'type/savingType';
import { useNotification } from 'utils/notification/NotificationContext';

export const useCreateOrUpdateSaverProfile = (householdId?: string) => {
  const { showError } = useNotification();
  const personService = personApi();
  return useMutation<SavingProfileFormValues, ErrorRequestEntity, SavingProfileFormValues>({
    mutationFn: async ({ id, ...data }: SavingProfileFormValues) => {
      const rawPayload = { ...data };
      delete rawPayload.updatedAt;
      delete rawPayload.createdAt;
      const rawPayloadAsEntries = Object.entries(rawPayload);

      const payloadAsEntries = rawPayloadAsEntries.map(([key, value]) => {
        if (key === 'saverProfileCurrent' || key === 'saverProfileDesired') {
          return [key, value || 0];
        }
        return [key, value || false];
      });

      const payload = Object.fromEntries(payloadAsEntries);

      const response = id
        ? await personService.updateSaverProfile(householdId, payload)
        : await personService.createSaverProfile(payload, householdId);

      return response.data;
    },
    onError: (e) => {
      showError({ status: e?.response?.status });
    },
  });
};
