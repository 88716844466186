import { checkForData } from 'form/util';
import { ITelephoneForm, TFormValueList } from 'type/contact';

export const SECOND_SECTION_INDEX = 1;

const isValidId = (id: string | undefined) => id !== undefined && id !== '';

export const isDeleteDisabled = (
  index: number,
  formDataList: TFormValueList,
  keyList: string[],
  isSaving: boolean,
  sectionIndex?: number | undefined,
): boolean => {
  if (isValidId(formDataList[index].id)) {
    if (isSaving) return true;
    if (
      formDataList[index].type === 'MOBIL_PRIVAT' &&
      (formDataList as Array<ITelephoneForm>).filter(
        (p) => p.type === 'MOBIL_PRIVAT' && isValidId(p.id),
      ).length === 1
    ) {
      return true;
    }
    if (
      formDataList[index].type === 'E_MAIL_PRIVAT' &&
      (formDataList as Array<ITelephoneForm>).filter(
        (p) => p.type === 'E_MAIL_PRIVAT' && isValidId(p.id),
      ).length === 1
    ) {
      return true;
    }
    if (
      formDataList[index].type === 'FAX_PRIVAT' &&
      (formDataList as Array<ITelephoneForm>).filter(
        (p) => p.type === 'FAX_PRIVAT' && isValidId(p.id),
      ).length === 1
    ) {
      return true;
    }
  }

  if (formDataList.length === 1) {
    return true;
  }

  const currentSection = formDataList[sectionIndex ?? index];
  const isCurrentSectionCompleted = checkForData(currentSection, keyList);
  if (index === 0 && !isCurrentSectionCompleted) {
    return true;
  }
  return false;
};
