import { useMemo } from 'react';

import {
  checkForEmailType,
  checkForFaxByType,
  checkForTelephoneByType,
  defaultPrefix,
} from 'form/util';
import { CommunicationChannelDto } from 'graphql/generates';
import { emailType, faxType, IContactFormValues, IEmailForm, phoneType } from 'type/contact';
import { contextPerson } from 'utils/util';

export const useHandleDataBeforeRender = (data: CommunicationChannelDto[]): IContactFormValues => {
  const emailList = useMemo(
    () =>
      !!data &&
      data
        ?.filter((el) => checkForEmailType(el.type as emailType) && el.context === contextPerson)
        ?.map((el) => ({
          type: el.type,
          value: el.value,
          id: el.id,
        })),
    [data],
  );

  const telephoneList = useMemo(
    () =>
      !!data &&
      data
        ?.filter(
          (el) => checkForTelephoneByType(el.type as phoneType) && el.context === contextPerson,
        )
        ?.map((el) => ({
          type: el.type,
          prefix: el.prefix ?? defaultPrefix,
          value: el.value,
          id: el.id,
        })),
    [data],
  );

  const faxList = useMemo(
    () =>
      !!data &&
      data
        ?.filter((el) => checkForFaxByType(el.type as faxType) && el.context === contextPerson)
        ?.map((el) => ({
          type: el.type,
          prefix: el.prefix ?? defaultPrefix,
          value: el.value,
          id: el.id,
        })),
    [data],
  );

  return useMemo(
    () => ({
      communicationChannelTelephoneData: telephoneList,
      communicationChannelFaxData: faxList,
      communicationChannelEmailData: emailList as IEmailForm[],
    }),
    [telephoneList, faxList, emailList],
  );
};
