import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DxButton, DxContainer, DxGrid, DxProcessHeader } from '@dvag/design-system-react';
import { appNames } from '@dvag/dfs-constant-config/app-names';
import { sharedAppRoutes } from '@dvag/dfs-constant-config/shared-app-names';

import i18next from 'i18next';

import { usePersonListManageHousehold } from 'hooks/usePersonListManageHousehold';
import { handleNavigateToPath } from 'service/handleNavigateToPath';

import { UnsavedOrPendingModal } from 'component/UnsavedOrPendingModal';
import { useContinuation } from 'utils/useContinuation';
import { checkIcon } from 'utils/util';

import { CardContainer } from './component/CardContainer';
import { PersonCard } from './component/PersonCard';
import { PersonForm } from './component/PersonForm';

import './style.css';

const translation = {
  title: i18next.t('manageHousehold.title'),
  headerButtonLabel: i18next.t('manageHousehold.headerButtonLabel'),
  backToDashboard: i18next.t('manageHousehold.backToDashboard'),
};

export const ManageHousehold = () => {
  const { householdId } = useParams();
  const [addPerson, setAddPerson] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const personList = usePersonListManageHousehold(householdId || '');

  const { continuation, getContinuationHandler, onContinuationRequest, getCanContinueHandler } =
    useContinuation();

  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);

  const showPersonForm = () => {
    setAddPerson(true);
  };

  const navigateToDashboard = () => {
    const path = householdId
      ? sharedAppRoutes.advisor.householdDashboard
      : sharedAppRoutes.advisor.householdSearch;
    handleNavigateToPath(path, householdId ?? '', appNames.advisor);
  };

  return (
    <>
      <DxProcessHeader headline={translation.title} />
      <DxContainer type="page" color="transparent" style={{ paddingBottom: '30px' }}>
        <DxGrid mq1="12/*">
          {householdId && (
            <DxContainer color="transparent" className="create-person-container">
              <DxButton
                id="create-person"
                data-testid="create-person"
                icon={checkIcon('plus-kreis')}
                iconposition="left"
                disabled={!householdId}
                onClick={showPersonForm}
                label={translation.headerButtonLabel}
                type="text"
              />
            </DxContainer>
          )}
          <DxContainer color="transparent">
            {!householdId && (
              <CardContainer data-testid="card-container" index={0}>
                <PersonForm
                  data-testid="person-form"
                  onIsSubmittingChange={setIsSubmitting}
                  notChild
                  setAddPerson={setAddPerson}
                  onContinuationRequest={onContinuationRequest}
                  onCanContinueChange={onCanContinueChange}
                  continuation={continuation}
                  getContinuationHandler={getContinuationHandler}
                />
              </CardContainer>
            )}
            {householdId && (
              <>
                {!!personList.data.length && (
                  <>
                    {personList.data.map(
                      ({ firstName, lastName, salutation, familyRelationship, id }, index) => (
                        <CardContainer
                          firstName={firstName}
                          lastName={lastName}
                          index={index}
                          key={id}
                          id={id}
                          handleContinuation={getContinuationHandler}
                        >
                          <PersonCard
                            firstName={firstName}
                            lastName={lastName}
                            familyRelationship={familyRelationship}
                            salutation={salutation}
                          />
                        </CardContainer>
                      ),
                    )}
                  </>
                )}
                {addPerson && (
                  <CardContainer index={personList.data.length}>
                    <PersonForm
                      onIsSubmittingChange={setIsSubmitting}
                      householdId={householdId}
                      setAddPerson={setAddPerson}
                      onContinuationRequest={onContinuationRequest}
                      onCanContinueChange={onCanContinueChange}
                      continuation={continuation}
                      getContinuationHandler={getContinuationHandler}
                    />
                  </CardContainer>
                )}
              </>
            )}
          </DxContainer>

          <DxContainer color="transparent">
            <DxButton
              id="back-to-dashboard"
              data-testid="back-to-dashboard"
              label={translation.backToDashboard}
              type="text"
              icon={checkIcon('pfeil-links')}
              iconposition="left"
              className="flex align-center fit-content dashboard-button"
              onClick={() => getContinuationHandler(navigateToDashboard)()}
            />
          </DxContainer>
        </DxGrid>
        <UnsavedOrPendingModal
          showPending={continuation !== undefined && isSubmitting}
          showUnsaved={continuation !== undefined && !isSubmitting}
          onCancel={continuation?.onCancel}
          onConfirm={continuation?.onConfirm}
        />
      </DxContainer>
    </>
  );
};
