import {
  CountryCode,
  useGetPersonalDataQuery,
  CommunicationChannelDto,
  GetPersonalDataQuery,
} from 'graphql/generates';
import { getGraphQLClient } from 'graphql/graphQLClient';

import { Address } from 'type/address';
import { IBankDetails } from 'type/bankAccount';
import { ProfessionFormData } from 'type/job';
import { TaxData } from 'type/taxData';

const transformValues = (response: GetPersonalDataQuery) => {
  const responseData = response.person;

  return {
    addressData: responseData.address?.map((address) => ({
      ...address,
      country: address.country || CountryCode.D,
    })) as Array<Address>,
    communicationChannelData: responseData.communicationChannel as Array<CommunicationChannelDto>,
    professionData: {
      ...responseData.profession,
      personId: responseData.profession ? responseData.id : undefined,
    } as ProfessionFormData,
    taxData: responseData.tax as TaxData,
    bankAccountData: responseData.bankAccount as unknown as Array<IBankDetails>,
  };
};

export const useGetPersonalData = (
  personId: string | undefined,
  householdId: string | undefined,
) => {
  const query = useGetPersonalDataQuery(
    getGraphQLClient(),
    { personId: personId as string, householdId },
    {
      enabled: !!personId && !!householdId,
      select: (response) => transformValues(response),
    },
  );
  return { ...query, data: query.data };
};
