import React from 'react';
import { DxContainer, DxSpacer, DxWizard, DxWizardStep } from '@dvag/design-system-react';
import i18next from 'i18next';
import { ConsentStatus } from 'graphql/generates';
import { FinleapConsentStatus } from 'type/consent';
import './ConsentStatusWizard.css';

type ConsentStatusWizardProps = {
  currentStatus: ConsentStatus | undefined;
};

const stepMap: Record<string, number> = {
  [FinleapConsentStatus.Missing]: 0,
  [FinleapConsentStatus.Sent]: 1,
  [FinleapConsentStatus.Created]: 2,
  [FinleapConsentStatus.Granted]: 3,
};

const stepLabelMap: Record<string, string> = {
  [FinleapConsentStatus.Sent]: i18next.t('bankSelectionPage.analysisStatuses.emailHasBeenSent'),
  [FinleapConsentStatus.Created]: i18next.t(
    'bankSelectionPage.analysisStatuses.accountHasBeenCreated',
  ),
  [FinleapConsentStatus.Granted]: i18next.t(
    'bankSelectionPage.analysisStatuses.analysisIsAvailable',
  ),
};

export const ConsentStatusWizard = ({ currentStatus }: ConsentStatusWizardProps) => {
  const currentStep = stepMap[currentStatus || FinleapConsentStatus.Missing];
  const lastStep = stepMap[FinleapConsentStatus.Granted];
  const nextStep = currentStep + 1 <= lastStep ? currentStep + 1 : currentStep;

  return (
    <DxContainer color="transparent" className="bc-consent-status-wizard-container">
      <DxSpacer size="24" />
      <DxWizard
        id="bc-analysis-status-wizard"
        data-testid="bc-analysis-status-wizard"
        headline="Analysis Status"
        currentstep={nextStep.toString()}
      >
        {Object.entries(stepMap).map(([status, step]) => {
          if (status === FinleapConsentStatus.Missing) return null;
          return (
            <DxWizardStep
              key={status}
              id={`bc-analysis-status-step-${step}`}
              data-testid={`bc-analysis-status-step-${step}`}
              label={stepLabelMap[status]}
              value={step.toString()}
              finished={step <= currentStep}
            />
          );
        })}
      </DxWizard>
      <div className="bc-consent-status-wizard-interaction-blocker" />
    </DxContainer>
  );
};
