import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { QueryClientProvider } from '@tanstack/react-query';
import { FC } from 'react';

import { Router } from 'Router';

import { queryClient } from 'config/reactQuery';
import { NotificationProvider } from 'utils/notification/NotificationProvider';
import { getAppInsights } from './service/getAppInsights';
import './style.css';

export const App: FC = () => (
  <AppInsightsContext.Provider value={getAppInsights().reactPlugin}>
    <QueryClientProvider client={queryClient}>
      <NotificationProvider>
        <Router />
      </NotificationProvider>
    </QueryClientProvider>
  </AppInsightsContext.Provider>
);
