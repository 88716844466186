import { navigateToMicroApp } from '@dvag/dfs-orchestrator-client/navigation';
import { buildPath } from '../service/buildPath';
import { route } from '../config/route';

export interface FormTypeInterface {
  basicData: 'basicData';
  address: 'address';
  contact: 'contact';
  profession: 'profession';
  tax: 'tax';
  bankDetails: 'bankDetails';
}

export const FormType: FormTypeInterface = {
  basicData: 'basicData',
  address: 'address',
  contact: 'contact',
  profession: 'profession',
  tax: 'tax',
  bankDetails: 'bankDetails',
} as const;

export type TriggerValidationValue = () => Promise<boolean[]>;

export type FormTypes = keyof typeof FormType;
export interface FieldTypeInterface {
  birthDate: 'birthDate';
  email: 'email';
  occupationGroup: 'occupationGroup';
  professionLabel: 'label';
  professionId: 'professionId';
  professionName: 'name';
  employmentStartDate: 'employmentStartDate';
  employerName: 'employer.name';
  employerAddressStreet: 'employer.address.street';
  employerAddressZipCode: 'employer.address.zipCode';
  employerAddressCity: 'employer.address.city';
  employerAddressState: 'employer.address.state';
  salutation: 'salutation';
  familyRelationship: 'familyRelationship';
  firstName: 'firstName';
  lastName: 'lastName';
  academicTitle: 'academicTitle';
  allFirstNames: 'allFirstNames';
  birthName: 'birthName';
  placeOfBirth: 'placeOfBirth';
  countryOfBirth: 'countryOfBirth';
  nationality: 'nationality';
  maritalState: 'maritalState';
  noOfChildren: 'noOfChildren';
  smoker: 'smoker';
  isVerified: 'isVerified';
  country: 'country';
  state: 'state';
  street: 'street';
  zipCode: 'zipCode';
  city: 'city';
  isPostalAddress: 'isPostalAddress';
}
export const FieldType: FieldTypeInterface = {
  birthDate: 'birthDate',
  email: 'email',
  occupationGroup: 'occupationGroup',
  professionLabel: 'label',
  professionId: 'professionId',
  professionName: 'name',
  employmentStartDate: 'employmentStartDate',
  employerName: 'employer.name',
  employerAddressStreet: 'employer.address.street',
  employerAddressZipCode: 'employer.address.zipCode',
  employerAddressCity: 'employer.address.city',
  employerAddressState: 'employer.address.state',
  salutation: 'salutation',
  familyRelationship: 'familyRelationship',
  firstName: 'firstName',
  lastName: 'lastName',
  academicTitle: 'academicTitle',
  allFirstNames: 'allFirstNames',
  birthName: 'birthName',
  placeOfBirth: 'placeOfBirth',
  countryOfBirth: 'countryOfBirth',
  nationality: 'nationality',
  maritalState: 'maritalState',
  noOfChildren: 'noOfChildren',
  smoker: 'smoker',
  isVerified: 'isVerified',
  country: 'country',
  state: 'state',
  street: 'street',
  zipCode: 'zipCode',
  city: 'city',
  isPostalAddress: 'isPostalAddress',
} as const;

export type VisitedForms = { [key: string]: boolean };

export type SecondaryFormType = 'email' | 'telephone' | 'fax';

export interface TriggerValidationForms {
  basicData?: () => Promise<boolean[]>;
  address?: () => Promise<boolean[]>;
  contact?: {
    telephone?: () => Promise<boolean[]>;
    email?: () => Promise<boolean[]>;
    fax?: () => Promise<boolean[]>;
  };
  tax?: () => Promise<boolean[]>;
  bankDetails?: () => Promise<boolean[]>;
  profession?: () => Promise<boolean[]>;
}

interface FormMap {
  stammdaten: 'basicData';
  kontakt: 'contact';
  adresse: 'address';
  bankverbindung: 'bankDetails';
  forderdaten: 'tax';
  beruf: 'profession';
}

const formMap = {
  stammdaten: FormType.basicData,
  adresse: FormType.address,
  kontakt: FormType.contact,
  beruf: FormType.profession,
  forderdaten: FormType.tax,
  bankverbindung: FormType.bankDetails,
};

const formParamMap = {
  [FormType.basicData]: 'stammdaten',
  [FormType.address]: 'adresse',
  [FormType.contact]: 'kontakt',
  [FormType.profession]: 'beruf',
  [FormType.tax]: 'forderdaten',
  [FormType.bankDetails]: 'bankverbindung',
};

export const getForm = (param: string | undefined): FormTypes => {
  if (param) return formMap[param as keyof FormMap] || 'basicData';
  return 'basicData';
};

export const navigateToForm = (
  form: FormTypes,
  householdId: string | undefined,
  personId: string,
) => {
  const activeForm = formParamMap[form] || 'stammdaten';
  navigateToMicroApp(
    'person',
    buildPath(route.personWithActiveForm, {
      householdId: householdId as string,
      personId,
      activeForm,
    }),
    true,
  );
};

export const getVisitedFormsDefaultValues = (selectedForm: string | undefined) => ({
  basicData: getForm(selectedForm) === 'basicData',
  address: getForm(selectedForm) === 'address',
  contact: getForm(selectedForm) === 'contact',
  profession: getForm(selectedForm) === 'profession',
  tax: getForm(selectedForm) === 'tax',
  bankDetails: getForm(selectedForm) === 'bankDetails',
});
