import { navigateToMicroApp } from '@dvag/dfs-orchestrator-client/navigation';
import { appNames } from '@dvag/dfs-constant-config/app-names';
import { sharedAppRoutes } from '@dvag/dfs-constant-config/shared-app-names';
import { buildPath } from 'service/buildPath';
import { route } from 'config/route';

export const navigateToBankSelection = (householdId: string, personId: string) => {
  const path = buildPath(route.bankSelection, { householdId, personId });
  navigateToMicroApp('person', path);
};

export const navigateToDashboard = (householdId: string) => {
  const path = buildPath(sharedAppRoutes.advisor.householdDashboard, { householdId });
  navigateToMicroApp(appNames.advisor, path);
};

export const navigateToBankAnalysis = (householdId: string, personId: string) => {
  const path = buildPath(route.bankAnalysis, { householdId, personId });
  navigateToMicroApp('person', path);
};
