import i18next from 'i18next';

export const checkboxLabelMap: Record<string, string> = {
  hasAsset: i18next.t('savingType.checkBoxes.hasAsset'),
  hasConsumerLoan: i18next.t('savingType.checkBoxes.hasConsumerLoan'),
  hasConsumption: i18next.t('savingType.checkBoxes.hasConsumption'),
  hasDebt: i18next.t('savingType.checkBoxes.hasDebt'),
  hasDiscipline: i18next.t('savingType.checkBoxes.hasDiscipline'),
  hasDuePaymentNotice: i18next.t('savingType.checkBoxes.hasDuePaymentNotice'),
  hasOverdraft: i18next.t('savingType.checkBoxes.hasOverdraft'),
  hasPlusMinusZero: i18next.t('savingType.checkBoxes.hasPlusMinusZero'),
  hasRevenueInterest: i18next.t('savingType.checkBoxes.hasRevenueInterest'),
  hasSavingBook: i18next.t('savingType.checkBoxes.hasSavingBook'),
  hasStrategy: i18next.t('savingType.checkBoxes.hasStrategy'),
  hasTwoAccountModel: i18next.t('savingType.checkBoxes.hasTwoAccountModel'),
};
