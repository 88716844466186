import { DxContainer } from '@dvag/design-system-react';
import { useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import { CommunicationChannelType } from 'graphql/generates';
import { useNotification } from 'utils/notification/NotificationContext';
import { useContinuation } from 'utils/useContinuation';
import { isFinleapConsentGranted } from 'utils/ConsentUtil';
import { UnsavedOrPendingModal } from 'component/UnsavedOrPendingModal';
import { checkPermission } from 'utils/util';
import { useValidateUrlParams } from 'utils/useValidateUrlParams';
import { useGetPersonListForBankSelection } from './useGetPersonListForBankSelection';
import { useGetPersonForBankSelection } from './useGetPersonForBankSelection';
import { useSendBankConnectionEmail } from './useSendBankConnectionEmail';
import { Header } from '../Header';
import { ProcessDescriptionCard } from './ProcessDescriptionCard/ProcessDescriptionCard';
import { ConsentStatusWizard } from './ConsentStatusWizard';
import { WelcomeCard } from './WelcomeCard';
import { Footer } from './Footer';
import {
  navigateToBankAnalysis,
  navigateToBankSelection,
  navigateToDashboard,
} from '../flcNavigation';
import { flcPermission } from '../flcConstants';
import { getAppInsights } from '../../../service/getAppInsights';

export const BankSelection = () => {
  checkPermission(flcPermission);
  useValidateUrlParams();
  const params = useParams();
  const householdId = params.householdId || '';
  const [personId, setPersonId] = useState(params.personId || '');
  const notification = useNotification();
  const getPersonList = useGetPersonListForBankSelection(householdId);
  const getPerson = useGetPersonForBankSelection(personId);
  const gdprConsent = getPerson.data?.gdprConsent;
  const finleapConsent = getPerson.data?.finleapConsent;
  const sendBankConnectionEmail = useSendBankConnectionEmail();
  const { continuation, getContinuationHandler, getCanContinueHandler } = useContinuation();
  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);

  useEffect(() => {
    onCanContinueChange(!sendBankConnectionEmail.isPending);
  }, [onCanContinueChange, sendBankConnectionEmail.isPending]);

  useEffect(() => {
    if (householdId && personId) {
      navigateToBankSelection(householdId, personId);
    }
  }, [householdId, personId]);

  const { appInsights } = getAppInsights();

  const sendEmail = async () => {
    try {
      await sendBankConnectionEmail.mutateAsync({ householdId, personId });
      await getPerson.invalidateQuery();
    } catch (e) {
      appInsights.trackException({
        exception: e as Error,
        severityLevel: 3,
      });
      notification.showError({
        title: i18next.t('general.toasters.loading.generic.title'),
      });
    }
  };

  const renderContent = () => {
    if (
      !personId ||
      !getPersonList.data.length ||
      !getPersonList.data.find(({ id }) => id === personId)
    ) {
      return null;
    }

    return (
      <>
        <ConsentStatusWizard currentStatus={finleapConsent?.status} />
        <ProcessDescriptionCard
          emailCommunicationChannel={getPerson.data?.communicationChannelList.find(
            ({ type }) => type === CommunicationChannelType.EMailPrivat,
          )}
          gdprConsent={gdprConsent}
          finleapConsent={finleapConsent}
          isPersonFetched={getPerson.isFetched}
          isEmailSending={sendBankConnectionEmail.isPending}
          onSendEmailClick={sendEmail}
        />
      </>
    );
  };

  return (
    <DxContainer color="transparent" type="page" data-testid="bc-page-container">
      <Header
        selectedPersonId={personId}
        personList={getPersonList.data}
        onPersonIdChange={(newPersonId) => {
          getContinuationHandler(() => {
            setPersonId(newPersonId);
          })?.();
        }}
      />
      <WelcomeCard />
      {renderContent()}
      <Footer
        onBackClick={getContinuationHandler(() => {
          navigateToDashboard(householdId);
        })}
        isContinueDisabled={!isFinleapConsentGranted(finleapConsent)}
        onContinueClick={getContinuationHandler(() => {
          navigateToBankAnalysis(householdId, personId);
        })}
      />
      <UnsavedOrPendingModal
        showPending={continuation !== undefined && sendBankConnectionEmail.isPending}
      />
    </DxContainer>
  );
};
