import { useMutation } from '@tanstack/react-query';

import { personApi } from 'service/api/personApi';
import { removeEmpty } from 'utils/util';
import { useNotification } from 'utils/notification/NotificationContext';
import { RemoveType } from 'type/general';
import { ProfessionFormData, ProfessionPayloadData, ProfessionPayloadDataUpdate } from 'type/job';
import { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';

export const useUpdateProfession = (personId: string | undefined) => {
  const { showError } = useNotification();
  const personService = personApi();

  return useMutation<ProfessionFormData, ErrorRequestEntity, ProfessionPayloadDataUpdate>({
    mutationFn: async (professionData) => {
      const postCallback = () =>
        personService.createProfession(`${personId}`, {
          ...(removeEmpty(professionData as RemoveType) as ProfessionPayloadData),
        });

      const putCallback = () => {
        const payload = professionData;
        delete payload.personId;
        return personService.updateProfession(`${personId}`, payload);
      };

      const response = await (professionData.personId ? putCallback() : postCallback());

      return { personId, ...response.data };
    },
    onError: (error) => {
      showError({ status: error?.response?.status });
    },
  });
};
