import { UseQueryOptions } from '@tanstack/react-query';
import { GetPersonIdListQuery, useGetPersonIdListQuery } from 'graphql/generates';
import { getGraphQLClient } from 'graphql/graphQLClient';
import { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';

export const useGetPersonIdList = (
  householdId?: string,
  options?: Omit<UseQueryOptions<GetPersonIdListQuery, ErrorRequestEntity, string[]>, 'queryKey'>,
) => {
  const query = useGetPersonIdListQuery(
    getGraphQLClient(),
    { householdId: householdId as string },
    {
      select: (response) =>
        response.household.basicData?.personInHouseholdList?.map(({ id }) => id) || [],
      enabled: !!householdId,
      ...options,
    },
  );

  return { ...query, data: query.data || [] };
};
