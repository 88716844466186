import { SavingProfileFormValues } from 'type/savingType';

export const defaultSavingTypeValues: SavingProfileFormValues = {
  hasAsset: false,
  hasConsumerLoan: false,
  hasConsumption: false,
  hasDebt: false,
  hasDiscipline: false,
  hasDuePaymentNotice: false,
  hasOverdraft: false,
  hasPlusMinusZero: false,
  hasRevenueInterest: false,
  hasSavingBook: false,
  hasStrategy: false,
  hasTwoAccountModel: false,
  saverProfileCurrent: 0,
  saverProfileDesired: 0,
};
