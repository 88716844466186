import { useMutation } from '@tanstack/react-query';
import { personApi } from 'service/api/personApi';
import { useNotification } from 'utils/notification/NotificationContext';
import { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';
import { ConsentDto } from 'graphql/generates';

export const useSendBankConnectionEmail = () => {
  const notification = useNotification();
  const personService = personApi();

  return useMutation<
    Omit<ConsentDto, '__typename'>,
    ErrorRequestEntity,
    { householdId: string; personId: string }
  >({
    mutationFn: async (variables) => {
      const response = await personService.sendBankConnectionEmail({
        householdId: variables.householdId,
        personId: variables.personId,
      });
      return response.data;
    },
    onError: (e) => {
      notification.showError({ status: e?.response?.status });
    },
  });
};
