import React from 'react';

import { DxCardHeader, DxIcon } from '@dvag/design-system-react';

import i18next from 'i18next';

import { checkIcon } from 'utils/util';

import './style.css';

const iconSize = 24;

export const FormHeader = ({
  lastName,
  firstName,
  index = 0,
}: {
  firstName?: string;
  lastName?: string;
  index?: number;
}) => {
  const title = lastName && firstName ? `${firstName} ${lastName}` : i18next.t('general.newMember');

  return (
    <DxCardHeader slot="header" label={title}>
      {index === 0 && (
        <div className="shape-container flex column align-content-end align-items-end">
          <div className="pd_person-icon-container flex">
            <DxIcon
              className="manage-HH-mensch-icon"
              icon={checkIcon('mensch')}
              color="white"
              size={iconSize}
            />
          </div>
          <div className="triangle icon-triangle" />
        </div>
      )}
    </DxCardHeader>
  );
};
