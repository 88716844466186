import { useQuery } from '@tanstack/react-query';
import { personApi } from 'service/api/personApi';
import { ProfessionSearch } from 'type/job';

export const useLoadProfessionList = (
  search: string | undefined,
  onDataLoaded: (professionList: ProfessionSearch[]) => void,
) => {
  const personService = personApi();
  const query = useQuery({
    queryKey: [`professionList-${search}`],
    queryFn: async () => {
      const response = await personService.searchProfession(search);
      onDataLoaded(response.data);
      return response.data;
    },
    enabled: !!search,
  });

  return {
    ...query,
    data: query.data || [],
  };
};
