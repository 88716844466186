import { getCountryList, getStateList } from '@dvag/dfs-constant-config';

import { ListItemGraph } from 'type/person';

const countryListData = getCountryList();

const stateListData = getStateList();

export const countryList = countryListData
  ?.filter(({ code, name }) => code && name)
  .map(({ code: value, name }) => ({
    value,
    label: name,
  }));

export const dialCodeList = countryListData
  ?.filter(({ dialCode }) => !!dialCode)
  .map(({ dialCode }) => ({ value: dialCode, label: dialCode }))
  .sort((first: ListItemGraph, second: ListItemGraph) => {
    const firstValue = +(first.value as string).replace(/\D/g, '');
    const secondValue = +(second.value as string).replace(/\D/g, '');
    if (firstValue === secondValue) return 0;
    return firstValue - secondValue;
  });

export const stateList = stateListData
  ?.filter(({ value, name }) => !!value && !!name)
  .map(({ value, name: label }) => ({
    value,
    label,
  }));
