import { useGetPersonForBankAnalysisQuery } from 'graphql/generates';
import { getGraphQLClient } from 'graphql/graphQLClient';
import { PersonConsentType } from 'type/consent';

export const useGetPersonForBankAnalysis = (personId: string) =>
  useGetPersonForBankAnalysisQuery(
    getGraphQLClient(),
    {
      personId,
    },
    {
      enabled: !!personId,
      select: (data) => ({
        id: data.person.id,
        finleapConsent: data.person.consent?.find(({ type }) => type === PersonConsentType.Finleap),
        gdprConsent: data.person.consent?.find(({ type }) => type === PersonConsentType.Gdpr),
      }),
    },
  );
