import React from 'react';

import { DxButton, DxContainer, DxSpacer } from '@dvag/design-system-react';

import i18next from 'i18next';
import { checkIcon } from 'utils/util';

import './style.css';

interface SavingFooterType {
  handleBack: () => void;
  handleContinue: () => void;
}

const translation = {
  back: i18next.t('general.back'),
  continue: i18next.t('general.continue'),
};

export const SavingTypeFooter = ({ handleBack, handleContinue }: SavingFooterType) => (
  <DxContainer className="actions-container" color="transparent">
    <DxContainer color="transparent" className="saving-type-navigation-button">
      <DxButton
        id="savings-type-back-button"
        data-testid="savings-type-back"
        className="navigation-back-button"
        icon={checkIcon('pfeil-links')}
        iconposition="left"
        type="text"
        label={translation.back}
        onClick={handleBack}
      />

      <DxSpacer size="24h" />

      <DxButton
        id="savings-type-front-button"
        data-testid="savings-type-continue"
        type="primary-m"
        icon={checkIcon('pfeil-rechts')}
        iconposition="right"
        label={translation.continue}
        onClick={handleContinue}
      />
    </DxContainer>
  </DxContainer>
);
