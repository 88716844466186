import React, { useCallback, useEffect } from 'react';

import {
  DxButton,
  DxCard,
  DxCardHeader,
  DxCardImage,
  DxContainer,
  DxText,
} from '@dvag/design-system-react';

import i18next from 'i18next';

import { useLocalStorage } from 'hooks/useLocalStorage';
import { Card } from 'type/savingType';
import { savingTypeUrl } from 'utils/savingTypeUrl';
import { checkIcon } from 'utils/util';

import './style.modal.css';

interface SavingTypeCardStartProps {
  card: Card;
  currentStep?: string;
  savingTypeId: string;
}

export const SavingTypeCardStart = ({
  card,
  currentStep = 'merkmale',
  savingTypeId,
}: SavingTypeCardStartProps) => {
  const [isClicked, setIsClicked] = useLocalStorage(`savingsType-${savingTypeId}`, false);
  useEffect(() => {
    if (currentStep !== savingTypeUrl.start.url) {
      setIsClicked(true);
    }
  }, [currentStep, setIsClicked]);

  const showPlaceholder = useCallback(() => !isClicked, [isClicked]);

  return (
    <DxCard
      className={`saving-type-card ${showPlaceholder() ? 'placeholder-border' : ''}`}
      key={card.id}
      id={card.id}
      data-testid={`saving-type-card-start-${card.id}`}
    >
      {showPlaceholder() && (
        <DxContainer
          color="white"
          onClick={() => setIsClicked(true)}
          className="placeholder-container"
          data-testid={`placeholder-container-${card.id}`}
        >
          <DxButton
            id={`${card.id}-click-here-button`}
            label={i18next.t('savingType.clickHere')}
            icon={checkIcon('auge')}
            iconposition="right"
            type="text"
          />
        </DxContainer>
      )}
      <DxCardHeader id={`${card.id}-title`} slot="header" label={card.title} />
      <DxCardImage id={`${card.id}-image`} url={card.image} />
      <DxContainer
        id={`${card.id}-description-container`}
        color="transparent"
        className="card-text-container"
      >
        <DxText id={`${card.id}-description`} type="ps">
          {card.text}
        </DxText>
      </DxContainer>
    </DxCard>
  );
};
