import { DxButton, DxCheckbox, DxText, DxTextInput } from '@dvag/design-system-react';
import i18next from 'i18next';
import { useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import * as yup from 'yup';
import { useAutosave } from '@dvag/dfs-ui-blocks/hooks';
import {
  ResourceRequestResponse,
  UpdateManyResources,
} from '@dvag/dfs-ui-blocks/hooks/useAutosaveHelper';
import {
  bankInformationNameKeyName,
  defaultBankDetailsData,
  mandatoryFieldMessage,
} from 'form/util';

import { useUpdateManyBankAccounts } from 'hooks/useUpdateManyBankAccounts';
import { style } from 'styleConfig';
import { IBankDetails } from 'type/bankAccount';
import { FormType, FormTypes, SecondaryFormType, TriggerValidationValue } from 'utils/fieldList';
import { ContinutationRequest } from 'utils/useContinuation';
import { checkIcon } from 'utils/util';

import { invalidIBANErrorMessage, validateIBAN } from '../../validation';
import './style.css';

interface BankAccountSectionProps {
  data: Array<IBankDetails>;
  personId: string;
  onFormDirtyStatusChanged: (formType: FormTypes, isDirty: boolean) => void;
  onFormValidStatusChanged: (formType: FormTypes, isValid: boolean) => void;
  onFormSubmittingStatusChanged: (formType: FormTypes, isSubmitting: boolean) => void;
  onFormSaved: (formType: FormTypes) => void;
  getCanContinueHandler: () => (canContinueCond: boolean) => void;
  onContinuationRequest: (continuationRequestCallback: ContinutationRequest) => void;
  setTriggerValidationForms: (
    value: TriggerValidationValue,
    formType: FormTypes,
    secondaryFormType?: SecondaryFormType,
  ) => void;
}

const translation = {
  mainBankAccount: i18next.t('bankDetails.mainBankAccount'),
  furtherBankDetails: i18next.t('bankDetails.furtherBankDetails'),
  addButton: i18next.t('bankDetails.addBankAccount'),
  ibanInputLabel: i18next.t('bankDetails.bankAccountNo'),
  bankNameInputLabel: i18next.t('bankDetails.bankName'),
  bankNameInputPlaceholder: i18next.t('bankDetails.nonEditableField'),
  inputDefaultPlaceholder: i18next.t('general.inputDefaultPlaceholder'),
  bankSelectionLabel: i18next.t('bankDetails.selectionLabel'),
};

const resourceSchema = yup.object().shape({
  iban: yup
    .string()
    .required(mandatoryFieldMessage)
    .test('iban', invalidIBANErrorMessage, validateIBAN),
});

export const BankAccountSection = ({
  personId,
  onFormDirtyStatusChanged,
  onFormValidStatusChanged,
  onFormSubmittingStatusChanged,
  onFormSaved,
  getCanContinueHandler,
  onContinuationRequest,
  data,
  setTriggerValidationForms,
}: BankAccountSectionProps) => {
  const defaultBankDetails = defaultBankDetailsData.bankInformationData[0];
  const [, startTransition] = useTransition();
  const updateBankAccounts = useUpdateManyBankAccounts(personId);
  const initialFinancialInstitutionMap = new Map(
    data.map((bankDetails) => [bankDetails.id, bankDetails.financialInstitution]),
  );
  const [financialInstitutionMap, setFinancialInstitutionMap] = useState(
    initialFinancialInstitutionMap,
  );

  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);

  const onRequestResponse = useCallback(
    (requestResponses: ResourceRequestResponse<IBankDetails>[]) => {
      requestResponses.forEach((requestResponse) => {
        if (
          (requestResponse.action === 'CREATE' || requestResponse.action === 'UPDATE') &&
          requestResponse.response !== undefined
        ) {
          financialInstitutionMap.set(
            requestResponse.response.id,
            requestResponse.response.financialInstitution,
          );
        }

        if (requestResponse.action === 'DELETE') {
          financialInstitutionMap.delete(requestResponse.request.id);
        }
        setFinancialInstitutionMap(new Map(financialInstitutionMap));
      });
    },
    [financialInstitutionMap],
  );

  const {
    flushChanges,
    remove,
    append,
    resources,
    register,
    getValues,
    canAddResource,
    setValue,
    triggerValidation,
  } = useAutosave<IBankDetails>({
    data,
    defaultData: defaultBankDetails,
    resourceSchema,
    updateManyResources:
      updateBankAccounts.updateManyBankAccounts as unknown as UpdateManyResources<IBankDetails>,
    onIsValidChange: (isValidParam) => onFormValidStatusChanged(FormType.bankDetails, isValidParam),
    onSubmitSuccessfulChange: (isSubmitSuccessfulParam) => {
      if (isSubmitSuccessfulParam) onFormSaved(FormType.bankDetails);
    },
    onIsDirtyChange: (isDirtyParam) => {
      onFormDirtyStatusChanged(FormType.bankDetails, isDirtyParam);
      onCanContinueChange(!isDirtyParam);
    },
    onIsSubmittingChange: (isSubmittingParam) => {
      onFormSubmittingStatusChanged(FormType.bankDetails, isSubmittingParam);
    },
    onRequestResponse,
  });

  const addEmptyBankAccount = useCallback(() => {
    startTransition(() => {
      append({ ...defaultBankDetails }, { shouldFocus: false });
    });
  }, [append, defaultBankDetails]);

  const removeBankAccount = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  useEffect(() => {
    onContinuationRequest(() => {
      flushChanges();
      triggerValidation();
    });
  }, [flushChanges, onContinuationRequest, triggerValidation]);

  useEffect(() => {
    setTriggerValidationForms(triggerValidation as TriggerValidationValue, FormType.bankDetails);
  }, [setTriggerValidationForms, triggerValidation]);

  const renderDeleteButton = (field: IBankDetails, index: number) => {
    const isReadOnly = !!field.isReadOnly;
    const isIbanSet = getValues('iban', index)?.length;
    const isMainAccount = index === 0;
    const disabled = isReadOnly || (!isIbanSet && isMainAccount);
    return (
      <DxButton
        type="text"
        disabled={disabled}
        className="pd_bankDetails-delete-button"
        onClick={() => removeBankAccount(index)}
        data-testid={`pd_deleteBankAccount-${index}`}
        id={`pd_deleteBankAccount-${index}`}
        icon={checkIcon('loeschen')}
        theme="destructive"
      />
    );
  };

  const renderIbanInput = (field: IBankDetails, index: number) => (
    <DxTextInput
      size="m"
      className="pd_bankDetails-input"
      id={`pd_IBAN-${index}`}
      placeholder={translation.inputDefaultPlaceholder}
      {...register(`iban`, index)}
      label={translation.ibanInputLabel}
      data-testid={`pd_IBAN-${index}`}
      disabled={field.isReadOnly || false}
      required={resources.length === 1}
    />
  );

  const renderBankNameInput = (field: IBankDetails, index: number, id: string) => (
    <DxTextInput
      size="m"
      data-testid={`pd_BANK-${index}`}
      className="pd_bankDetails-input"
      id={`${bankInformationNameKeyName}.${index}.financialInstitution`}
      label={translation.bankNameInputLabel}
      placeholder={translation.bankNameInputPlaceholder}
      value={financialInstitutionMap.get(id)}
      disabled
    />
  );

  const renderCheckbox = (field: IBankDetails, index: number) => (
    <DxCheckbox
      style={{ marginRight: 'auto' }}
      checked={!!getValues('isUsedForCalculation', index)}
      label={translation.bankSelectionLabel}
      onCheckedChange={(e) => {
        setValue('isUsedForCalculation', e.detail, index);
      }}
      {...register('isUsedForCalculation', index)}
      data-testid={`pd_isUsedForCalculation-${index}`}
      disabled={field.isReadOnly || false}
      id={`pd_isUsedForCalculation-${index}`}
    />
  );

  const renderBankAccountField = (field: IBankDetails, idx: number) => {
    const isMainAccount = idx === 0;
    register(`id`, idx);

    return (
      <div key={`bankDetailsSection-${idx}`}>
        <div className="pd_bankDetails-title-container">
          {idx < 2 && (
            <DxText color="headline" type="Paragraph-Standard">
              {isMainAccount ? translation.mainBankAccount : translation.furtherBankDetails}
            </DxText>
          )}
        </div>
        <div
          className="pd_bankDetails-field-content"
          style={{
            gridTemplateColumns: style.contactEmailColumnGridTemplate,
          }}
        >
          {renderIbanInput(field, idx)}
          {renderBankNameInput(field, idx, getValues('id', idx) as string)}
        </div>
        <div className="pd_bankDetails-action">
          {renderCheckbox(field, idx)}
          {renderDeleteButton(field, idx)}
        </div>
      </div>
    );
  };

  return (
    <>
      <form className="pd_bankDetails-accounts-form">{resources?.map(renderBankAccountField)}</form>
      <DxButton
        type="text"
        className="pd_bankDetails-add"
        data-testid="pd_add-new-bank-account-section"
        id="pd_bankDetails-addNewSection"
        onClick={addEmptyBankAccount}
        label={translation.addButton}
        icon={checkIcon('plus-kreis')}
        disabled={!canAddResource()}
      />
    </>
  );
};
