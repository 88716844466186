import { navigateToMicroApp } from '@dvag/dfs-orchestrator-client/navigation';

import { Routes } from 'named-urls';

import { buildPath } from './buildPath';

export const handleNavigateToPath = (
  route: string | Routes,
  householdId: string | number | undefined,
  microApp = 'vpd',
): void => {
  const path = buildPath(route, { householdId: householdId as string });
  navigateToMicroApp(microApp, path);
};
