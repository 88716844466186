import React from 'react';
import debounce from 'debounce';
import { useNavigate, useParams } from 'react-router-dom';
import i18next from 'i18next';

import { DxButton, DxContainer, DxGrid } from '@dvag/design-system-react';
import { navigateNext } from '@dvag/dfs-orchestrator-client/messengers';

import { sharedAppRoutes } from '@dvag/dfs-constant-config/shared-app-names';
import { dashboardUtil } from 'service/model/dashboardUtil';
import { handleNavigateToPath } from 'service/handleNavigateToPath';
import { checkIcon } from 'utils/util';

interface FooterProps {
  handleBack?: () => void;
  handleContinue?: () => void;
  handleGoToFinancialNote?: () => void;
  hideFinancialGrade?: boolean;
}

const translation = {
  goToFinanceGrade: i18next.t('general.goToFinanceGrade'),
  back: i18next.t('general.back'),
  continue: i18next.t('general.continue'),
};

export const Footer = ({
  handleBack,
  handleContinue,
  handleGoToFinancialNote,
  hideFinancialGrade = false,
}: FooterProps) => {
  const navigate = useNavigate();
  const { ACCOUNTING } = dashboardUtil;
  const { householdId } = useParams();

  const onBackClick =
    handleBack ||
    (() => {
      debounce(() => navigate(-1), 200)();
    });

  const onContinueClick =
    handleContinue ||
    (() => {
      debounce(() => navigateNext(), 200)();
    });

  const onGoToFinancialGradeClick =
    handleGoToFinancialNote ||
    (() => {
      debounce(
        () => handleNavigateToPath(sharedAppRoutes.person.financeNote, householdId, ACCOUNTING),
        200,
      )();
    });

  return (
    <DxContainer color="transparent" className="footer-wrapper container-wrapper">
      <DxGrid mq5="6-6/*" mq4="6-6/*" mq3="12/*" mq2="12/*" mq1="12/*">
        <DxContainer color="transparent" className="left-controls">
          {!hideFinancialGrade && (
            <DxButton
              id="wg-go-to-finance-grade-button"
              type="text"
              data-testid="go-to-finance-grade"
              onClick={onGoToFinancialGradeClick}
              label={translation.goToFinanceGrade}
            />
          )}
        </DxContainer>
        <DxContainer color="transparent" className="navigation-buttons margin-fix">
          <DxContainer color="transparent" className="navigation-button">
            <DxButton
              id="wg-back-button"
              data-testid="wishes-and-goals_back"
              type="text"
              icon={checkIcon('pfeil-links')}
              iconposition="left"
              stretch
              label={translation.back}
              onClick={onBackClick}
            />
          </DxContainer>
          <DxContainer color="transparent" className="navigation-button">
            <DxButton
              id="wg-continue-button"
              data-testid="wishes-and-goals_continue"
              type="primary-s"
              icon={checkIcon('pfeil-rechts')}
              iconposition="right"
              stretch
              label={translation.continue}
              onClick={onContinueClick}
            />
          </DxContainer>
        </DxContainer>
      </DxGrid>
    </DxContainer>
  );
};
