import React from 'react';

import {
  DxCard,
  DxCardContent,
  DxCardImage,
  DxContainer,
  DxText,
  DxCardHeader,
} from '@dvag/design-system-react';

import { SavingTypeCardProps } from 'type/savingType';
import { CheckBoxGroup } from './CheckBoxGroup/CheckBoxGroup';

import './style.modal.css';

export const SavingTypeCard = ({
  setValue,
  getValues,
  register,
  card,
  currentStep,
}: SavingTypeCardProps) => (
  <DxCard key={card.id} id={card.id} data-testid={`saving-type-card-${card.id}`}>
    <DxCardHeader id={`${card.id}-title`} slot="header" label={card.title} />
    <DxCardImage id={`${card.id}-image`} url={card.image} />
    <DxContainer
      id={`${card.id}-description-container`}
      color="transparent"
      className="card-text-container"
    >
      <DxText id={`${card.id}-description`} type="ps">
        {card.text}
      </DxText>
    </DxContainer>

    <DxCardContent id={`${card.id}-checkbox-container`} className="checkbox-container">
      <CheckBoxGroup
        register={register}
        checkBoxList={card.checkBoxList}
        currentStep={currentStep}
        setValue={setValue}
        getValues={getValues}
      />
    </DxCardContent>
  </DxCard>
);
