import React from 'react';

import { DxCheckbox, DxList } from '@dvag/design-system-react';
import { CheckBoxGroupProps } from 'type/savingType';
import { checkboxLabelMap } from './util';
import './checkBoxStyle.modal.css';

export const CheckedCheckBoxGroup = ({ getValues, checkBoxList }: CheckBoxGroupProps) => {
  const onlyChecked = checkBoxList.filter((elem) => getValues(elem.name));
  return (
    <DxList>
      {onlyChecked.map((checkBox, index) => (
        <DxCheckbox
          data-testid={`${checkBox.name}-checkbox`}
          label={checkboxLabelMap[checkBox.name]}
          key={checkBox.name}
          id={checkBox.name}
          className={index === onlyChecked.length - 1 ? 'checkbox' : 'checkbox-bordered'}
          checked={!!getValues(checkBox.name)}
          disabled
        />
      ))}
    </DxList>
  );
};
