import React from 'react';

import './style.css';

export const LoadingCard = () => (
  <div className="card-wrapper-placeholder">
    <div className="card-image-placeholder" />
    <div className="card-info-placeholder">
      <div className="placeholder-content">
        <div className="placeholder-title" />
        <div className="placeholder-icon" />
      </div>
      <div className="placeholder-list-item" />
      <div className="placeholder-list-item" />
    </div>
  </div>
);
