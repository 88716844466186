import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import { useGetPersonIdList } from '../hooks/useGetPersonIdList';

export const useValidateUrlParams = () => {
  const { householdId, personId } = useParams<{ householdId: string; personId: string }>();
  const { showBoundary } = useErrorBoundary();
  const personIdListQuery = useGetPersonIdList(householdId, {
    enabled: !!householdId && !!personId,
  });

  useEffect(() => {
    if (
      personIdListQuery.isSuccess &&
      !personIdListQuery.isFetching &&
      personId &&
      !personIdListQuery.data.includes(personId)
    ) {
      showBoundary({ message: 'Not Found', cause: 404 });
    }
  }, [
    showBoundary,
    personIdListQuery.isSuccess,
    personId,
    personIdListQuery.data,
    personIdListQuery.isFetching,
  ]);

  return personIdListQuery;
};
