import React from 'react';
import { DxGrid, DxText } from '@dvag/design-system-react';
import i18next from 'i18next';
import { PersonInHouseholdDto } from 'graphql/generates';
import { PersonDropdown } from './PersonDropdown';
import './Header.css';

type HeaderProps = {
  selectedPersonId: string;
  personList: Omit<PersonInHouseholdDto, 'order'>[];
  onPersonIdChange: (id: string) => void;
};

export const Header = ({ selectedPersonId, personList, onPersonIdChange }: HeaderProps) => (
  <DxGrid
    className="bc-title-container"
    mq2="12/*"
    mq3="7-(0)-(0)-5"
    mq4="8-(0)-(0)-4"
    mq5="9-(0)-(0)-3"
  >
    <DxText data-testid="bc-header-title" type="h3">
      {i18next.t('bankSelectionPage.title')}
    </DxText>
    <PersonDropdown selectedId={selectedPersonId} list={personList} onChange={onPersonIdChange} />
  </DxGrid>
);
