import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { refetchPersonList } from '@dvag/dfs-orchestrator-client/messengers';
import { personApi } from 'service/api/personApi';
import { ICreatePerson, ManagePerson } from 'type/createPerson';
import { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';

import { familyRelationshipList } from 'utils/util';
import { useNotification } from 'utils/notification/NotificationContext';
import { getPersonListManageHouseholdQueryKey } from './usePersonListManageHousehold';

export const useCreatePerson = () => {
  const { showError } = useNotification();
  const queryClient = useQueryClient();
  const { householdId } = useParams();
  const personService = personApi();

  return useMutation<ManagePerson, ErrorRequestEntity, ICreatePerson>({
    mutationFn: async (formData: ICreatePerson) => {
      const body = {
        familyRelationship: formData.familyRelationship
          ? familyRelationshipList.KIND
          : familyRelationshipList.ERWACHSEN,
        firstName: formData.firstName,
        lastName: formData.lastName,
        salutation: formData.salutation,
      };

      const result = householdId
        ? await personService.createPersonInHousehold(householdId, body)
        : await personService.createPersonAndHousehold(body);

      return result.data;
    },
    onSuccess: () => {
      const queryKey = getPersonListManageHouseholdQueryKey(householdId);
      queryClient.invalidateQueries({ queryKey });
      refetchPersonList();
    },
    onError: (error) => {
      showError({ status: error?.response?.status });
    },
  });
};
