import { isSmoker } from 'utils/util';
import { useGetBasicDataQuery, GetBasicDataQuery } from 'graphql/generates';
import { getGraphQLClient } from 'graphql/graphQLClient';
import { BasicData } from 'type/basicData';

const transformValues = (response: GetBasicDataQuery): BasicData => {
  const responseData = response.person;
  const transformedBasicData: BasicData = {
    ...(responseData.basicData as BasicData),
    smoker: isSmoker(responseData.basicData?.isSmoker as boolean),
  };

  return {
    ...transformedBasicData,
  };
};

export const useGetBasicData = (personId: string | undefined, householdId: string | undefined) => {
  const query = useGetBasicDataQuery(
    getGraphQLClient(),
    { personId: personId as string, householdId },
    {
      select: (response) => transformValues(response),
    },
  );

  return { ...query, data: query.data || ({} as BasicData) };
};
