import React from 'react';
import { DxButton, DxContainer, DxGrid } from '@dvag/design-system-react';
import i18next from 'i18next';
import { checkIcon } from 'utils/util';
import './Footer.css';

type FooterProps = {
  onBackClick?: () => void;
  isContinueDisabled?: boolean;
  onContinueClick?: () => void;
};

export const Footer = ({ onBackClick, isContinueDisabled, onContinueClick }: FooterProps) => (
  <DxGrid className="bc-footer-container" mq3="6-(0)-(0)-6" mq4="4-(2)-(2)-4" mq5="2-(4)-(3)-3">
    <DxButton
      id="bc-back-button"
      data-testid="bc-back-button"
      type="text"
      icon={checkIcon('pfeil-links')}
      iconposition="left"
      label={i18next.t('general.back')}
      onClick={onBackClick}
    />
    <DxContainer color="transparent" className="bc-footer-continue-container">
      <DxButton
        id="bc-continue-button"
        data-testid="bc-continue-button"
        type="primary-s"
        icon={checkIcon('dokument')}
        iconposition="right"
        disabled={isContinueDisabled}
        label={i18next.t('bankSelectionPage.footer.continue')}
        onClick={onContinueClick}
      />
    </DxContainer>
  </DxGrid>
);
