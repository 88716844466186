import { useMutation } from '@tanstack/react-query';

import { personApi } from 'service/api/personApi';
import { Address, AddressPayload } from 'type/address';
import { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';
import { useNotification } from 'utils/notification/NotificationContext';
import { dataContext } from 'utils/util';

export const useCreateOrUpdateAddress = (
  personId: string | undefined,
  householdId: string | undefined,
) => {
  const { showError } = useNotification();
  const personService = personApi();

  return useMutation<Address, ErrorRequestEntity, Address>({
    mutationFn: async (formData: Address) => {
      const { id, context, ...dataForSubmit } = formData;

      const payload: AddressPayload = {
        ...(id && context === dataContext.household && { householdId }),
        ...(id && { context }),
        isPostalAddress: dataForSubmit.isPostalAddress,
        city: dataForSubmit.city,
        country: dataForSubmit.country,
        street: dataForSubmit.street,
        zipCode: dataForSubmit.zipCode,
        state: dataForSubmit.state,
        isVerified: !!dataForSubmit.isVerified,
      };

      const response = id
        ? await personService.updateAddress(id, payload, personId)
        : await personService.createAddress(payload, personId);

      return response.data;
    },
    onError: (error) => {
      showError({ status: error?.response?.status });
    },
  });
};
