import React, { useCallback, useEffect } from 'react';

import {
  DxButton,
  DxCard,
  DxCardContent,
  DxCardHeader,
  DxContainer,
  DxText,
} from '@dvag/design-system-react';

import i18next from 'i18next';

import { useLocalStorage } from 'hooks/useLocalStorage';
import { SavingTypeCardProps } from 'type/savingType';
import { savingTypeUrl } from 'utils/savingTypeUrl';
import { checkIcon } from 'utils/util';
import { CheckedCheckBoxGroup } from './CheckBoxGroup/CheckedCheckBoxGroup';

import './style.modal.css';

export const SavingTypeCheckedCard = ({
  card,
  currentStep,
  savingTypeId,
  getValues,
  register,
}: SavingTypeCardProps) => {
  const [isClicked, setIsClicked] = useLocalStorage(`savingsType-${savingTypeId}`, false);

  useEffect(() => {
    if (currentStep !== savingTypeUrl.start.url) {
      setIsClicked(true);
    }
  }, [currentStep, setIsClicked]);

  const checkBoxList = () => {
    if (savingTypeUrl.start.url === currentStep) return [];
    return card.checkBoxList;
  };
  const showPlaceholder = useCallback(() => !isClicked, [isClicked]);

  return (
    <DxCard
      className={showPlaceholder() ? 'placeholder-border' : ''}
      key={card.id}
      id={card.id}
      data-testid={`saving-type-checked-card-${card.id}`}
    >
      {showPlaceholder() && (
        <DxContainer
          color="white"
          onClick={() => setIsClicked(true)}
          className="placeholder-container"
          data-testid={`checked-placeholder-container-${card.id}`}
        >
          <DxButton
            id={`${card.id}-click-here-button`}
            label={i18next.t('savingType.clickHere')}
            icon={checkIcon('auge')}
            iconposition="right"
            type="text"
          />
        </DxContainer>
      )}
      <DxCardHeader id={`${card.id}-title`} slot="header" label={card.title} />
      <DxContainer
        id={`${card.id}-description-container`}
        color="transparent"
        className="card-text-container"
      >
        <DxText id={`${card.id}-description`} type="ps">
          {card.text}
        </DxText>
      </DxContainer>
      {checkBoxList()?.length > 0 && (
        <DxCardContent id={`${card.id}-checkbox-container`} className="checkbox-container">
          <CheckedCheckBoxGroup
            register={register}
            getValues={getValues}
            checkBoxList={checkBoxList()}
          />
        </DxCardContent>
      )}
    </DxCard>
  );
};
