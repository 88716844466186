import {
  UPDATE_PERSONAL_DATA_FORM_IS_DIRTY,
  UPDATE_PERSONAL_DATA_FORM_IS_SUBMITTING,
  UPDATE_PERSONAL_DATA_FORM_IS_VALID,
  UPDATE_PERSONAL_DATA_FORM_SUCCESS,
} from 'config/action';

export interface PersonalDataStateSection {
  isSuccess: boolean;
  isValid: boolean;
  isDirty: boolean;
  isSubmitting: boolean;
}

export interface PersonalDataState {
  basicData: PersonalDataStateSection;
  address: PersonalDataStateSection;
  contact: PersonalDataStateSection;
  profession: PersonalDataStateSection;
  tax: PersonalDataStateSection;
  bankDetails: PersonalDataStateSection;
}

export interface PersonalDataActionPayload {
  key: string;
  value: {
    isSuccess?: boolean;
    isValid?: boolean;
    isDirty?: boolean;
    isSubmitting?: boolean;
  };
}

export interface PersonalDataAction {
  type: string;
  payload: PersonalDataActionPayload;
}

export const reducer = (state: PersonalDataState, action: PersonalDataAction) => {
  switch (action.type) {
    case UPDATE_PERSONAL_DATA_FORM_SUCCESS:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key as keyof PersonalDataState],
          isSuccess: action.payload.value.isSuccess,
        },
      };
    case UPDATE_PERSONAL_DATA_FORM_IS_VALID:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key as keyof PersonalDataState],
          isValid: action.payload.value.isValid,
        },
      };
    case UPDATE_PERSONAL_DATA_FORM_IS_DIRTY:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key as keyof PersonalDataState],
          isDirty: action.payload.value.isDirty,
        },
      };
    case UPDATE_PERSONAL_DATA_FORM_IS_SUBMITTING:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key as keyof PersonalDataState],
          isSubmitting: action.payload.value.isSubmitting,
        },
      };
    default: {
      return state;
    }
  }
};
