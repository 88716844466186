import { useMutation } from '@tanstack/react-query';

import { personApi } from 'service/api/personApi';
import { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';
import { TaxData } from 'type/taxData';
import { removeEmpty, transformEmptyToNull } from 'utils/util';
import { useNotification } from 'utils/notification/NotificationContext';

export const useUpdateTaxData = ({ personId }: { personId: string | undefined }) => {
  const { showError } = useNotification();
  const personService = personApi();

  return useMutation<TaxData, ErrorRequestEntity, TaxData>({
    mutationKey: [`tax-information-${personId}`],
    mutationFn: async (taxData) => {
      const { taxReturnType, ...restFormData } = taxData;
      const transformedValues = transformEmptyToNull(restFormData) as TaxData;
      const payload = {
        childAllowance:
          transformedValues?.childAllowance && Number(transformedValues?.childAllowance),
        churchTax: transformedValues?.churchTax && Number(transformedValues?.churchTax),
        incomeSocialInsurancePreviousYear: transformedValues?.incomeSocialInsurancePreviousYear,
        taxAdvisor: transformedValues?.taxAdvisor,
        taxNumber: transformedValues?.taxNumber,
        taxReturnType: taxReturnType || undefined,
        taxableIncomePreviousYear: transformedValues?.taxableIncomePreviousYear,
      };
      const response = await personService.updateTaxData(`${personId}`, removeEmpty(payload));
      return response.data;
    },
    onError: (error) => {
      showError({ status: error?.response?.status });
    },
  });
};
