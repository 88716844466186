import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetSaverProfile } from 'hooks/useGetSaverProfile';
import { SavingTypeForm } from './component/SavingTypeForm/SavingTypeForm';

export const SavingType = () => {
  const { householdId } = useParams();
  const { data, isLoading } = useGetSaverProfile(`${householdId}`);

  if (isLoading) return null;
  return <SavingTypeForm savingProfile={data} />;
};
