import { useGetPersonListForBankAnalysisQuery } from 'graphql/generates';
import { getGraphQLClient } from 'graphql/graphQLClient';
import { isFinleapConsentGranted } from 'utils/ConsentUtil';
import { PersonFamilyRelationship } from 'type/person';

export const useGetPersonListForBankAnalysis = (householdId: string) => {
  const query = useGetPersonListForBankAnalysisQuery(
    getGraphQLClient(),
    {
      householdId,
    },
    {
      enabled: !!householdId,
      select: (response) => {
        const consentList = response?.household.consent || [];
        const hasFinleapConsentGranted = (personId: string) =>
          consentList.find(
            (consent) => consent.personId === personId && isFinleapConsentGranted(consent),
          );
        const personList = response?.household.basicData?.personInHouseholdList || [];
        return personList
          .filter(({ familyRelationship }) => familyRelationship === PersonFamilyRelationship.Adult)
          .filter(({ id }) => hasFinleapConsentGranted(id));
      },
    },
  );

  return {
    ...query,
    data: query.data || [],
  };
};
