import {
  PersonWishGoalMutationTransactionInputDto,
  useUpdatePersonWishGoalMutation,
} from 'graphql/generates';
import { getGraphQLClient } from 'graphql/graphQLClient';
import { useNotification } from 'utils/notification/NotificationContext';

export const useUpdateManyPersonWishGoals = (personId: string) => {
  const { showError } = useNotification();
  const wishGoalMutation = useUpdatePersonWishGoalMutation(getGraphQLClient(), {
    onError: () => {
      showError();
    },
    onSuccess: (data) => {
      const failedTransaction = data.wishGoal.transactionList.find(
        (transaction) => transaction.errorList?.length,
      );
      if (failedTransaction) {
        showError({ description: failedTransaction.errorList?.[0].message });
      }
    },
  });

  return {
    ...wishGoalMutation,
    mutateAsync: (transactionList: PersonWishGoalMutationTransactionInputDto[]) =>
      wishGoalMutation
        .mutateAsync({
          wishGoalInput: {
            personId,
            transactionList: transactionList.map((transactionItem) => {
              const { operation, data } = transactionItem;
              return {
                operation,
                data: {
                  wishGoalId: data.wishGoalId,
                  isDesired: data.isDesired,
                  order: data.order,
                  comment: data.comment,
                },
              };
            }),
          },
        })
        .then((output) => output.wishGoal.transactionList),
  };
};
