import { useMutation } from '@tanstack/react-query';

import { personApi } from 'service/api/personApi';
import { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';
import { TaxData, TaxDataCreateType } from 'type/taxData';
import { removeEmpty } from 'utils/util';
import { useNotification } from 'utils/notification/NotificationContext';

export const useCreateTaxData = ({ personId }: { personId: string | undefined }) => {
  const { showError } = useNotification();
  const personService = personApi();

  return useMutation<TaxData, ErrorRequestEntity, TaxDataCreateType>({
    mutationKey: [`tax-information-${personId}`],
    mutationFn: async (taxData) => {
      const payload = {
        childAllowance: taxData?.childAllowance && Number(taxData?.childAllowance),
        churchTax: taxData?.churchTax && Number(taxData?.churchTax),
        incomeSocialInsurancePreviousYear: taxData?.incomeSocialInsurancePreviousYear,
        taxAdvisor: taxData?.taxAdvisor,
        taxNumber: taxData?.taxNumber,
        taxReturnType: taxData?.taxReturnType,
        taxableIncomePreviousYear: taxData?.taxableIncomePreviousYear,
      };
      const response = await personService.createTaxData(removeEmpty(payload), personId);
      return response.data;
    },
    onError: (error) => {
      showError({ status: error?.response?.status });
    },
  });
};
