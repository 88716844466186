import React from 'react';

import { DxCheckbox, DxList } from '@dvag/design-system-react';
import {
  CheckBoxGroupProps,
  SaverFields,
  SaverFieldType,
  SavingProfileFormValues,
} from 'type/savingType';
import { checkboxLabelMap } from './util';
import './checkBoxStyle.modal.css';

export const CheckBoxGroup = ({
  register,
  checkBoxList,
  setValue,
  getValues,
}: CheckBoxGroupProps) => (
  <DxList>
    {checkBoxList.map((checkBox, index) => (
      <DxCheckbox
        data-testid={`${checkBox.name}-checkbox`}
        label={checkboxLabelMap[checkBox.name]}
        key={checkBox.name}
        id={checkBox.name}
        className={index === checkBoxList.length - 1 ? 'checkbox' : 'checkbox-bordered'}
        {...register(SaverFieldType[checkBox.name as keyof SaverFields])}
        checked={getValues(checkBox.name) as boolean | undefined}
        onCheckedChange={(e) => {
          setValue?.(checkBox.name as keyof SavingProfileFormValues, e.detail);
        }}
      />
    ))}
  </DxList>
);
