import React from 'react';
import { DxContainer, DxSpacer } from '@dvag/design-system-react';

export const Separator = () => (
  <DxContainer color="transparent">
    <DxSpacer size="24" />
    <DxContainer data-testid="bc-separator" color="gray-16" style={{ height: 1 }} />
    <DxSpacer size="24" />
  </DxContainer>
);
