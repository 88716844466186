import { useMutation } from '@tanstack/react-query';
import { personApi } from 'service/api/personApi';
import type { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';

interface Variables {
  personId: string;
  personIdList: Array<string>;
}

export const useCopyWishGoalList = () => {
  const personService = personApi();

  const copy = async (variables: Variables) =>
    personService.copyWishAndGoal(variables.personId, {
      personIdList: variables.personIdList,
    });

  return useMutation<unknown, ErrorRequestEntity, Variables>({ mutationFn: copy });
};
