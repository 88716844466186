import { useParams } from 'react-router-dom';
import { useGetPersonListQuery } from 'graphql/generates';
import { getGraphQLClient } from 'graphql/graphQLClient';

export const usePersonList = () => {
  const { householdId } = useParams<{ householdId: string }>();

  const query = useGetPersonListQuery(
    getGraphQLClient(),
    {
      householdId: householdId || '',
    },
    {
      enabled: !!householdId,
      select: (response) => response.household.basicData.personInHouseholdList,
    },
  );

  return {
    ...query,
    data: query.data || [],
  };
};
