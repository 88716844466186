import React from 'react';
import { DxContainer, DxIcon, DxText } from '@dvag/design-system-react';
import { checkIcon } from 'utils/util';
import './Email.css';

type EmailProps = {
  children: string;
};

export const Email = ({ children }: EmailProps) => (
  <DxContainer
    color="transparent"
    className="bc-email-container"
    data-testid="bc-consent-details-email-container"
  >
    <DxContainer color="transparent" className="bc-email-icon-container">
      <DxIcon
        icon={checkIcon('mail')}
        size={24}
        color="gray-83"
        className="bc-email-icon"
        data-testid="bc-consent-details-email-icon"
      />
    </DxContainer>
    <DxText color="gray-83" data-testid="bc-consent-details-email-children">
      {children}
    </DxText>
  </DxContainer>
);
