import { useState } from 'react';
import { getAppInsights } from '../service/getAppInsights';

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const { appInsights } = getAppInsights();

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      appInsights.trackException({
        exception: error as Error,
        severityLevel: 3,
      });
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    window.localStorage.setItem(key, JSON.stringify(valueToStore));
  };

  return [storedValue, setValue];
};
