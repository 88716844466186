import { useQuery } from '@tanstack/react-query';

import { personApi } from 'service/api/personApi';
import { ValidateAddress } from 'type/address';

export const useValidateAddress = (data?: ValidateAddress) =>
  useQuery({
    queryKey: [
      `validateAddress-${data?.country}-${data?.zipCode}-${data?.city}-${data?.street}-${data?.number}`,
    ],
    queryFn: async () => {
      const { country, zipCode, city, street } = data!;
      const personService = personApi();

      const params = new URLSearchParams();
      params.append('country', country);
      params.append('zipCode', zipCode);
      params.append('city', city);
      params.append('street', street);

      const result = await personService.validateAddress(`?${params.toString()}`);
      return result.data;
    },
    enabled: !!data,
  });
