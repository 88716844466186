import { useGetPersonListForBankSelectionQuery } from 'graphql/generates';
import { PersonFamilyRelationship } from 'type/person';
import { getGraphQLClient } from 'graphql/graphQLClient';

export const useGetPersonListForBankSelection = (householdId: string) => {
  const query = useGetPersonListForBankSelectionQuery(
    getGraphQLClient(),
    {
      householdId,
    },
    {
      enabled: !!householdId,
      select: (response) =>
        response?.household.basicData?.personInHouseholdList?.filter(
          ({ familyRelationship }) => familyRelationship === PersonFamilyRelationship.Adult,
        ),
    },
  );

  return {
    ...query,
    data: query.data || [],
  };
};
