import { WishGoalCards } from '@dvag/dfs-ui-blocks/components';
import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import { UpdateManyResources } from '@dvag/dfs-ui-blocks/hooks/useAutosaveHelper';
import { useCallback, useEffect, useState, useTransition } from 'react';
import * as yup from 'yup';
import { mandatoryFieldMessage } from 'form/util';
import { PersonWishGoalDto } from 'graphql/generates';

import { useNotification } from 'utils/notification/NotificationContext';
import { useContinuation } from 'utils/useContinuation';
import { FieldType } from './constant';
import { useUpdateManyPersonWishGoals } from './service/useUpdateManyPersonWishGoals';
import { TopDisplayType } from './type';

interface WishGoalCardsMapProps {
  personId: string;
  initialWgList: PersonWishGoalDto[];
  topDisplayType: TopDisplayType;
  setIsDirty: (isDirty: boolean) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  onWgListChange: (wgList: PersonWishGoalDto[]) => void;
  onContinuationRequest: ReturnType<typeof useContinuation>['onContinuationRequest'];
}

export const WishGoalCardsMap = ({
  personId,
  initialWgList,
  topDisplayType,
  setIsDirty,
  setIsSubmitting,
  onWgListChange,
  onContinuationRequest,
}: WishGoalCardsMapProps) => {
  const updateWg = useUpdateManyPersonWishGoals(personId);
  const [trackedWishGoalsIds, setTrackedWishGoalIds] = useState<Set<string>>(new Set());

  useEffect(() => {
    setTrackedWishGoalIds(new Set(initialWgList.filter((p) => p.id).map((wg) => wg.wishGoalId)));
  }, [initialWgList]);
  const { showError } = useNotification();
  const resourceSchema = yup.object().shape({
    wishGoalId: yup.string().required(mandatoryFieldMessage),
    name: yup.string().required(mandatoryFieldMessage),
    id: yup.string().nullable(),
    personId: yup.string().nullable(),
    isDesired: yup.boolean().nullable(),
    order: yup.number().min(1).max(10).nullable(),
    comment: yup.string().nullable(),
  });

  const { register, setValue, flushChanges, triggerValidation, move, getAllResources } =
    useAutosave<PersonWishGoalDto>({
      data: initialWgList,
      updateManyResources: updateWg.mutateAsync as UpdateManyResources<PersonWishGoalDto>,
      onIsDirtyChange: (isDirty) => {
        setIsDirty(isDirty);
      },
      onIsSubmittingChange: (isSubmitting) => {
        setIsSubmitting(isSubmitting);
      },
      onRequestResponse: (data) => {
        const wgMap = new Map(getAllResources().map((res) => [res.wishGoalId, res]));

        data.forEach(({ response }) => {
          if (response) {
            const oldWg = wgMap.get(response.wishGoalId);
            if (oldWg) {
              wgMap.set(response.wishGoalId, {
                ...oldWg,
                id: response.id,
                personId: response.personId,
              });
            }
          }
        });
        onWgListChange([...wgMap.values()]);
      },
      resourceIdFieldName: 'wishGoalId',
      resourceSchema,
    });

  useEffect(() => {
    onContinuationRequest(() => {
      flushChanges();
      triggerValidation();
    });
  }, [flushChanges, onContinuationRequest, triggerValidation]);

  const [, startTransition] = useTransition();

  const setNewValue = useCallback(
    (fieldName: string, fieldValue: string | number | boolean, resourceIndex: number) => {
      startTransition(() => setValue(fieldName, fieldValue, resourceIndex));
      onWgListChange(getAllResources());
    },
    [getAllResources, onWgListChange, setValue],
  );

  const setTrackedIndex = useCallback(
    (resourceIndex: number) => {
      const { wishGoalId } = getAllResources()[resourceIndex];
      setTrackedWishGoalIds((wishGoalIds) => new Set([...wishGoalIds, wishGoalId]));
    },
    [getAllResources],
  );

  const onCommentChange = (value: string, resourceIndex: number) => {
    setNewValue(FieldType.comment, value, resourceIndex);
    setNewValue(FieldType.order, resourceIndex + 1, resourceIndex);
    setTrackedIndex(resourceIndex);

    if (!getAllResources()[resourceIndex].id) {
      setNewValue(FieldType.id, Math.random(), resourceIndex);
    }
  };

  const moveCard = useCallback(
    (activeIdIndex: number, overIdIndex: number) => {
      move(activeIdIndex, overIdIndex);
      const start = activeIdIndex < overIdIndex ? activeIdIndex : overIdIndex;
      const end = activeIdIndex < overIdIndex ? overIdIndex : activeIdIndex;
      const moveBack = activeIdIndex > overIdIndex;

      for (let i = start; i <= end; i += 1) {
        const { wishGoalId } = getAllResources()[i];
        if (
          (moveBack && i === start) ||
          (!moveBack && i === end) ||
          trackedWishGoalsIds.has(wishGoalId)
        ) {
          setTrackedIndex(i);
          setNewValue(FieldType.order, i + 1, i);

          if (!getAllResources()[i].id) {
            setNewValue(FieldType.id, Math.random(), i);
          }
        }
      }
    },
    [move, getAllResources, trackedWishGoalsIds, setTrackedIndex, setNewValue],
  );

  const onDesireChange = useCallback(
    (isDesired: boolean, resourceIndex: number) => {
      setTrackedIndex(resourceIndex);

      setNewValue(FieldType.isDesired, isDesired, resourceIndex);
      setNewValue(FieldType.order, resourceIndex + 1, resourceIndex);
      const wgList = getAllResources();
      const gapCandidateIndex = wgList.findIndex((p) => !p.isDesired);

      const hasGap =
        gapCandidateIndex !== -1 && wgList.slice(gapCandidateIndex).some((p) => p.isDesired);

      const destinationIndex = wgList.filter((p) => p.isDesired).length + (isDesired ? -1 : 0);
      if (hasGap && destinationIndex !== resourceIndex && destinationIndex < wgList.length) {
        moveCard(resourceIndex, destinationIndex);
      }
    },
    [setTrackedIndex, setNewValue, getAllResources, moveCard],
  );

  const onOrderChange = useCallback(
    (activeId: string, overId: string) => {
      const wgList = getAllResources();
      const activeIdIndex = wgList.findIndex((wg) => wg.wishGoalId === activeId);
      let overIdIndex = wgList.findIndex((wg) => wg.wishGoalId === overId);
      const checkedFieldsLength = wgList.filter((p) => p.isDesired === true).length;
      if (!wgList[activeIdIndex].isDesired && overIdIndex < checkedFieldsLength) {
        overIdIndex = checkedFieldsLength;
      }
      if (wgList[activeIdIndex].isDesired === true && overIdIndex >= checkedFieldsLength) {
        overIdIndex = checkedFieldsLength - 1;
      }

      moveCard(activeIdIndex, overIdIndex);
    },
    [getAllResources, moveCard],
  );

  return (
    <WishGoalCards
      showError={showError}
      list={getAllResources()}
      topDisplayType={topDisplayType}
      onCommentChange={onCommentChange}
      onDesireChange={onDesireChange}
      onOrderChange={onOrderChange}
      register={register}
    />
  );
};
