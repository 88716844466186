import i18next from 'i18next';
import { isValidIBAN } from 'ibantools';
import { zipCodeRegex } from '@dvag/dfs-constant-config';
import { errorMessage as contactErrorMessage, incomeMaxValue, incomeMinValue } from './util';

export const invalidIBANErrorMessage = i18next.t('bankDetails.invalidIBAN');
export const zipCodeValidationMessage = i18next.t('general.invalidZipCode');
export const telephoneRegExp = /^(?:\+[0-9X]{1,7} )?(\d{1,14})$/;
export const emailRegExp = /^[a-z0-9+_.-]+@(?:[a-ž0-9+_-]+?\.)+[a-z0-9][a-z]+$/i;
export const taxNumberRegExp = /^\d{10,13}$/;
export const zipCodeRegExp = zipCodeRegex;

export const validateIBAN = (value: string | undefined): boolean => {
  if (value === '') return true;
  if (value?.length && isValidIBAN(value)) return true;
  return false;
};

export const validateEmail = (value: string) => {
  if (value === '') return true;
  if (value?.length && emailRegExp.test(value)) return true;
  return contactErrorMessage;
};

export const validatePhoneOrFaxNumber = (value?: string): boolean | string => {
  if (value?.length && telephoneRegExp.test(value)) return true;
  return contactErrorMessage;
};

export const validateZipCode = (value?: string): boolean | string => {
  if (value === '' || value === null || value === undefined) return true;
  if (value?.length && zipCodeRegExp.test(value)) return true;
  return zipCodeValidationMessage;
};

export const validateTaxNumber = (value: string | undefined): boolean => {
  if (value === '' || value === null || value === undefined) return true;
  if (value?.length && taxNumberRegExp.test(value)) return true;
  return false;
};

export const validateMinIncomeNumber = (value: number | undefined): boolean => {
  if (!value && value !== 0) return true;
  if (value > incomeMinValue && value < incomeMaxValue) return true;
  if (value < incomeMinValue) return false;
  return true;
};

export const validateMaxIncomeNumber = (value: number | undefined): boolean => {
  if (!value && value !== 0) return true;
  if (value > incomeMinValue && value < incomeMaxValue) return true;
  if (value >= incomeMaxValue) return false;
  return true;
};
