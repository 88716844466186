import React from 'react';

import './style.css';
import { DxContainer, DxGrid } from '@dvag/design-system-react';

export const FormPlaceholder = () => {
  const placeholderNumber = 10;
  const placeholderArray = [...Array(placeholderNumber)].map((_, index) => index);
  return (
    <DxContainer color="transparent" className="form-container" data-testid="form-placeholder">
      <DxGrid base="page" mq3="6-6/*" mq1="12/*" rowgap="24">
        {placeholderArray.map((placeholder) => (
          <DxContainer color="white" className="element-container" key={`${placeholder}`}>
            <DxContainer color="gray-7" className="label-placeholder" />
            <DxContainer color="background" className="input-placeholder" />
          </DxContainer>
        ))}
      </DxGrid>
    </DxContainer>
  );
};
