export const route = {
  home: '/',
  person: '/haushalt/:householdId/analyse/persoenliche-daten/:personId',
  personWithActiveForm: '/haushalt/:householdId/analyse/persoenliche-daten/:personId/:activeForm',
  createHousehold: '/haushalt/hinzufuegen',
  manageHousehold: '/haushalt/:householdId/verwalten',
  wishesAndGoals: '/haushalt/:householdId/analyse/wuensche-und-ziele',
  wishesAndGoalsWithSelectedPerson: '/haushalt/:householdId/analyse/wuensche-und-ziele/:personId',
  wishesAndGoalsSalesforce: '/haushalt/:householdId/analyse/wuensche-und-ziele/salesforce',
  wishesAndGoalsSalesforceWithSelectedPerson:
    '/haushalt/:householdId/analyse/wuensche-und-ziele/:personId/salesforce',
  wishesAndGoalsHideFinancialGrade:
    '/haushalt/:householdId/analyse/wuensche-und-ziele/ausgeblendetfinanznote',
  wishesAndGoalsHideFinancialGradeWithSelectedPerson:
    '/haushalt/:householdId/analyse/wuensche-und-ziele/ausgeblendetfinanznote/:personId',
  savingTypeHome: '/haushalt/:householdId/analyse/ffg/sparer-typ/start',
  savingType: '/haushalt/:householdId/analyse/ffg/sparer-typ/:step',
  bankSelection: '/haushalt/:householdId/bankauslese/person/:personId',
  bankAnalysis: '/haushalt/:householdId/bankauslese/person/:personId/analyse',
};
