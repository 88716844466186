import i18next from 'i18next';

import { FormType } from 'utils/fieldList';

export const tabList: {
  label: string;
  key: string;
}[] = [
  {
    label: i18next.t('general.basicData'),
    key: FormType.basicData,
  },
  {
    label: i18next.t('general.address'),
    key: FormType.address,
  },
  {
    label: i18next.t('general.contact'),
    key: FormType.contact,
  },
  {
    label: i18next.t('general.occupation'),
    key: FormType.profession,
  },
  {
    label: i18next.t('general.tax'),
    key: FormType.tax,
  },
  {
    label: i18next.t('general.bankDetails'),
    key: FormType.bankDetails,
  },
];
