import { getContext } from '@dvag/dfs-orchestrator-client';
import { personalDataHasBeenUpdated } from '@dvag/dfs-orchestrator-client/messengers';

export function sendPersonalDataHasBeenUpdatedMessage(
  householdId: string | undefined,
  personId: string | undefined,
) {
  const advisorId = getContext()?.user.id;
  personalDataHasBeenUpdated(advisorId, householdId as string, personId as string);
}
