import { getContext, getToken } from '@dvag/dfs-orchestrator-client';
import { GraphQLClient } from 'graphql-request';
import { ApiUrls, getApiUrls } from '@dvag/dfs-constant-config/api-urls';
import { getDevEnv } from 'service/envConfig';

const getHeaders = () => {
  const accessToken = getToken();
  const { user } = getContext();
  return {
    Authorization: `Bearer ${accessToken}`,
    ...(user.parentUserId ? { 'parent-user-id': user.parentUserId } : {}),
  };
};

export const getGraphQLClient = (service: keyof ApiUrls = 'person') => {
  const url = `${getApiUrls(getDevEnv())[service]}/graphql`;
  return new GraphQLClient(url, { headers: getHeaders() });
};
