import React from 'react';
import { DxCard, DxCardContent, DxCardHeader, DxText } from '@dvag/design-system-react';
import i18next from 'i18next';
import './WelcomeCard.css';

export const WelcomeCard = () => (
  <DxCard id="bc-welcome-card" data-testid="bc-welcome-card">
    <DxCardHeader
      data-testid="bc-welcome-card-title"
      label={i18next.t('bankSelectionPage.welcomeCard.title')}
    />
    <DxCardContent>
      <ul className="bc-welcome-content-list">
        <li>
          <DxText data-testid="bc-welcome-card-info-1">
            {i18next.t('bankSelectionPage.welcomeCard.content.info1')}
          </DxText>
        </li>
        <br />
        <li>
          <DxText data-testid="bc-welcome-card-info-2">
            {i18next.t('bankSelectionPage.welcomeCard.content.info2')}
          </DxText>
        </li>
      </ul>
    </DxCardContent>
  </DxCard>
);
