type StatusValue = 'error' | 'success' | 'none' | 'required';

interface StatusI {
  [key: string]: StatusValue;
}

export const Status: StatusI = {
  required: 'required',
  error: 'error',
  success: 'success',
  none: 'none',
};
