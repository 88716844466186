import React, { ReactNode, useMemo, useState } from 'react';
import i18next from 'i18next';
import { NotificationContext } from './NotificationContext';
import { Notification, NotificationErrorData, NotificationSuccessData } from './types';
import { NotificationComponent } from './NotificationComponent';

export type NotificationProviderProps = {
  children: ReactNode;
};

const translations = {
  defaultErrorTitle: i18next.t('general.toasters.loading.generic.title'),
  defaultErrorDescription: i18next.t('general.toasters.loading.generic.description'),
  getErrorDescription: (status?: number) => {
    switch (status) {
      case 404:
        return i18next.t('general.toasters.loading.404.description');
      case 409:
        return i18next.t('general.toasters.loading.409.description');
      default:
        return translations.defaultErrorDescription;
    }
  },
};

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [data, setData] = useState<Notification | null>(null);

  const hide = () => {
    setData(null);
  };

  const showSuccess = ({ title, description }: NotificationSuccessData) => {
    setData({ type: 'success', title, description });
  };

  const showError = (errorData?: NotificationErrorData) => {
    if (!errorData) {
      setData({
        type: 'error',
        title: translations.defaultErrorTitle,
        description: translations.defaultErrorDescription,
      });
      return;
    }

    const { status, title, description } = errorData;

    if (status === 403) return;

    setData({
      type: 'error',
      title: title || translations.defaultErrorTitle,
      description: description || translations.getErrorDescription(status),
    });
  };

  const value = useMemo(() => ({ data, hide, showSuccess, showError }), [data]);

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <NotificationComponent />
    </NotificationContext.Provider>
  );
};
