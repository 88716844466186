export const savingTypeUrl = {
  start: {
    url: 'start',
    title: 'whatDistinguishesTypes',
    nextUrl: 'merkmale',
    index: 0,
  },
  merkmale: {
    url: 'merkmale',
    title: 'selectAttributes',
    nextUrl: 'gegenwaertig',
    index: 1,
  },
  gegenwaertig: {
    url: 'gegenwaertig',
    title: 'currentType',
    nextUrl: 'gewuenscht',
    index: 2,
  },
  gewuenscht: {
    url: 'gewuenscht',
    title: 'desiredType',
    nextUrl: '/haushalt/:householdId/analyse/ffg/erklaerung',
    index: 3,
  },
};
