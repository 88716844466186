import { RefCallback, useCallback, useEffect, useMemo, useState, useTransition } from 'react';

import {
  DxButton,
  DxCard,
  DxCardContent,
  DxCheckbox,
  DxDropdown,
  DxGrid,
  DxIcon,
  DxLegend,
  DxText,
  DxTextInput,
  DxToggle,
} from '@dvag/design-system-react';
import { StringOrObject } from '@dvag/design-system/dist/types/components/layout/dx-list/list.public';
import i18next from 'i18next';
import * as yup from 'yup';
import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import { FieldErrors } from 'react-hook-form';
import { useCreateOrUpdateAddress } from 'hooks/useCreateOrUpdateAddress';
import { useDeleteAddress } from 'hooks/useDeleteAddress';
import { useMemoizedDropdownOptionList } from 'hooks/useMemoizedDropdownOptionList';
import { useValidateAddress } from 'hooks/useValidateAddress';

import { style } from 'styleConfig';

import { Address, ValidateAddress } from 'type/address';
import {
  FieldType,
  FormType,
  FormTypes,
  SecondaryFormType,
  TriggerValidationValue,
} from 'utils/fieldList';
import { checkIcon, countryIdentifiers } from 'utils/util';
import { checkIsMobile } from 'utils/windowSize';

import { ContinutationRequest } from 'utils/useContinuation';

import { defaultAddressData } from '../util';

import '../style.css';
import { validateZipCode } from '../validation';
import { AddressSearchInput } from '../searchInput/AddressSearchInput';

interface AddressFormProps {
  display: string;
  personId: string | undefined;
  householdId: string | undefined;
  onFormDirtyStatusChanged: (formType: FormTypes, isDirty: boolean) => void;
  onFormValidStatusChanged: (formType: FormTypes, isValid: boolean) => void;
  onFormSubmittingStatusChanged: (formType: FormTypes, isSubmitting: boolean) => void;
  onFormSaved: (formType: FormTypes) => void;
  getCanContinueHandler: () => (canContinueCond: boolean) => void;
  onContinuationRequest: (continuationRequestCallback: ContinutationRequest) => void;
  data: Address[] | undefined;
  setTriggerValidationForms: (
    value: TriggerValidationValue,
    formType: FormTypes,
    secondaryFormType?: SecondaryFormType,
  ) => void;
}

type Props = {
  index: number;
  field: Address;
  getValues: (fieldName: string, resourceIndex?: number) => string | undefined;
  isSubmitting: boolean;
  onToggleEnableFields: (value: boolean, index1: number) => void;
  remove: (index1: number) => void;
  register: (fieldName: string, resourceIndex?: number) => { ref: RefCallback<unknown> };
  changeValidatedFields: (value: boolean, index1: number) => void;
  onCountryChange: (countryValue: StringOrObject, fieldName: string, index1: number) => void;
  countryListMemoized: JSX.Element[];
  enableFields: boolean[];
  errors: FieldErrors | undefined;
  startTransition: (p: () => void) => void;
  setValue: <TF>(fieldName: string, resourceFieldValue: TF, resourceIndex?: number) => void;
  onStateChange: (stateValue: StringOrObject, fieldName: string, index1: number) => void;
  stateListMemoized: JSX.Element[];
  onSelectAutocomplete: (address: ValidateAddress, index: number) => void;
};

const translation = {
  addressSearch: i18next.t('addressForm.addressSearch'),
  inputDefaultPlaceholder: i18next.t('general.inputDefaultPlaceholder'),
  selectDefaultPlaceholder: i18next.t('general.selectDefaultPlaceholder'),
  mainAddressSubTitle: i18next.t('addressForm.mainAddressSubTitle'),
  additionalAddressSubtitle: i18next.t('addressForm.additionalAddressSubtitle'),
  manualInput: i18next.t('addressForm.manualInput'),
  country: i18next.t('addressForm.country'),
  street: i18next.t('addressForm.street'),
  zipCode: i18next.t('addressForm.zipCode'),
  city: i18next.t('addressForm.city'),
  state: i18next.t('addressForm.state'),
  addAddress: i18next.t('addressForm.addAddress'),
};

const AddressCard = ({
  index,
  field,
  getValues,
  isSubmitting,
  onToggleEnableFields,
  remove,
  register,
  changeValidatedFields,
  onCountryChange,
  countryListMemoized,
  enableFields,
  errors,
  startTransition,
  setValue,
  onStateChange,
  stateListMemoized,
  onSelectAutocomplete,
}: Props) => {
  const isSecondaryAddress = index > 0;
  const shouldDisable = field.isReadOnly && !isSecondaryAddress;

  return (
    <DxGrid
      base="page"
      mq3="12/3-9/12/6-6/6-6/6-6/*"
      mq1="12/12/12/12/12/12/12/12/*"
      key={`addressData.id${field.id}`}
      data-testid={`address-card-${index}`}
    >
      <div className="pd_address-title-container" style={style.addressTitleContainer}>
        <div className="text-container" style={{ paddingTop: isSecondaryAddress ? '16px' : 0 }}>
          <DxText
            color="headline"
            type="Paragraph-Standard"
            className="pd_address-title"
            style={style.mainTitle}
          >
            {isSecondaryAddress
              ? translation.additionalAddressSubtitle
              : translation.mainAddressSubTitle}
          </DxText>
          {typeof getValues(FieldType.isVerified, index) === 'boolean' && (
            <div className="pd_address-validated-container">
              {getValues(FieldType.isVerified, index) ? (
                <>
                  <DxIcon
                    size={16}
                    color="green-100"
                    icon={checkIcon('check')}
                    className="pd_address-check"
                  />
                  <DxText className="pd_address-validated" type="Infotext-Small" color="green-100">
                    {i18next.t('addressForm.validated')}
                  </DxText>
                </>
              ) : (
                <DxText color="gray-50" type="Infotext-Small">
                  {i18next.t('addressForm.notValidated')}
                </DxText>
              )}
            </div>
          )}
        </div>
        {isSecondaryAddress && (
          <DxButton
            disabled={isSubmitting}
            type="text"
            className="pd_address-delete-button"
            onClick={() => {
              onToggleEnableFields(false, index);
              remove(index);
            }}
            data-testid={`pd_deleteAddressSection-${index}`}
            id={`pd_deleteAddressSection-${index}`}
            icon={checkIcon('loeschen')}
            theme="destructive"
          />
        )}
      </div>
      <DxDropdown
        size="m"
        placeholder={translation.selectDefaultPlaceholder}
        required
        {...register(FieldType.country, index)}
        label={translation.country}
        data-testid={`pd_country-${index}`}
        id={`pd_country-${index}`}
        disabled={shouldDisable}
        onValueChange={({ detail }) => {
          onCountryChange(detail, FieldType.country, index);
          changeValidatedFields(false, index);
        }}
        clearable={false}
      >
        {countryListMemoized}
      </DxDropdown>
      <AddressSearchInput
        onSelectSearchOption={(searchOption) => {
          onSelectAutocomplete(searchOption as ValidateAddress, index);
        }}
        getLabel={(address) =>
          `${address.street ? `${address.street}, ` : ''}${address.zipCode} ${address.city}`
        }
        country={getValues(FieldType.country, index)}
        isDisabled={shouldDisable}
        isRequired={enableFields[index]}
        id={`pd_autocomplete-search-${index}`}
        data-testid={`pd_autocomplete-search-${index}`}
        label={translation.addressSearch}
      />
      <div className="pd_address-toggle-container">
        <DxText color="blue-80">{translation.manualInput}</DxText>
        <DxToggle
          disabled={!!errors?.[index] || shouldDisable}
          id={`toggle-${index}`}
          data-testid={`toggle-${index}`}
          onCheckedChange={(e) => onToggleEnableFields(e.detail, index)}
        />
      </div>

      <DxTextInput
        nominmaxlabel
        size="m"
        placeholder={translation.inputDefaultPlaceholder}
        required={!enableFields[index]}
        maxlength={45}
        label={translation.street}
        data-testid={`pd_street-${index}`}
        id={`pd_street-${index}`}
        disabled={shouldDisable || enableFields[index]}
        {...register(FieldType.street, index)}
        onValueChange={(e) => {
          startTransition(() => setValue(FieldType.street, e.detail, index));
          changeValidatedFields(false, index);
        }}
      />
      <DxTextInput
        nominmaxlabel
        size="m"
        required={!enableFields[index]}
        maxlength={15}
        label={translation.zipCode}
        data-testid={`pd_zipCode-${index}`}
        id={`pd_zipCode-${index}`}
        disabled={shouldDisable || enableFields[index]}
        placeholder={translation.inputDefaultPlaceholder}
        {...register(FieldType.zipCode, index)}
        onValueChange={(e) => {
          startTransition(() => setValue(FieldType.zipCode, e.detail, index));
          changeValidatedFields(false, index);
        }}
      />

      <DxTextInput
        nominmaxlabel
        size="m"
        placeholder={translation.inputDefaultPlaceholder}
        required={!enableFields[index]}
        maxlength={50}
        label={translation.city}
        data-testid={`pd_city-${index}`}
        id={`pd_city-${index}`}
        disabled={shouldDisable || enableFields[index]}
        {...register(FieldType.city, index)}
        onValueChange={(e) => {
          startTransition(() => setValue(FieldType.city, e.detail, index));
          changeValidatedFields(false, index);
        }}
      />
      <DxDropdown
        size="m"
        placeholder={translation.selectDefaultPlaceholder}
        label={translation.state}
        data-testid={`pd_state-${index}`}
        id={`pd_state-${index}`}
        disabled={shouldDisable || enableFields[index]}
        {...register(FieldType.state, index)}
        onValueChange={({ detail }) => {
          if (
            getValues(FieldType.state, index) !== detail &&
            getValues(FieldType.isVerified, index)
          )
            changeValidatedFields(false, index);
          onStateChange(detail, FieldType.state, index);
        }}
      >
        {stateListMemoized}
      </DxDropdown>
      {isSecondaryAddress && (
        <DxCheckbox
          className="pd_address-isPostalAddress"
          data-testid={`pd_isPostAddress-${index}`}
          id={`pd_isPostAddress-${index}`}
          label={`${i18next.t('addressForm.isPostalAddress')}`}
          onCheckedChange={(e) => {
            startTransition(() => setValue(FieldType.isPostalAddress, e.detail, index));
          }}
          checked={!!getValues(FieldType.isPostalAddress, index)}
          {...register(FieldType.isPostalAddress, index)}
        />
      )}
    </DxGrid>
  );
};

export const AddressForm = ({
  display,
  personId,
  householdId,
  onFormDirtyStatusChanged,
  onFormValidStatusChanged,
  onFormSubmittingStatusChanged,
  onFormSaved,
  getCanContinueHandler,
  onContinuationRequest,
  data,
  setTriggerValidationForms,
}: AddressFormProps) => {
  const resourceSchema = useMemo(
    () =>
      yup.object({
        street: yup.string().required(i18next.t('general.mandatoryField')),
        zipCode: yup
          .string()
          .required(i18next.t('general.mandatoryField'))
          .test(
            'validateZipCode',
            i18next.t('general.invalidZipCode'),
            (x) => validateZipCode(x) === true,
          ),
        city: yup.string().required(i18next.t('general.mandatoryField')),
        state: yup.string().optional().nullable(),
        country: yup.string().required(i18next.t('general.mandatoryField')),
        isVerified: yup.boolean().nullable(),
      }),
    [],
  );

  const createOrUpdateAddress = useCreateOrUpdateAddress(personId, householdId);
  const deleteAddressData = useDeleteAddress(personId);
  const isMobile = checkIsMobile();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAutocompleteValues, setSelectedAutocompleteValues] = useState<
    ValidateAddress | undefined
  >();
  const [selectedAutocompleteIndex, setSelectedAutocompleteIndex] = useState<number | undefined>();

  const { data: validatedAddress, isFetched: isValidatedAddressFetched } = useValidateAddress(
    selectedAutocompleteValues,
  );

  const { countryListMemoized, stateListMemoized } = useMemoizedDropdownOptionList();

  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);
  const {
    register,
    flushChanges,
    remove,
    append,
    setValue,
    triggerValidation,
    errors,
    getValues,
    canAddResource,
    resources,
  } = useAutosave<Address>({
    data,
    defaultData: defaultAddressData.addressData[0],
    createResource: createOrUpdateAddress.mutateAsync,
    updateResource: createOrUpdateAddress.mutateAsync,
    onIsValidChange: (isValidParam) => {
      onFormValidStatusChanged(FormType.address, isValidParam);
    },
    onSubmitSuccessfulChange: (isSubmitSuccessfulParam) => {
      if (isSubmitSuccessfulParam) onFormSaved(FormType.address);
    },
    onIsDirtyChange: (isDirtyParam) => {
      onFormDirtyStatusChanged(FormType.address, isDirtyParam);
      onCanContinueChange(!isDirtyParam);
    },
    onIsSubmittingChange: (isSubmittingParam) => {
      setIsSubmitting(isSubmittingParam);
      onFormSubmittingStatusChanged(FormType.address, isSubmittingParam);
    },
    resourceSchema,
    deleteResource: deleteAddressData.mutateAsync as never,
  });

  const [, startTransition] = useTransition();

  const changeValidatedFields = useCallback(
    (value: boolean, index: number) => {
      startTransition(() => setValue(FieldType.isVerified, value, index));
    },
    [setValue],
  );

  const onStateChange = (stateValue: StringOrObject, fieldName: string, index: number) => {
    startTransition(() => setValue(fieldName, stateValue, index));

    if (stateValue === countryIdentifiers.AUSLAND) {
      startTransition(() => setValue(FieldType.country, null, index));
    } else {
      startTransition(() => setValue(FieldType.country, countryIdentifiers.DEUTSCHLAND, index));
    }
  };

  const onCountryChange = (countryValue: StringOrObject, fieldName: string, index: number) => {
    const stateFieldName = fieldName.replace('country', 'state');
    const currentStateValue = getValues(stateFieldName, index);
    startTransition(() => setValue(fieldName, countryValue, index));
    if (countryValue === countryIdentifiers.DEUTSCHLAND) {
      if (currentStateValue === countryIdentifiers.AUSLAND) {
        startTransition(() => setValue(FieldType.state, undefined, index));
      }
    } else {
      startTransition(() => setValue(FieldType.state, countryIdentifiers.AUSLAND, index));
    }
  };

  const newAddress = defaultAddressData.addressData[0];

  const addNewAddress = useCallback(() => {
    startTransition(() => {
      append({ ...newAddress }, { shouldFocus: false });
    });
  }, [append, newAddress]);

  useEffect(() => {
    onContinuationRequest(() => {
      flushChanges();
      triggerValidation();
    });
  }, [flushChanges, onContinuationRequest, triggerValidation]);

  useEffect(() => {
    setTriggerValidationForms(triggerValidation as TriggerValidationValue, FormType.address);
  }, [setTriggerValidationForms, triggerValidation]);

  const [enableFields, setEnableFields] = useState([true, true]);

  const onToggleEnableFields = useCallback(
    (value: boolean, index: number) => {
      const fields = [...enableFields];
      fields.splice(index, 1, !value);
      if (JSON.stringify(fields) === JSON.stringify(enableFields)) return;
      setEnableFields(fields);
    },
    [enableFields],
  );

  const onSelectAutocomplete = (address: ValidateAddress, index: number) => {
    setSelectedAutocompleteValues(address);
    setSelectedAutocompleteIndex(index);
  };

  const onChangeValues = useCallback(
    (address: ValidateAddress) => {
      if (selectedAutocompleteIndex === undefined) return;
      const { street, zipCode, city, state, number } = address;
      const index = selectedAutocompleteIndex;
      setSelectedAutocompleteIndex(undefined);

      startTransition(() => setValue(FieldType.street, street, index));
      startTransition(() => setValue(FieldType.zipCode, zipCode, index));
      startTransition(() => setValue(FieldType.city, city, index));
      startTransition(() => setValue(FieldType.state, state || null, index));
      changeValidatedFields(true, index);

      if (!number) {
        const toggle = document.querySelector(`#toggle-${index}`) as HTMLDxToggleElement;
        onToggleEnableFields(true, index);
        if (toggle && !toggle.checked) toggle?.toggleAttribute('checked');
        setTimeout(() => {
          const input = document.querySelector(`#pd_street-${index}`) as HTMLDxTextInputElement;
          input?.focusControl?.();
        }, 100);
      }
    },
    [changeValidatedFields, onToggleEnableFields, selectedAutocompleteIndex, setValue],
  );

  useEffect(() => {
    if (isValidatedAddressFetched) {
      onChangeValues(validatedAddress as ValidateAddress);
    }
  }, [isValidatedAddressFetched, onChangeValues, validatedAddress]);

  return (
    <DxCard className="pd_address-container" style={{ display }}>
      <DxCardContent
        className="pd_address-form-container"
        style={{ padding: isMobile ? style.mobilePadding : style.desktopPadding }}
      >
        <form className="pd_address-form" data-testid="pd_address">
          <DxLegend className="pd_address-legend" />
          {resources &&
            resources.map((field, index) =>
              AddressCard({
                index,
                field,
                getValues,
                isSubmitting,
                onToggleEnableFields,
                remove,
                register,
                changeValidatedFields,
                onCountryChange,
                countryListMemoized,
                enableFields,
                errors,
                startTransition,
                setValue,
                onStateChange,
                stateListMemoized,
                onSelectAutocomplete,
              }),
            )}
          <div style={{ width: '100%', clear: 'right' }}>
            <DxButton
              type="text"
              className="pd_addressAddNewSection"
              disabled={!canAddResource() || resources.length > 1}
              onClick={addNewAddress}
              data-testid="pd_addressAddNewSection"
              id="pd_addressAddNewSection"
              label={translation.addAddress}
              icon={checkIcon('plus-kreis')}
            />
          </div>
        </form>
      </DxCardContent>
    </DxCard>
  );
};
