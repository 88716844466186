import { useEffect, useMemo } from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';

import i18next from 'i18next';

import {
  DxCard,
  DxCardContent,
  DxDateInput,
  DxDropdown,
  DxGrid,
  DxText,
  DxTextInput,
} from '@dvag/design-system-react';

import * as yup from 'yup';

import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import { useMemoizedDropdownOptionList } from 'hooks/useMemoizedDropdownOptionList';
import { useUpdateProfession } from 'hooks/useUpdateProfession';
import { ContinutationRequest } from 'utils/useContinuation';

import { style } from 'styleConfig';
import { ProfessionFormData, ProfessionSearch } from 'type/job';
import { isFieldRequired } from 'utils/common';
import {
  FieldType,
  FormType,
  FormTypes,
  SecondaryFormType,
  TriggerValidationValue,
} from 'utils/fieldList';
import { removeEmptySpace } from 'utils/util';
import {
  changeDateFormat,
  MAXDATE,
  MINDATE,
  validateMaxDate,
  validateMinDate,
} from 'utils/validation';
import { checkIsMobile } from 'utils/windowSize';
import { ProfessionSearchInput } from '../searchInput/ProfessionSearchInput';
import { mainPersonChecker, MIN_SEARCH_TERM_LENGTH } from '../util';
import { validateZipCode } from '../validation';
import { defaultProfessionData } from './defaultOccupationData';
import './style.css';

interface ProfessionFormProps {
  display: string;
  personId: string | undefined;
  order: number | undefined;
  onFormDirtyStatusChanged: (formType: FormTypes, isDirty: boolean) => void;
  onFormValidStatusChanged: (formType: FormTypes, isValid: boolean) => void;
  onFormSubmittingStatusChanged: (formType: FormTypes, isSubmitting: boolean) => void;
  onFormSaved: (formType: FormTypes) => void;
  getCanContinueHandler: () => (canContinueCond: boolean) => void;
  onContinuationRequest: (continuationRequestCallback: ContinutationRequest) => void;
  data: ProfessionFormData;
  setTriggerValidationForms: (
    value: TriggerValidationValue,
    formType: FormTypes,
    secondaryFormType?: SecondaryFormType,
  ) => void;
}

export const ProfessionForm = ({
  display,
  personId,
  order,
  onFormValidStatusChanged,
  onFormSaved,
  onFormDirtyStatusChanged,
  onFormSubmittingStatusChanged,
  getCanContinueHandler,
  onContinuationRequest,
  data,
  setTriggerValidationForms,
}: ProfessionFormProps) => {
  const { stateListMemoized, occupationGroupMemoizedList } = useMemoizedDropdownOptionList();

  const isMainPerson = mainPersonChecker(order);
  const isMobile = checkIsMobile();

  const updateProfession = useUpdateProfession(personId);

  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);

  const resourceSchema = useMemo(
    () =>
      yup.object().shape({
        occupationGroup: isMainPerson
          ? yup.string().required(i18next.t('general.mandatoryField'))
          : yup.string().nullable(),

        name: isMainPerson
          ? yup.string().required(i18next.t('general.mandatoryField'))
          : yup.string().nullable(),

        professionId: isMainPerson ? yup.number().required() : yup.number().nullable(),

        employer: yup
          .object()
          .shape({
            address: yup
              .object()
              .shape({
                zipCode: yup
                  .string()
                  .test(
                    'validateZipCode',
                    i18next.t('general.invalidZipCode'),
                    (x) => validateZipCode(x) === true,
                  )
                  .nullable(),
              })
              .nullable(),
          })
          .nullable(),
        employmentStartDate: yup
          .string()
          .nullable()
          .test(
            'validateMinDate',
            i18next.t('general.oldDateError', { date: changeDateFormat(MINDATE) }),

            validateMinDate,
          )
          .test(
            'validateMaxDate',
            i18next.t('general.longFutureDateError', {
              date: changeDateFormat(MAXDATE),
            }),
            validateMaxDate,
          ),
      }),
    [isMainPerson],
  );

  const { register, flushChanges, setValue, triggerValidation, errors } =
    useAutosave<ProfessionFormData>({
      data,
      defaultData: defaultProfessionData,
      createResource: updateProfession.mutateAsync,
      updateResource: updateProfession.mutateAsync,
      onIsValidChange: (isValidParam) =>
        onFormValidStatusChanged(FormType.profession, isValidParam),
      onSubmitSuccessfulChange: (isSubmitSuccessfulParam) => {
        if (isSubmitSuccessfulParam) onFormSaved(FormType.profession);
      },
      onIsDirtyChange: (isDirtyParam) => {
        onFormDirtyStatusChanged(FormType.profession, isDirtyParam);
        onCanContinueChange(!isDirtyParam);
      },
      onIsSubmittingChange: (isSubmittingParam) => {
        onFormSubmittingStatusChanged(FormType.profession, isSubmittingParam);
      },
      resourceIdFieldName: 'personId',
      resourceSchema,
    });

  const onProfessionChange = (profession: ProfessionSearch | undefined) => {
    setValue(FieldType.professionId, profession?.id);
    setValue(FieldType.professionName, removeEmptySpace(profession?.name));
  };

  useEffect(() => {
    onContinuationRequest(() => {
      flushChanges();
      triggerValidation();
    });
  }, [flushChanges, onContinuationRequest, triggerValidation]);

  const currentSectionErrorList: DeepMap<FieldValues, FieldError> = errors;

  useEffect(() => {
    setTriggerValidationForms(triggerValidation as TriggerValidationValue, FormType.profession);
  }, [setTriggerValidationForms, triggerValidation]);

  const translation = {
    profession: i18next.t('profession.profession'),
    employer: i18next.t('profession.employer'),
    occupationGroup: i18next.t('profession.occupationGroup'),
    startDate: i18next.t('profession.startDate'),
    companyName: i18next.t('profession.companyName'),
    streetName: i18next.t('profession.streetName'),
    zipCode: i18next.t('profession.zipCode'),
    city: i18next.t('profession.city'),
    federalState: i18next.t('profession.federalState'),
    selectDefaultPlaceholder: i18next.t('general.selectDefaultPlaceholder'),
    inputDefaultPlaceholder: i18next.t('general.inputDefaultPlaceholder'),
    noSearchResultsFoundForInput: i18next.t('combobox.noSearchResultsFoundForInput'),
    searchResultsLoading: i18next.t('combobox.searchResultsLoading'),
    enterMinCharactersForSearchTerm: i18next.t('combobox.enterMinCharactersForSearchTerm', {
      minSearchTermLength: MIN_SEARCH_TERM_LENGTH,
    }),
  };

  return (
    <DxCard
      data-testid="profession-card"
      className="pd_profession-form"
      style={{ display, width: 'inherit' }}
    >
      <DxCardContent style={{ padding: isMobile ? style.mobilePadding : style.desktopPadding }}>
        <form style={{ width: '100%' }} className="pd_profession">
          <DxGrid base="page" mq3="12/6-6/6/12/6-6/6-6/6-6/6-6/6" mq1="12/*">
            <DxText color="headline" type="Paragraph-Standard" className="pd_profession-title">
              {translation.profession}
            </DxText>
            <DxDropdown
              size="m"
              placeholder={translation.selectDefaultPlaceholder}
              label={translation.occupationGroup}
              required={
                isMainPerson || isFieldRequired(FieldType.occupationGroup, currentSectionErrorList)
              }
              id="pd_occupationGroup"
              data-testid="pd_occupationGroup"
              {...register(FieldType.occupationGroup)}
            >
              {occupationGroupMemoizedList}
            </DxDropdown>
            <div className="pd_profession-search-container">
              <ProfessionSearchInput
                errormessage={register<string>(FieldType.professionName).errormessage}
                onSelectSearchOption={(searchOption) => onProfessionChange(searchOption)}
                getLabel={(searchOption) => searchOption.name}
                isRequired={isMainPerson}
                label={translation.profession}
                id="pd_profession_title"
                data-testid="pd_profession_title"
                value={register<string>(FieldType.professionName).value}
              />
            </div>
            <DxDateInput
              size="m"
              label={translation.startDate}
              id="pd_profession_startDate"
              data-testid="pd_profession_startDate"
              {...register(FieldType.employmentStartDate)}
            />
            <DxText
              color="headline"
              type="Paragraph-Standard"
              className="pd_profession-secondary-title"
            >
              {translation.employer}
            </DxText>
            <DxTextInput
              nominmaxlabel
              size="m"
              placeholder={translation.inputDefaultPlaceholder}
              maxlength={50}
              label={translation.companyName}
              id="pd_companyName"
              data-testid="pd_companyName"
              {...register(FieldType.employerName)}
            />
            <DxTextInput
              nominmaxlabel
              size="m"
              placeholder={translation.inputDefaultPlaceholder}
              label={translation.streetName}
              id="pd_employerStreetName"
              data-testid="pd_employerStreetName"
              maxlength={45}
              {...register(FieldType.employerAddressStreet)}
            />
            <DxTextInput
              nominmaxlabel
              size="m"
              maxlength={5}
              label={translation.zipCode}
              id="pd_employerZipCode"
              data-testid="pd_employerZipCode"
              placeholder={translation.inputDefaultPlaceholder}
              {...register(FieldType.employerAddressZipCode)}
            />
            <DxTextInput
              nominmaxlabel
              size="m"
              placeholder={translation.inputDefaultPlaceholder}
              label={translation.city}
              id="pd_employerCity"
              data-testid="pd_employerCity"
              maxlength={50}
              {...register(FieldType.employerAddressCity)}
            />
            <DxDropdown
              size="m"
              placeholder={translation.selectDefaultPlaceholder}
              label={translation.federalState}
              id="pd_employerState"
              data-testid="pd_employerState"
              {...register(FieldType.employerAddressState)}
            >
              {stateListMemoized}
            </DxDropdown>
          </DxGrid>
        </form>
      </DxCardContent>
    </DxCard>
  );
};
