import { useCallback, useEffect, useState } from 'react';

import { addMessageListener, removeMessageListener } from '@dvag/dfs-orchestrator-client';
import {
  navigate as orchestratorNavigate,
  setMicroappIsDirty,
} from '@dvag/dfs-orchestrator-client/messengers';

type Continuation = { onConfirm: () => void; onCancel: () => void } | undefined;
export type ContinutationRequest = () => void;
export type OnCanContinue = (a: boolean) => void;

type CanContinue = { id: string; value: boolean };

const HEX_BASE = 16;
const TIMEOUT_DURATION_MS = 300;
const INITIAL_DELAY_MS = 100;

export const getGuid = () =>
  '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, () =>
    crypto.getRandomValues(new Uint8Array(1))[0].toString(HEX_BASE),
  );

export const useContinuation = () => {
  const [continuation, setContinuation] = useState<Continuation>(undefined);
  const [canContinueList, setCanContinueList] = useState<CanContinue[]>([]);

  const canContinue = canContinueList.every((item) => item.value);

  const [continuationRequestList, setContinuationRequestList] = useState<ContinutationRequest[]>(
    [],
  );

  const onContinuationRequest = useCallback((continuationRequestCallback: ContinutationRequest) => {
    setContinuationRequestList((previous) => [...previous, continuationRequestCallback]);
  }, []);

  const getContinuationHandler = useCallback(
    (continuationCallback: () => void) => () => {
      continuationRequestList.forEach((request) => request());
      if (canContinue) {
        continuationCallback();
      } else
        setTimeout(
          () =>
            setContinuation({
              onConfirm: () => {
                setContinuation(undefined);
                setMicroappIsDirty(false);
                setTimeout(() => continuationCallback(), TIMEOUT_DURATION_MS);
              },
              onCancel: () => setContinuation(undefined),
            }),
          INITIAL_DELAY_MS,
        );
    },
    [canContinue, continuationRequestList],
  );

  const getCanContinueHandler = useCallback(() => {
    const id = getGuid();
    return (canContinueCond: boolean) => {
      setCanContinueList((prev) => {
        const index = prev.findIndex((el) => el.id === id);
        if (index === -1) {
          return [...prev, { id, value: canContinueCond }];
        }
        const newCanContinue = [...prev];
        newCanContinue[index] = { id, value: canContinueCond };
        return newCanContinue;
      });
    };
  }, []);

  useEffect(() => {
    setMicroappIsDirty(!canContinue);
    if (continuation !== undefined && canContinue) {
      continuation.onConfirm();
    }
  }, [canContinue, continuation]);

  useEffect(() => {
    const customMsgId = addMessageListener('navigationRequest', (data) => {
      const requestPayload = (data as { payload: Record<string, string> }).payload;
      const { url, nextUrl } = requestPayload;
      getContinuationHandler(() => orchestratorNavigate(url, nextUrl))();
    });
    return () => {
      removeMessageListener(customMsgId);
    };
  }, [getContinuationHandler]);

  return {
    continuation,
    getContinuationHandler,
    getCanContinueHandler,
    onContinuationRequest,
  };
};
