import { DxCard } from '@dvag/design-system-react';
import { style } from 'styleConfig';
import { IBankDetails } from 'type/bankAccount';
import { FormTypes, SecondaryFormType, TriggerValidationValue } from 'utils/fieldList';
import { ContinutationRequest } from 'utils/useContinuation';
import { checkIsMobile } from 'utils/windowSize';
import { BankAccountSection } from './component/BankAccountSection';

interface BankAccountFormProps {
  data: Array<IBankDetails> | undefined;
  display: string;
  personId: string;
  onFormDirtyStatusChanged: (formType: FormTypes, isDirty: boolean) => void;
  onFormValidStatusChanged: (formType: FormTypes, isValid: boolean) => void;
  onFormSubmittingStatusChanged: (formType: FormTypes, isSubmitting: boolean) => void;
  onFormSaved: (formType: FormTypes) => void;
  getCanContinueHandler: () => (canContinueCond: boolean) => void;
  onContinuationRequest: (continuationRequestCallback: ContinutationRequest) => void;
  setTriggerValidationForms: (
    value: TriggerValidationValue,
    formType: FormTypes,
    secondaryFormType?: SecondaryFormType,
  ) => void;
}

export const BankAccountForm = ({
  display,
  personId,
  onFormDirtyStatusChanged,
  onFormValidStatusChanged,
  onFormSubmittingStatusChanged,
  onContinuationRequest,
  onFormSaved,
  getCanContinueHandler,
  data,
  setTriggerValidationForms,
}: BankAccountFormProps) => {
  if (!data) {
    return null;
  }
  const isMobile = checkIsMobile();
  return (
    <div
      className="pd_bankDetails-container"
      data-testid="pd_bankDetails-container"
      style={{ display }}
    >
      <DxCard>
        <div style={{ padding: isMobile ? style.mobilePadding : style.desktopPadding }}>
          <BankAccountSection
            data={data}
            personId={personId}
            onFormDirtyStatusChanged={onFormDirtyStatusChanged}
            onFormValidStatusChanged={onFormValidStatusChanged}
            onFormSubmittingStatusChanged={onFormSubmittingStatusChanged}
            onFormSaved={onFormSaved}
            getCanContinueHandler={getCanContinueHandler}
            onContinuationRequest={onContinuationRequest}
            setTriggerValidationForms={setTriggerValidationForms}
          />
        </div>
      </DxCard>
    </div>
  );
};
