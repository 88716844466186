import { pages, personalDataSectionSeparator } from '@dvag/dfs-constant-config';
import { useRef } from 'react';

import { getForm } from 'utils/fieldList';

import { tabList } from './tablist';

export const usePathWithMultipleSections = (activeFromWithSections: string | undefined) => {
  const hasMultipleSections = useRef(
    activeFromWithSections?.includes(personalDataSectionSeparator) ?? false,
  );

  const activeForm = activeFromWithSections?.replace(
    new RegExp(`^\\${personalDataSectionSeparator}`),
    '',
  );

  const multipleSections = useRef(
    activeFromWithSections ? pages.PERSONAL_DATA_SECTIONS_EDIT.getUrlSections(activeForm!) : [],
  );

  const multipleSectionsLabels = useRef(
    multipleSections.current?.map(
      (section) => tabList.find((p) => p.key === getForm(section))?.label ?? '',
    ) ?? [],
  );
  return {
    multipleSections: multipleSections.current,
    hasMultipleSections: hasMultipleSections.current,
    activeForm: activeForm?.includes(personalDataSectionSeparator)
      ? activeForm.split(personalDataSectionSeparator)[0]
      : activeForm,
    multipleSectionsLabels,
  };
};
