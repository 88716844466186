import { useMutation } from '@tanstack/react-query';
import { personApi } from 'service/api/personApi';
import { CommunicationChannelSectionDataType } from 'type/person';
import { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';
import { TransformParameters } from 'type/taxData';
import { queryKeyStr, transformEmptyToNull } from 'utils/util';
import { useNotification } from 'utils/notification/NotificationContext';

export const useCreateOrUpdateCommunicationChannel = (personId: string | undefined) => {
  const { showError } = useNotification();
  const personService = personApi();
  const mutationKey = [`${queryKeyStr}${personId}`];

  const mutationFn = async (communicationChannelData: CommunicationChannelSectionDataType) => {
    const { id, value, ...restData } = communicationChannelData;

    const rawData = { value: value.toLowerCase(), type: restData.type, prefix: restData.prefix };
    const transformedData = transformEmptyToNull(rawData) as typeof rawData;
    const payload: CommunicationChannelSectionDataType | TransformParameters = {
      ...transformedData,
    };
    const response = id
      ? await personService.updateCommunicationChannel(id, payload, personId)
      : await personService.createCommunicationChannel(payload, personId);
    return response.data;
  };

  return useMutation<
    CommunicationChannelSectionDataType,
    ErrorRequestEntity,
    CommunicationChannelSectionDataType
  >({
    mutationKey,
    mutationFn,
    onError: (error) => {
      showError({ status: error?.response?.status });
    },
  });
};
