import { appNames } from '@dvag/dfs-constant-config/app-names';

export const dashboardUtil = {
  HHB_AND_CONTRACT_OVERVIEW: 'hhb-und-vertragsuebersicht',
  INTERVIEW_CONCLUSION: 'interview-conclusion',
  PERSON: appNames.person,
  ADVISOR: appNames.advisor,
  ACCOUNTING: appNames.accounting,
  VPD: appNames.vpd,
  SHOPPING_CART: appNames.shoppingCart,
  ENVPROD: 'prod',
  ENVUAT: 'uat',
  PRIVACY_POLICY: 'datenschutzbestimmungen',
  CUSTOMER_ACCESS: 'kundenzugang',
  RECOMMENDATION: 'kundenwerbenkunden',
  DREAM_JOB: 'berufmitzukunft',
  DIRECT_ACCESS: 'direkteinstieg',
  PERSENTATION: 'presentation',
};
