import type { FC } from 'react';
import { WishAndGoalSalesforceCard } from '@dvag/dfs-ui-blocks/components';
import { PersonInHouseholdDto } from '../../graphql/generates';
import { useGetPersonWishGoalList } from '../WishAndGoal/service/useGetPersonWishGoalList';

export const WishAndGoalSalesforceContent: FC<{ person: PersonInHouseholdDto }> = ({ person }) => {
  const { id: personId, firstName, lastName } = person;
  const { data: wgList } = useGetPersonWishGoalList(personId);

  const desiredWGList = wgList.filter((i) => i.isDesired);

  return <WishAndGoalSalesforceCard fullName={`${firstName} ${lastName}`} wgList={desiredWGList} />;
};
