import React from 'react';
import { DxContainer, DxText } from '@dvag/design-system-react';
import i18next from 'i18next';
import { getConsentStatusText, isGdprConsentMissing } from 'utils/ConsentUtil';
import { FinleapConsent } from 'type/consent';
import { Email } from './Email';
import './ConsentDetails.css';

type ConsentDetailsProps = {
  email: string | undefined;
  gdprConsent: FinleapConsent | undefined;
};

export const ConsentDetails = ({ email, gdprConsent }: ConsentDetailsProps) => (
  <DxContainer
    color="transparent"
    className="bc-consent-details-container"
    data-testid="bc-consent-details"
  >
    <Email>
      {email || i18next.t('bankSelectionPage.processDescriptionCard.contact.missingEmail')}
    </Email>
    <DxContainer color="transparent" className="bc-consent-status-text-container">
      <DxText type="its" color="green-100" data-testid="bc-consent-details-text">
        {isGdprConsentMissing(gdprConsent)
          ? i18next.t('bankSelectionPage.processDescriptionCard.contact.missingConsent')
          : getConsentStatusText(gdprConsent?.status)}
      </DxText>
    </DxContainer>
  </DxContainer>
);
